import { useRoutes } from "react-router-dom";
import routes from "./routes/config";
import { Helmet } from "react-helmet";
import AuthProvider from "./auth/AuthProvider";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import theme from "./theme/theme";
import AlertProvider from "./providers/AlertProvider";
import { ConfirmationDialogProvider } from "@publicrelay/component-library";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers-pro/AdapterDateFns";
import ReactGA from "react-ga";
import usePageTracking from "./hooks/usePageTracking";
import { getGoogleTrackingId } from "./util/environment";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey("932aab264749b5f80d69b70b8441e91cTz05MTkyMixFPTE3NDkxNTc0MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

ReactGA.initialize(getGoogleTrackingId());

function App() {
    usePageTracking();

    return (
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AuthProvider>
                        <Helmet titleTemplate="%s | PublicRelay">
                            <title>Client</title>
                        </Helmet>
                        <ConfirmationDialogProvider>
                            <AlertProvider>{useRoutes(routes)}</AlertProvider>
                        </ConfirmationDialogProvider>
                    </AuthProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </Box>
    );
}

/**
 * Store and print the build time
 */
window.buildTimestamp = Number(process.env.REACT_APP_BUILD_TIMESTAMP);
const buildTime = new Date();
buildTime.setTime(window.buildTimestamp * 1000);
console.log(
    `%cBuilt at ${buildTime.toLocaleDateString()} ${buildTime.toLocaleTimeString()}`,
    "font-size: 4em; color: #999999"
);

export default App;
