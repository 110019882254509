import Box from "@mui/material/Box";
import PRSelect from "../shared/PRSelect";
import { CADENCE_OPTIONS, REPORT_TYPE_OPTIONS } from "./constants";
import Typography from "@mui/material/Typography";
import { PRTextField } from "../shared/PRInputs";
import PRDropzone from "../shared/form/PRDropzone";
import { isUrl } from "../../util/validation";
import {
    createNewCloudReport,
    createNewFileReport,
    updateCloudReport,
    updateFileReport,
} from "@publicrelay/service/dist/client/myProgram";
import { getDefaultErrorHandler } from "../../util/services";
import { Dialog } from "@publicrelay/component-library";
import PropTypes from "prop-types";

/**
 * display the add / edit dialog for My Program Reports
 * visible only to internal users; allows users to create / edit cloud reports or upload files
 * @param {MyProgramReport} draftReport
 * @param {function} setDraftReport
 * @param {function} handleAddEditSuccess
 * @param {number} clientId
 * @param {function} closeAddEdit
 * @returns {JSX.Element}
 * @constructor
 */
const AddEditReportDialog = ({
    draftReport,
    setDraftReport,
    handleAddEditSuccess,
    clientId,
    closeAddEdit,
}) => {
    /**
     * passed to the add / edit dialog
     * handle submitting the report data, either new or update
     * no validation done here as the Save button should be disabled until the form is valid
     */
    const handleAddEditSubmit = () => {
        let params = {
            displayName: draftReport.displayName,
            dateRange: draftReport.dateRange,
            cadence: draftReport.cadence,
        };

        if (draftReport.reportType === REPORT_TYPE_OPTIONS[0].value) {
            // add cloud report specific params
            params.reportType = REPORT_TYPE_OPTIONS[0].value;

            params.tableauUrl = draftReport.tableauUrl;

            if (draftReport.reportId) {
                updateCloudReport(clientId, draftReport.reportId, params)
                    .then(handleAddEditSuccess)
                    .catch(getDefaultErrorHandler("updateCloudReport"));
            } else {
                createNewCloudReport(clientId, params)
                    .then(handleAddEditSuccess)
                    .catch(getDefaultErrorHandler("createNewCloudReport"));
            }
        } else {
            // add local file report specific params
            params.reportType = REPORT_TYPE_OPTIONS[1].value;

            if (draftReport.uploadedFile) {
                params.uploadedFile = draftReport.uploadedFile[0];
            }

            if (draftReport.reportId) {
                updateFileReport(clientId, draftReport.reportId, params)
                    .then(handleAddEditSuccess)
                    .catch(getDefaultErrorHandler("updateFileReport"));
            } else {
                // create new
                createNewFileReport(clientId, params)
                    .then(handleAddEditSuccess)
                    .catch(getDefaultErrorHandler("createNewFileReport"));
            }
        }
    };

    /**
     * handle input changes
     * @param {object} target
     * @param {string} target.name
     * @param {string} target.value
     */
    const handleChange = ({ target }) => {
        setDraftReport({
            ...draftReport,
            [target.name]: target.value,
        });
    };

    /**
     * passed to the PRDropzone component; update state with new files
     * @param files
     */
    const handleFileChange = (files) => {
        setDraftReport({
            ...draftReport,
            uploadedFile: [...files],
        });
    };

    /**
     * passed to the PRDropzone component (trash can click); remove the uploadedFile from the UI
     */
    const handleFileRemove = () => {
        setDraftReport({
            ...draftReport,
            uploadedFile: undefined,
            fileName: undefined,
        });
    };

    /**
     * prefer to show file selected for upload and then previously saved file, if any
     * note that removing file selected for upload removes any previously saved file from the draft report as well
     * but the user can stills cancel to get back to the previously uploaded version
     * @returns {[{name: string}]|undefined|*}
     */
    const getPreviewFiles = () => {
        if (draftReport.uploadedFile) {
            return draftReport.uploadedFile;
        } else if (draftReport.fileName) {
            return [{ name: draftReport.fileName }];
        } else {
            return undefined;
        }
    };

    /**
     * should the save button be disabled on the report dialog?
     * @returns {boolean}
     */
    const shouldDisableSave = () => {
        // check cloud report specific
        if (
            draftReport.reportType === REPORT_TYPE_OPTIONS[0].value &&
            !isUrl(draftReport.tableauUrl)
        ) {
            return true;
        }

        // check local file specific
        if (
            draftReport.reportType === REPORT_TYPE_OPTIONS[1].value &&
            !(draftReport.uploadedFile || draftReport.fileName)
        ) {
            return true;
        }

        // shared values
        return !(
            draftReport.dateRange &&
            draftReport.displayName &&
            draftReport.cadence
        );
    };

    return (
        <Dialog
            title={draftReport.reportId ? "Edit Report" : "Add New Report"}
            open={true}
            onCancel={closeAddEdit}
            onSubmit={handleAddEditSubmit}
            maxWidth="sm"
            submitText="Save"
            disableSave={shouldDisableSave()}
        >
            <Box mt={1}>
                {!draftReport.reportId && (
                    <>
                        <PRSelect
                            label="Select report type"
                            options={REPORT_TYPE_OPTIONS}
                            formControlProps={{
                                sx: { width: "205px" },
                            }}
                            selectProps={{
                                onChange: handleChange,
                                value: draftReport.reportType,
                                name: "reportType",
                            }}
                        />
                        <br />
                        <br />
                    </>
                )}

                {draftReport.reportType && (
                    <>
                        <Typography component="legend" mb={1}>
                            Create a unique display name for this report:
                        </Typography>

                        <PRTextField
                            formControlConfig={{ sx: { width: "50%" } }}
                            type="text"
                            label="Report name"
                            inputConfig={{
                                name: "displayName",
                                value: draftReport.displayName,
                                onChange: handleChange,
                                inputProps: { maxLength: 50 },
                            }}
                        />

                        <br />
                        <br />
                    </>
                )}

                {draftReport.reportType === REPORT_TYPE_OPTIONS[0].value && (
                    <>
                        <Typography component="legend" mb={1}>
                            Paste the Tableau link for this report:
                        </Typography>

                        <PRTextField
                            formControlConfig={{ fullWidth: true }}
                            type="url"
                            label="Link to cloud report"
                            shouldValidateOnBlur={true}
                            inputConfig={{
                                name: "tableauUrl",
                                value: draftReport.tableauUrl,
                                onChange: handleChange,
                            }}
                        />

                        <br />
                        <br />
                    </>
                )}

                {draftReport.reportType && (
                    <>
                        <Typography component="legend" mb={1}>
                            Enter the date range or quarter for this report. For
                            reports that receive frequent updates, consider
                            putting "Ongoing".
                        </Typography>
                        <PRTextField
                            formControlConfig={{ sx: { width: "50%" } }}
                            type="text"
                            label="Date range/quarter"
                            inputConfig={{
                                name: "dateRange",
                                value: draftReport.dateRange,
                                onChange: handleChange,
                                inputProps: { maxLength: 30 },
                            }}
                        />
                        <br />
                        <br />
                        <Typography component="legend" mb={1}>
                            Select the report's delivery cadence:
                        </Typography>
                        <PRSelect
                            label="Select cadence"
                            options={CADENCE_OPTIONS}
                            formControlProps={{
                                sx: { width: "205px" },
                            }}
                            selectProps={{
                                onChange: handleChange,
                                value: draftReport.cadence,
                                name: "cadence",
                            }}
                        />
                        <br /> <br />
                    </>
                )}

                {draftReport.reportType === REPORT_TYPE_OPTIONS[1].value && (
                    <>
                        <Box width="50%">
                            <PRDropzone
                                accept={{
                                    "image/*": [".png", ".jpeg", ".jpg"],
                                    "application/pdf": [".pdf"],
                                    "application/vnd.ms-excel": [".xls"],
                                    "text/csv": [".csv"],
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                        [".xlsx"],
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                                        [".pptx"],
                                }}
                                multiple={false}
                                showPreviews={true}
                                onDrop={handleFileChange}
                                onRemovePreview={handleFileRemove}
                                previewFiles={getPreviewFiles()}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
};

AddEditReportDialog.propTypes = {
    draftReport: PropTypes.object.isRequired,
    setDraftReport: PropTypes.func.isRequired,
    handleAddEditSuccess: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    closeAddEdit: PropTypes.func.isRequired,
};

export default AddEditReportDialog;
