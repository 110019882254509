import {
    get,
    // post,
    // put,
    standardOptions,
} from "@publicrelay/service/src/service";

/**
 * KEM TODO: move to packages
 * get the list of feature togglz
 * @returns {Promise<Response>}
 */
export const getFeatureTogglz = () => {
    return get("com.publicrelay.www/prrestservices/togglz", standardOptions);
};

/**
 * Records page visit
 * @param clientId
 * @param pageIdentifier
 * @returns {Promise<Response>}
 */
export const recordPageVisit = (clientId, pageIdentifier) => {
    return get(
        `com.publicrelay.www/prrestservices/client/${clientId}/user/page-visit?pageIdentifier=${pageIdentifier}`,
        standardOptions,
    );
};
