import { createSlice, createSelector } from "@reduxjs/toolkit";
import { orderBy as _orderBy } from "lodash/collection";

// this will be stored at state.liveStream
export const name = "liveStream";

const initialState = {
    articles: [],
    order: null,
    orderBy: null,
};

const liveStreamSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        setArticles(state, { payload }) {
            state.articles = payload;
        },
        setOrdering(state, { payload }) {
            const { property, defaultDesc = false } = payload;

            let isAsc;

            if (state.orderBy !== property) {
                // first time setting dir for this property, set isAsc based on whether we want
                // "desc" to be first sort order
                isAsc = defaultDesc;
            } else {
                isAsc = state.orderBy === property && state.order === "asc";
            }

            // currently asc? now we should swap to desc
            state.order = isAsc ? "desc" : "asc";

            state.orderBy = property;
        },
    },
    extraReducers: {},
});

/**
 * Select the current list of articles for live stream
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectAllArticles = createSelector(
    (state) => state[name],
    (subState) => subState.articles
);

/**
 * Select the current orderBy value
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectOrderBy = createSelector(
    (state) => state[name],
    (subState) => subState.orderBy
);

/**
 * Select the current order value
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectOrder = createSelector(
    (state) => state[name],
    (subState) => subState.order
);

/**
 * select how many total articles we have for live stream
 */
export const selectTotalArticleCount = createSelector(
    selectAllArticles,
    (articles) => articles.length
);

/**
 * select articles based on the currently applied sort
 */
export const selectSortedArticles = createSelector(
    selectAllArticles,
    selectOrderBy,
    selectOrder,
    (articles, orderBy, order) => {
        if (orderBy) {
            return _orderBy(
                articles,
                [
                    function (article) {
                        if (article[orderBy]) {
                            if (typeof article[orderBy] === "string") {
                                return article[orderBy].toLowerCase();
                            } else {
                                return article[orderBy];
                            }
                        } else {
                            // per lodash - "by design when in asc order NaN, nullish, and symbol values are at the end so
                            // it fits that when desc they'd be at the beginning". we don't want them to be, so
                            // we will force empty string for comparison
                            return "";
                        }
                    },
                ],
                [order]
            );
        } else {
            return articles;
        }
    }
);

/**
 * select only a portion of the live stream articles based on the current page and the number of results per page
 * https://react-redux.js.org/api/hooks#useselector-examples
 * @param {number} page
 * @param {number} pageSize
 */
export const selectSortedArticlesByPage = createSelector(
    selectSortedArticles,
    (articles, page) => page,
    (articles, page, pageSize) => pageSize,
    (articles, page, pageSize) => {
        const start = (page - 1) * pageSize;

        const end = page * pageSize;

        //legal because slice doesn't mutate the articles array
        return articles.slice(start, end);
    }
);

export const { setArticles, setOrdering } = liveStreamSlice.actions;

export default liveStreamSlice.reducer;
