import { getClientLandingPageUrl } from "../../util/client";
import { isEXEnvironment } from "../../util/environment";

/**
 * determine client logo with click thru to dashboard or CL08
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderLogo = () => {
    const { PUBLIC_URL } = process.env;

    const src = isEXEnvironment()
        ? "BarometerLogoVerticalWhite.svg"
        : "2022LogoWhite.svg";

    const url = getClientLandingPageUrl();

    return (
        <a href={url}>
            <img
                src={`${PUBLIC_URL}/static/images/logos/${src}`}
                alt="PublicRelay"
                style={{ margin: "8px", height: "60px" }}
            />
        </a>
    );
};

export default HeaderLogo;
