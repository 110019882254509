import Typography from "@mui/material/Typography";
import { convert24HourTo12 } from "../../util/dates";
import PropTypes from "prop-types";

/**
 * determine deadline and sender data for display
 * @param {array} [deadlineList]
 * @param {string} [distributionListSender]
 * @returns {*|null}
 * @constructor
 */
const NewsletterDeadlineAndSender = ({
    deadlineList,
    distributionListSender,
}) =>
    deadlineList?.length > 0 ? (
        deadlineList.map((deadline) => (
            <Typography key={deadline.taskId}>
                {deadline.selectedDaysDisplay},{" "}
                {deadline.preApprovalTime && (
                    <>
                        Pre-Approval{" "}
                        {convert24HourTo12(deadline.preApprovalTime)} EDT,{" "}
                    </>
                )}
                Publish {convert24HourTo12(deadline.publishTime)} EDT
                {distributionListSender && (
                    <>, Sender {distributionListSender}</>
                )}
            </Typography>
        ))
    ) : distributionListSender ? (
        <>Sender {distributionListSender}</>
    ) : null;

NewsletterDeadlineAndSender.propTypes = {
    deadlineList: PropTypes.array,
    distributionListSender: PropTypes.string,
};

export default NewsletterDeadlineAndSender;
