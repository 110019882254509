import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";

/**
 * full screen loader with semitransparent background
 * @param  {} props
 * @return {React component}
 */
const BackdropLoader = (props) => {
    const handleClick = () => {
        if (props.handleClick) {
            props.handleClick();
        }
    };

    return (
        <Backdrop open={props.open} onClick={handleClick}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

BackdropLoader.propTypes = {
    open: PropTypes.bool,
    handleClick: PropTypes.func,
};

BackdropLoader.defaultProps = {
    open: false,
};

export default BackdropLoader;
