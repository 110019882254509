import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { getDefaultErrorHandler } from "../../util/services";
import { STATUS_VALUE_MAP } from "../util";
import { getTagGroups } from "../../components/filters/tags/stagedServices";
import { getTagGroupsAndTagDefinitionsFlattened } from "../../components/filters/tags/util";

// this will be stored at state.tags
export const name = "tags";

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get a list of tag groups
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchTagGroupsList = createAsyncThunk(
    `${name}/fetchTagGroupsList`,
    async (showSubTopics, { rejectWithValue }) => {
        try {
            const response = await getTagGroups(showSubTopics);

            return response.tagGroups;
        } catch (error) {
            getDefaultErrorHandler("fetchTagGroupsList")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * tags slice initial values
 */
const initialState = {
    tagGroups: {
        tagGroupsArray: [],
        status: STATUS_VALUE_MAP.IDLE,
    },
};

const tagsSlice = createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchTagGroupsList.pending, (state) => {
                state.tagGroups.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchTagGroupsList.fulfilled, (state, action) => {
                state.tagGroups.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add tag groups to redux
                state.tagGroups.tagGroupsArray = action.payload;
            });
    },
});

/**
 * Select the current tag groups
 */
export const selectTagGroups = createSelector(
    (state) => state[name],
    (subState) => subState.tagGroups.tagGroupsArray
);

/**
 * select the status of the tag groups fetch
 */
export const selectTagGroupsStatus = createSelector(
    (state) => state[name],
    (subState) => subState.tagGroups.status
);

/**
 * get a flattened list of tags with tag groups and their tag definitions (sorted)
 */
export const selectTagGroupsAndTagDefinitionsFlattened = createSelector(
    (state) => state[name],
    (subState) => {
        return getTagGroupsAndTagDefinitionsFlattened(
            subState.tagGroups.tagGroupsArray
        );
    }
);

export default tagsSlice.reducer;
