import Cookies from "js-cookie";

export const status_cookie_path = "publicrelay-status";

export const client_cookie_path = "pr_client";

export const defaultStatusCookie = {
    //Cookie to store the clientId on the analyst side.
    clientListAnalyst: {
        clientId: null,
        name: null,
        MSSEnabled: null,
        suffix: null,
        TrendingScore: null,
    },
    //Cookie to store the clientId on the client side.
    clientListClient: {
        clientId: null,
    },
    releasedArticles: {
        startDate: null,
        endDate: null,
        range: null,
    },
    analysisUpdate: {
        startDate: null,
        endDate: null,
        range: null,
    },
    chartDate: {
        startDate: null,
        endDate: null,
        range: null,
        interval: null,
    },
    articleTable: {
        startDate: null,
        endDate: null,
        range: "",
        isCustomDateRange: false,
    },
    newsletterPreview: {
        lastSelectedGroupingId: null,
    },
    titan: {
        sortAnalyst: true,
        showHiddenTags: false,
    },
    processing: {
        range: "",
    },
    menu: {
        quick: false,
        recently: false,
        savedItems: false,
        tools: false,
        list: false,
        modules: false,
    },

    unsuportedBrowserHeaderDisabled: false,

    grouping: {
        publication: false,
        presentation: false,
        scheduled: false,
        blog: false,
        widget: false,
        tweet: false,
        sectionsOpen: [],
        lastNewsFeedViewed: null,
        lastNewsClipViewed: null,
    },
    contentClose: {
        url: "",
    },
    analystArticleSearchSorter: {
        sorter: null,
        status: false,
    },
    selectedCoverageSubNav: {
        url: "",
    },

    multisort: {
        sort: [],
    },

    rawFeed: {
        enabled: false,
    },

    influencerSubNav: {
        authorUrl: "",
        outletUrl: "",
    },

    analystHotKeysFlag: false,

    sharing: {
        lastUpdatedNewsletter: null,
        lastUpdatedNewsletterSection: null,
        lastUpdatedNewsFeed: null,
    },

    tableau: {
        lastTimeHasBiLicense: 0,
    },

    exploreShowAsPercent: false,
};

const cookies = Cookies.withConverter({
    read: function (value) {
        return decodeURIComponent(value);
    },
});

/**
 * Check if provided String is Base64 Encoded
 * @param str
 * @return {boolean}
 */
export const isBase64 = (str) => {
    if (!str) {
        return false;
    }
    try {
        return btoa(atob(str)) === str;
    } catch (error) {
        return false;
    }
};

/**
 * Checks if text is surrounded by Quotes (double quotation marks)
 * @param text
 * @return {boolean}
 */
export const isSurroundedByQuotes = (text) => {
    const regex = /^"(.+)"$/;
    return regex.test(text);
};

export const getClientCookie = () => {
    const cookie = cookies.get(client_cookie_path);

    let result;

    try {
        let cleanCookieString = isSurroundedByQuotes(cookie)
            ? JSON.parse(cookie)
            : cookie;
        if (isBase64(cleanCookieString)) {
            cleanCookieString = atob(cleanCookieString);
        }
        result = JSON.parse(cleanCookieString);
    } catch (e) {
        console.log("Failed to parse client cookie");
    }

    return result;
};

/**
 * Get a value from the client cookie
 * @param name
 * @return {*}
 */
export const getClientCookieValue = (name) => {
    const cookie = getClientCookie();
    if (cookie) {
        return cookie[name];
    }
};

//retrieves the client's id associated with a ClientUser if they are logged in
export const getClientId = () => getClientCookieValue("clientId");

//retrieves the unsupported browser boolean
export const getUnsupportedBrowser = () => {
    const cookie = getStatusCookie();

    return cookie.unsuportedBrowserHeaderDisabled;
};
/**
 * retrieves the clientUser's username from the cookie
 * @returns {string|undefined}
 */
export const getClientUsernameFromCookie = () =>
    getClientCookieValue("username");

/**
 * retrieves the clientUser's uuid from the cookie
 * @returns {string|undefined}
 */
export const getClientUuidFromCookie = () => getClientCookieValue("uuid");

/**
 * retrieves the client's full name for the logged in user
 * @returns {string|undefined}
 */
export const getClientFullName = () => getClientCookieValue("clientFullName");

/**
 * retrieves the client's uploadAllowed flag if they are logged in
 * @returns {boolean|undefined}
 */
export const getUploadAllowedCookieValue = () =>
    getClientCookieValue("uploadAllowed");

/**
 * retrieves the client's clientType
 * @returns {string|undefined}
 */
export const getClientTypeFromCookie = () => getClientCookieValue("clientType");

/**
 * retrieves the client's commandCenterEnabled flag if they are logged in
 * @returns {boolean|undefined}
 */
export const getCommandCenterEnabledCookieValue = () =>
    getClientCookieValue("commandCenterEnabled");

/**
 * retrieves the client's twitter feed cookie value. when true, the client has access to twitter content
 * @returns {boolean|undefined}
 */
export const getClientTwitterFeedCookieValue = () =>
    getClientCookieValue("clientTwitterFeed");

/**
 * retrieves the client's predictive enabled cookie value
 * @returns {boolean|undefined}
 */
export const getClientPredictiveEnabledCookieValue = () =>
    getClientCookieValue("clientPredictiveEnabled");

/**
 * retrieves the client's raw feed enabled cookie value
 * @returns {boolean|undefined}
 */
export const getClientRawFeedEnabledCookieValue = () =>
    getClientCookieValue("clientRawFeedEnabled");

/**
 * retrieves the client's contact (media) database enabled cookie value
 * @returns {boolean|undefined}
 */
export const getClientContactDatabaseEnabledCookieValue = () =>
    getClientCookieValue("clientContactDatabaseEnabled");

/**
 * retrieves the visible publications cookie value
 * @returns {boolean|undefined}
 */
export const getClientVisiblePublicationsCookieValue = () =>
    getClientCookieValue("clientVisiblePublications");

/**
 * retrieves the visible news feeds (widgets) cookie value
 * @returns {boolean|undefined}
 */
export const getClientVisibleWidgetsCookieValue = () =>
    getClientCookieValue("clientVisibleWidgets");

/**
 * retrieves the live stream search cookie value
 * @returns {boolean|undefined}
 */
export const getLiveStreamSearchCookieValue = () =>
    getClientCookieValue("liveStreamSearchEnabled");

/**
 * retrieves the live stream alert enabled cookie value
 * @returns {boolean|undefined}
 */
export const getLiveStreamAlertsCookieValue = () =>
    getClientCookieValue("liveStreamAlertsEnabled");

/**
 * retrieves the neli enabled cookie value
 * @returns {boolean}
 */
export const getNeliEnabledCookieValue = () =>
    getClientCookieValue("clientNeliEnabled");

/**
 * retrieves the first name cookie value
 * @returns {boolean}
 */
export const getFirstNameCookieValue = () => getClientCookieValue("firstName");

/**                                 **
 *  Status Cookie                   **
 *                                  **
 */
export const createStatusCookie = () => {
    cookies.set(status_cookie_path, JSON.stringify(defaultStatusCookie));
};

/**
 * Get the status cookie
 */
export const getStatusCookie = () => {
    let cookie = cookies.get(status_cookie_path);

    let result;

    if (!cookie) {
        console.log("No status cookie, creating");
        createStatusCookie();
        cookie = cookies.get(status_cookie_path);
    }
    try {
        result = JSON.parse(cookie);
    } catch (e) {
        console.log("Failed to parse status cookie");
    }

    return result;
};

/**
 * Set a value in the status cookie
 * @param name
 * @param value
 * @return {*}
 */
export const setStatusCookieValue = (name, value) => {
    const cookie = getStatusCookie();

    if (cookie) {
        cookie[name] = value;
        cookies.set(status_cookie_path, JSON.stringify(cookie));
    }
};

/**
 * check the status cookie to see if we have a value for the last CL08 visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getSelectedCoverageSubNavFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.selectedCoverageSubNav?.url;
};

/**
 * check the status cookie to see if we have a value for the last Dashboard (CL01) visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getSelectedDashboardSubNavFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.selectedDashboardSubNav?.url;
};

/**
 * check the status cookie to see if we have a value for the last author list view (CL12) visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getSelectedAuthorSubNavUrlFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.influencerSubNav?.authorUrl;
};

/**
 * check the status cookie to see if we have a value for the last outlet list view (CL14) visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getSelectedOutletSubNavUrlFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.influencerSubNav?.outletUrl;
};

/**
 * check the status cookie to see if we have a value for the last twitter list view (CL28) visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getTwitterCoverageSubNavUrlFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.selectedTwitterContent?.url;
};

/**
 * check the status cookie to see if we have a value for the last twitter handles list view (CL24) visited
 * @returns {string|boolean|undefined} - returns value from cookie one of: full URL including domain, empty string, or undefined
 */
export const getSelectedTwitterHandlesSubNavUrlFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.influencerSubNav?.twitterHandleUrl;
};

/**
 * check the status cookie to see if we have an id for the last newsletter view (CL07) visited
 * @returns {number|undefined|null} - returns the newsletter ID, if any
 */
export const getLastViewedNewsletterIdFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.grouping?.lastNewsClipViewed;
};

/**
 * check the status cookie to see if we have an id for the last news feed (widget) view (CL06) visited
 * @returns {number|undefined|null} - returns the news feed ID, if any
 */
export const getLastViewedNewsFeedIdFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.grouping?.lastNewsFeedViewed;
};

/**
 * check the status cookie to see if we have a value for the last CL10 (raw feed AKA live stream) visited
 * @returns {string|undefined} - returns value from cookie one of: bucket id, empty string, or undefined
 */
export const getSelectedLiveStreamFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.rawFeed?.id;
};

/**
 * sets the cookie value for the last live stream bucket viewed
 * @param {number} id
 */
export const setSelectedLiveStreamCookie = (id) => {
    const statusCookie = getStatusCookie();

    const rawFeed = statusCookie?.rawFeed;

    if (rawFeed) {
        rawFeed.id = id;

        setStatusCookieValue("rawFeed", rawFeed);
    } else {
        setStatusCookieValue("rawFeed", { id });
    }
};

/**
 * sets the cookie value for the unsupported browser disabled value
 * @param {number} id
 */
export const setUnsupportedBrowserCookie = (bool) => {
    setStatusCookieValue("unsuportedBrowserHeaderDisabled", bool);
};

/**
 * check the status cookie to see if we have a value for the last newsletter added to via the "add to newsletter" dialog
 * @returns {string|undefined|null}
 */
export const getLastSharedNewsletterFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.sharing?.lastUpdatedNewsletter;
};

/**
 * sets value in the cookie for the newsletter last added to via sharing menu
 * @param {number} id
 */
export const setLastSharedNewsletterCookie = (id) => {
    const statusCookie = getStatusCookie();

    const sharing = statusCookie?.sharing;

    if (sharing) {
        sharing.lastUpdatedNewsletter = id;

        setStatusCookieValue("sharing", sharing);
    } else {
        setStatusCookieValue("sharing", { lastUpdatedNewsletter: id });
    }
};

/**
 * check the status cookie to see if we have a value for the last newsletter section added to via the "add to newsletter" dialog
 * @returns {string|undefined|null}
 */
export const getLastSharedNewsletterSectionFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.sharing?.lastUpdatedNewsletterSection;
};

/**
 * sets value in the cookie for the newsletter section last added to via sharing menu
 * @param {number} id
 */
export const setLastSharedNewsletterSectionCookie = (id) => {
    const statusCookie = getStatusCookie();

    const sharing = statusCookie?.sharing;

    if (sharing) {
        sharing.lastUpdatedNewsletterSection = id;

        setStatusCookieValue("sharing", sharing);
    } else {
        setStatusCookieValue("sharing", { lastUpdatedNewsletterSection: id });
    }
};

/**
 * check the status cookie to see if we have a value for the last news feed an article was added to via a sharing menu
 * @returns {string|undefined|null}
 */
export const getLastSharedNewsFeedFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.sharing?.lastUpdatedNewsFeed;
};

/**
 * sets value in the cookie for the news feed last added to via sharing menu
 * @param {number} id
 */
export const setLastSharedNewsFeedCookie = (id) => {
    const statusCookie = getStatusCookie();

    const sharing = statusCookie?.sharing;

    if (sharing) {
        sharing.lastUpdatedNewsFeed = id;

        setStatusCookieValue("sharing", sharing);
    } else {
        setStatusCookieValue("sharing", { lastUpdatedNewsFeed: id });
    }
};

/**
 * check the status cookie to see if we have start date value for the CL08 date range
 * @returns {string|undefined|null}
 */
export const getCL08StartDateFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.releasedArticles?.startDate;
};

/**
 * check the status cookie to see if we have an end date value for the CL08 date range
 * @returns {string|undefined|null}
 */
export const getCL08EndDateFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.releasedArticles?.endDate;
};

/**
 * set both the start and the end date for CL08 (used across application for date components)
 * @param start
 * @param end
 */
export const setCL08StartEndDateCookie = (start, end) => {
    if (start && end) {
        const statusCookie = getStatusCookie();

        setStatusCookieValue("releasedArticles", {
            ...statusCookie.releasedArticles,
            startDate: start,
            endDate: end,
        });
    }
};

/**
 * sets the cookie value for show as percent toggle on CL30
 * @param {boolean} show
 */
export const setExploreShowAsPercentCookie = (show) => {
    setStatusCookieValue("exploreShowAsPercent", show);
};

/**
 * check the status cookie to see should show explore (CL30) data as a percent
 * @returns {boolean|undefined}
 */
export const getExploreShowAsPercentFromCookie = () => {
    const statusCookie = getStatusCookie();

    return statusCookie?.exploreShowAsPercent;
};

/**
 * Clear a cookie
 * @param {string} name
 */
export const clearCookie = (name) => {
    cookies.set(name, "", { expires: -1, path: "/" });
    cookies.set(name, "", {
        expires: -1,
        path: "/",
        domain: ".publicrelay.com",
    });
};

/**
 * Clear the status cookie
 */
export const clearStatusCookie = () => {
    clearCookie(status_cookie_path);
};

/**
 * Clear the client cookie
 */
export const clearClientCookie = () => {
    clearCookie(client_cookie_path);
};

const all = {
    status_cookie_path,
    client_cookie_path,
    defaultStatusCookie,
    getClientId,
    getClientUsername: getClientUsernameFromCookie,
    getClientFullName,
};

export default all;

window.publicrelayCookies = all;
