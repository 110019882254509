import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";

// https://stackoverflow.com/questions/70655186/is-it-possible-to-use-multiple-outlets-in-a-component-in-react-router-v6
// https://reactrouter.com/docs/en/v6/hooks/use-outlet-context

const usePageIdentifier = (identifier) => {
    const { setPageIdentifier } = useOutletContext();

    useEffect(() => {
        setPageIdentifier(identifier);
    }, [setPageIdentifier, identifier]);
};

export default usePageIdentifier;
