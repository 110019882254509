import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { StyledLink } from "../../styled/StyledLink";
import PropTypes from "prop-types";

/**
 * display the linked in icon with link
 * @param linkedIn
 * @returns {JSX.Element}
 * @constructor
 */
const LinkedInIconLink = ({ linkedIn }) => (
    <StyledLink href={linkedIn} target="_blank" rel="noreferrer">
        <LinkedInIcon />
    </StyledLink>
);

LinkedInIconLink.propTypes = { linkedIn: PropTypes.string.isRequired };

export default LinkedInIconLink;
