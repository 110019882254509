import { HeaderMenuItemLink } from "./styled";
import { getClientUsername } from "../../util/users";
import useAuth from "../../auth/useAuth";
import { logout } from "@publicrelay/service/dist/client/security";
import { getLoginUrl } from "../../util/urls";
import { clearClientCookie, clearStatusCookie } from "../../util/cookies";

/**
 * create the logout functionality for the top nav
 * @returns {JSX.Element}
 * @constructor
 */
const LogoutLink = () => {
    const userName = getClientUsername();

    const authUser = useAuth();

    /**
     * click handler for the logout button
     */
    const handleClick = () => {
        const redirectUrl = `${getLoginUrl()}?s=loggedout`;

        logout({ type: "client", uuid: authUser.uuid })
            .then(() => {
                window.location.href = redirectUrl;
            })
            .catch(() => {
                //logic from dojo
                clearStatusCookie();

                clearClientCookie();

                window.location.href = redirectUrl;
            });
    };

    return (
        <HeaderMenuItemLink
            component="button"
            onClick={handleClick}
            sx={{ textAlign: "left" }}
        >
            Log Out {userName}
        </HeaderMenuItemLink>
    );
};

export default LogoutLink;
