import {
    get,
    post,
    // put,
    standardOptions,
} from "@publicrelay/service/src/service";

/**
 * returns a list of tag definitions (no groups)
 * @returns {Promise<Response>}
 */
export const getSingleTags = () =>
    get(
        "com.publicrelay.www/prrestservices/client/filters/tag/single",
        standardOptions
    );

/**
 * returns a list of tag groups (with nested tagDefinitions).
 * optionally return the subtopics (2D) group
 * @param {boolean} showSubTopics
 * @returns {Promise<Response>}
 */
export const getTagGroups = (showSubTopics = false) =>
    get(
        `com.publicrelay.www/prrestservices/taggroup/getTagGroups/${showSubTopics}`,
        standardOptions
    );

/**
 * returns a list of tag groups (with nested tagDefinitions) associated with the given tag definition and tag group
 * @param {number} clientId
 * @param {number} tagGroupId
 */
export const getAssociatedTagGroups = (clientId, tagGroupId) =>
    post(
        `com.publicrelay.www/prrestservices/tag/${clientId}/findTagGroupsClient`,
        { tagGroupId },
        standardOptions
    );

/**
 * returns tag definitions for the posted 2D tags
 * @param {number} clientId
 * @param {{tagDef1Array: number[], tagDef2Array: number[]}} params
 */
export const add2DTag = (clientId, params) =>
    post(
        `com.publicrelay.www/prrestservices/tag/${clientId}/add2dTag`,
        params,
        standardOptions
    );
