import React from "react";
import Fab from "@mui/material/Fab";
import { APP_ENVIRONMENTS } from "../util/constants";

const fabStyle = {
    position: "absolute",
    bottom: 50,
    left: 5,
    color: "white",
    pointerEvents: "none",
    cursor: "default",
    width: 65,
    height: 65,
};

const QA_COLOR = "#FF6600";
const LAB_COLOR = "#f165ac";

/**
 * US5716 QA & LAB Environment Indicator
 * @return {JSX.Element|null}
 * @constructor
 */
const FABEnvironmentIndicator = () => {
    const { REACT_APP_ENV_TYPE } = process.env;

    if (
        REACT_APP_ENV_TYPE !== APP_ENVIRONMENTS.QA &&
        REACT_APP_ENV_TYPE !== APP_ENVIRONMENTS.LAB
    ) {
        return null;
    }

    return (
        <Fab
            size="large"
            aria-label="environment"
            sx={{
                ...fabStyle,
                bgcolor:
                    REACT_APP_ENV_TYPE === APP_ENVIRONMENTS.QA
                        ? QA_COLOR
                        : LAB_COLOR,
            }}
        >
            THIS IS {REACT_APP_ENV_TYPE}
        </Fab>
    );
};

export default FABEnvironmentIndicator;
