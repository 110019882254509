import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";

/**
 * No Results component
 * TODO: move to component library / share with analyst
 * @param {string|JSX.Element} message
 * @param {number} [colspan] - default 100
 * @returns {JSX.Element}
 * @constructor
 */
const NoResults = ({ message, colspan = 100 }) => {
    return (
        <TableRow data-testid="no_results_shown">
            <TableCell colSpan={colspan}>{message}</TableCell>
        </TableRow>
    );
};

NoResults.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    colspan: PropTypes.number,
};

export default NoResults;
