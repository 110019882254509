import Link from "@mui/material/Link";
import PropTypes from "prop-types";

/**
 * output a report link
 * cloud reports open in new window
 * local file triggers download
 * @param {MyProgramReport} report
 * @returns {JSX.Element}
 * @constructor
 */
const ReportLink = ({ report, clientId }) =>
    report.tableauUrl ? (
        <Link href={report.tableauUrl} target="_blank" rel="noreferrer">
            {report.displayName}
        </Link>
    ) : report.fileName ? (
        <Link
            download
            href={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/reports/local/${report.reportId}`}
        >
            {report.displayName}
        </Link>
    ) : (
        <>{report.displayName}</>
    );

ReportLink.propTypes = {
    report: PropTypes.object.isRequired,
    clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
};

export default ReportLink;
