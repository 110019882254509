import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { ProgramButton } from "../../components/myProgram/styled";
import useAuth from "../../auth/useAuth";
import PRTableHead from "../../components/shared/tables/PRTableHead";
import { useTableSort } from "../../hooks/useTableSort";
import {
    ADD_EDIT_DIALOG_DEFAULT_STATE,
    NO_REPORTS_MESSAGE,
    REPORTS_HEAD_CELLS,
} from "../../components/myProgram/constants";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PREditIcon from "../../components/icons/PREditIcon";
import { PRDeleteIcon } from "@publicrelay/component-library";
import { useEffect, useMemo, useState } from "react";
import BackdropLoader from "../../components/shared/BackdropLoader";
import NoResults from "../../components/shared/NoResults";
import {
    deleteMyProgramReport,
    getMyProgramReports,
} from "@publicrelay/service/dist/client/myProgram";
import { getDefaultErrorHandler } from "../../util/services";
import Box from "@mui/material/Box";
import ReportLink from "../../components/myProgram/ReportLink";
import { useConfirmationDialog } from "@publicrelay/component-library";
import { orderBy as _orderBy } from "lodash/collection";
import AddEditReportDialog from "../../components/myProgram/AddEditReportDialog";

/**
 * display My Program Reports (cloud reports & uploaded files)
 * also allows internal users to add / edit reports
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Reports = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { isInternalClientUser, clientId } = useAuth();

    const { order, orderBy, handleRequestSort } = useTableSort();

    const [reports, setReports] = useState([]);

    const [loading, setLoading] = useState(true);

    const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);

    const [draftReport, setDraftReport] = useState(
        ADD_EDIT_DIALOG_DEFAULT_STATE
    );

    const { getConfirmation } = useConfirmationDialog();

    /**
     * call service to get the reports data
     */
    useEffect(() => {
        getMyProgramReports(clientId)
            .then((response) => {
                setReports(response.items || []);
            })
            .catch(getDefaultErrorHandler("getMyProgramReports"))
            .finally(() => {
                setLoading(false);
            });
    }, [clientId]);

    /**
     * handle add new report click (internal users only)
     */
    const handleAddNew = () => {
        setAddEditDialogOpen(true);
    };

    /**
     * set dialog data and open dialog (internal users only)
     * @param {MyProgramReport} report
     * @returns {(function(): void)|*}
     */
    const handleEditClick = (report) => () => {
        setAddEditDialogOpen(true);

        setDraftReport({ ...report });
    };

    /**
     * close / reset dialog (internal users only)
     */
    const closeAddEdit = () => {
        setAddEditDialogOpen(false);

        setDraftReport(ADD_EDIT_DIALOG_DEFAULT_STATE);
    };

    /**
     * handle updating reports state from successful add or edit (internal users only)
     * @param {MyProgramReport} response
     */
    const handleAddEditSuccess = (response) => {
        if (draftReport.reportId) {
            setReports(
                reports.map((report) =>
                    report.reportId === response.reportId ? response : report
                )
            );
        } else {
            // add newly created report
            setReports([...reports, response]);
        }

        closeAddEdit();
    };

    /**
     * handle the delete icon click (internal users only); delete the given report
     * @param {number} reportId
     * @param {string} displayName
     * @returns {(function(): Promise<void>)|*}
     */
    const handleDelete = (reportId, displayName) => async () => {
        const message = (
            <Box component="p" mt={0}>
                Are you sure you want to delete {displayName}?
            </Box>
        );

        const confirmed = await getConfirmation({
            dialogProps: {
                title: "Confirm Delete",
                maxWidth: "sm",
                submitText: "Delete",
            },
            message,
        });

        if (confirmed) {
            deleteReport(reportId);
        }
    };

    /**
     * call service to delete and update state afterward
     * @param {number} reportId
     */
    const deleteReport = (reportId) => {
        deleteMyProgramReport(clientId, reportId)
            .then(() => {
                setReports(
                    reports.filter((report) => report.reportId !== reportId)
                );
            })
            .catch(getDefaultErrorHandler("deleteReport"));
    };

    /**
     * order the rows if sort is applied
     * @type {unknown}
     */
    const sortedReports = useMemo(() => {
        if (orderBy) {
            return _orderBy(reports, [orderBy], [order]);
        }

        return reports;
    }, [reports, order, orderBy]);

    return (
        <MyProgramLayoutContainer title="Reports">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h1" component="h1">
                    Reports
                </Typography>
                {isInternalClientUser && (
                    <ProgramButton onClick={handleAddNew}>
                        <AddBoxOutlinedIcon sx={{ mr: 1 }} /> New Report
                    </ProgramButton>
                )}
            </PRPageContentContainer>

            {loading ? (
                <BackdropLoader open={true} />
            ) : (
                <Table
                    sx={{
                        minWidth: "100%",
                    }}
                    stickyHeader
                >
                    <PRTableHead
                        columns={REPORTS_HEAD_CELLS}
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        {...(isInternalClientUser && {
                            showEditIcon: true,
                            showDeleteIcon: true,
                        })}
                    />
                    <TableBody>
                        {sortedReports.length > 0 ? (
                            sortedReports.map((report) => (
                                <TableRow key={report.reportId}>
                                    <TableCell>{report.dateRange}</TableCell>
                                    <TableCell>
                                        <ReportLink
                                            report={report}
                                            clientId={clientId}
                                        />
                                    </TableCell>
                                    <TableCell>{report.reportType}</TableCell>
                                    <TableCell>{report.cadence}</TableCell>
                                    {isInternalClientUser && (
                                        <>
                                            <TableCell align="center">
                                                <PREditIcon
                                                    handleClick={handleEditClick(
                                                        report
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <PRDeleteIcon
                                                    handleClick={handleDelete(
                                                        report.reportId,
                                                        report.displayName
                                                    )}
                                                />
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <NoResults message={NO_REPORTS_MESSAGE} />
                        )}
                    </TableBody>
                </Table>
            )}

            {addEditDialogOpen && (
                <AddEditReportDialog
                    handleAddEditSuccess={handleAddEditSuccess}
                    closeAddEdit={closeAddEdit}
                    draftReport={draftReport}
                    setDraftReport={setDraftReport}
                    clientId={clientId}
                />
            )}
        </MyProgramLayoutContainer>
    );
};

export default CL01Reports;
