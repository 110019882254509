import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import ManageButton from "../../components/myProgram/ManageButton";
import Box from "@mui/material/Box";
import PRSelect from "../../components/shared/PRSelect";
import PRPagination from "../../components/shared/PRPagination";
import {
    ALERTS_HEAD_CELLS,
    MY_PROGRAM_PAGE_SIZE,
    NO_ALERT_RECIPIENTS_MESSAGE,
    NO_ALERTS_MESSAGE,
} from "../../components/myProgram/constants";
import Table from "@mui/material/Table";
import PRTableHead from "../../components/shared/tables/PRTableHead";
import TableBody from "@mui/material/TableBody";
import NoResults from "../../components/shared/NoResults";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import useAuth from "../../auth/useAuth";
import { useEffect, useMemo, useState } from "react";
import { useTableSort } from "../../hooks/useTableSort";
import { updateMyProgramVisibleAlerts } from "@publicrelay/service/dist/client/myProgram";
import { getAllAlerts } from "@publicrelay/service/dist/clientAnalyst/alerts";
import { getDefaultErrorHandler } from "../../util/services";
import {
    calculateSelectedItem,
    getDisplayedItems,
    getOptions,
} from "../../components/myProgram/util";
import ManageVisibleItems from "../../components/myProgram/ManageVisibleItems";
import { convert24HourTo12 } from "../../util/dates";
import useManagePagination from "../../hooks/useManagePagination";
import ExportLink from "../../components/myProgram/ExportLink";

/**
 * @typedef {{
 * articlesFromSelectedDays: boolean,
 * betweenHoursDisplay?: string,
 * betweenHoursFrom?: number,
 * betweenHoursTo?: number,
 * groupingId: number,
 * groupingScheduleId: number,
 * hourInterval: string,
 * minuteInterval: string,
 * recurringTimesDisplay?: string,
 * selectedDaysArray: string,
 * selectedDaysDisplay: string,
 * sendImmediately: boolean,
 * specificTimeRange: boolean,
 * specificTimes?: string[],
 * specificTimesDisplay?: string,
 * }} AlertSchedule
 */

/**
 * display alerts info (send time, recipients) when visible on client side and visible on My Program
 * allow internal users to edit what alerts are visible on My Program
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Alerts = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const [alertsList, setAlertsList] = useState();

    const [selectedAlertId, setSelectedAlertId] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [manageAlertsOpen, setManageAlertsOpen] = useState(false);

    const [draftAlertsList, setDraftAlertsList] = useState([]);

    const { isInternalClientUser, clientId } = useAuth();

    const { order, orderBy, handleRequestSort, resetSort } = useTableSort();

    const { currentPage, setCurrentPage, handlePagination } =
        useManagePagination();

    // on page load get data
    // one service returns all info needed for display
    useEffect(() => {
        getAllAlerts(clientId, { activeAlerts: true })
            .then((response) => {
                setAlertsList(response.rawFeedAlerts);
            })
            .catch(getDefaultErrorHandler("getAllAlerts"))
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    /**
     * open the dialog to manage My Program visible alerts
     * the button to open the dialog should only be enabled when there are client visible alerts
     */
    const handleManageClick = () => {
        setManageAlertsOpen(true);

        setDraftAlertsList(
            alertsList.map((alert) => ({
                rawFeedAlertId: alert.rawFeedAlertId,
                name: alert.name,
                myProgramVisible: alert.myProgramVisible,
            }))
        );
    };

    /**
     * close / reset the manage alerts dialog
     */
    const handleDialogClose = () => {
        setManageAlertsOpen(false);

        setDraftAlertsList([]);
    };

    /**
     * passed to the manage alerts dialog; update the alerts with My Program visible info
     */
    const handleDialogSubmit = () => {
        updateMyProgramVisibleAlerts(clientId, draftAlertsList)
            .then((response) => {
                setAlertsList(response.rawFeedAlerts);

                // if we've toggled the currently selected alert not visible in "My Program",
                // reset the selected option to avoid MUI complaining the value doesn't match
                // an option when we update the alerts available for selection
                if (
                    response.rawFeedAlerts.some(
                        (alert) =>
                            alert.rawFeedAlertId === selectedAlertId &&
                            !alert.myProgramVisible
                    )
                ) {
                    setSelectedAlertId("");

                    // also reset current page since we'll get new alert data
                    setCurrentPage(1);
                }

                handleDialogClose();
            })
            .catch(getDefaultErrorHandler("updateMyProgramVisibleAlerts"));
    };

    /**
     * handle the select change
     * update the selected alert in state
     * @param target
     */
    const handleAlertChange = ({ target }) => {
        setSelectedAlertId(target.value);

        setCurrentPage(1);

        if (orderBy) {
            resetSort();
        }
    };

    /**
     * format the "My Program" visible alerts for use in the select
     * @type {{label: string, value: number}[]}
     */
    const alertsSelectOptions = useMemo(
        () => getOptions(alertsList, "rawFeedAlertId"),
        [alertsList]
    );

    /**
     * when the select options change we might need to update which alert is currently being viewed
     * 2 cases: (1) page load, (2) when the alert is made not visible in Manage
     */
    useEffect(() => {
        setSelectedAlertId((selectedAlert) => {
            return calculateSelectedItem(alertsSelectOptions, selectedAlert);
        });
    }, [alertsSelectOptions]);

    /**
     * reset to the first page when the table sort changes
     */
    useEffect(() => {
        setCurrentPage(1);
    }, [orderBy, order, setCurrentPage]);

    // determine alert specifics for display. recipients used for display but also pagination calc
    const { recipients, scheduleDetails } = useMemo(() => {
        const alertDetail = alertsList?.find(
            (alert) => alert.rawFeedAlertId === selectedAlertId
        );

        let scheduleDetails = [];

        if (alertDetail) {
            /**
             * @type AlertSchedule
             */
            const schedule = alertDetail.schedule;

            scheduleDetails.push(schedule.selectedDaysDisplay);

            if (schedule.sendImmediately && schedule.betweenHoursDisplay) {
                scheduleDetails.push("Send Immediately");

                const [from, to] = schedule.betweenHoursDisplay.split("-");

                scheduleDetails.push(
                    `${convert24HourTo12(from)} - ${convert24HourTo12(to)}`
                );
            } else if (schedule.recurringTimesDisplay) {
                scheduleDetails.push(schedule.recurringTimesDisplay);

                const [from, to] = schedule.betweenHoursDisplay.split("-");

                scheduleDetails.push(
                    `${convert24HourTo12(from)} - ${convert24HourTo12(to)}`
                );
            } else {
                schedule.specificTimes.forEach((time) => {
                    scheduleDetails.push(convert24HourTo12(time));
                });
            }
        }

        return { recipients: alertDetail?.externalUsers, scheduleDetails };
    }, [selectedAlertId, alertsList]);

    /**
     * apply sorting and pagination to the recipients list; this is what will be displayed in the table
     * @type {{clientUserId: number, email: string, fullName: string, username: string}[]}
     */
    const displayedRecipients = useMemo(() => {
        if (!recipients?.length) {
            return [];
        }

        return getDisplayedItems({
            items: recipients,
            orderBy,
            order,
            page: currentPage,
        });
    }, [recipients, orderBy, order, currentPage]);

    return (
        <MyProgramLayoutContainer title="Alerts & Recipients">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <Typography variant="h1" component="h1">
                        Alerts & Recipients
                    </Typography>
                    <ExportLink
                        url={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/alert/${selectedAlertId}/export`}
                        disabled={!selectedAlertId}
                    />
                </Box>
                <ManageButton
                    isInternalClientUser={isInternalClientUser}
                    handleClick={handleManageClick}
                    text="Manage Alerts"
                    disabled={!alertsList?.length}
                />
            </PRPageContentContainer>

            <PRPageContentContainer mb={2}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <PRSelect
                            label="Select Alert"
                            options={alertsSelectOptions}
                            formControlProps={{
                                disabled: !alertsSelectOptions.length,
                                sx: { width: "300px", mb: 1 },
                            }}
                            selectProps={{
                                name: "alert",
                                onChange: handleAlertChange,
                                value: selectedAlertId,
                            }}
                        />
                    </Box>

                    <PRPagination
                        pageSize={MY_PROGRAM_PAGE_SIZE}
                        resultCount={recipients?.length || 0}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                    />
                </Box>
                {scheduleDetails.length > 0 && (
                    <Typography>{scheduleDetails.join(", ")} EDT</Typography>
                )}
            </PRPageContentContainer>

            <Table
                sx={{
                    minWidth: "100%",
                }}
                stickyHeader
            >
                <PRTableHead
                    columns={ALERTS_HEAD_CELLS}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                />
                {!isLoading && (
                    <TableBody>
                        {!alertsSelectOptions.length ? (
                            <NoResults message={NO_ALERTS_MESSAGE} />
                        ) : !displayedRecipients?.length ? (
                            <NoResults message={NO_ALERT_RECIPIENTS_MESSAGE} />
                        ) : (
                            displayedRecipients.map((recipient) => (
                                <TableRow key={recipient.clientUserId}>
                                    <TableCell>{recipient.email}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                )}
            </Table>

            {manageAlertsOpen && (
                <ManageVisibleItems
                    open={true}
                    items={draftAlertsList}
                    handleDialogSubmit={handleDialogSubmit}
                    setItems={setDraftAlertsList}
                    handleClose={handleDialogClose}
                    idLabel="rawFeedAlertId"
                    type="Alerts"
                >
                    Choose which alerts to show on this page. These settings
                    will not affect which alerts appear elsewhere within
                    PublicRelay systems. Visit AN-06b for additional alert
                    settings.
                </ManageVisibleItems>
            )}
        </MyProgramLayoutContainer>
    );
};

export default CL01Alerts;
