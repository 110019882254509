import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/system";

/**
 * this is a container that can be used to hold page content
 * for easy reuse of standard bg color and padding/margins
 */
export const PRPageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
}));

/**
 * this is a container that can be used to hold content in a tab panel when the panel
 * does not use default panel background and spacing (See PRTabPanel)
 * this container creates the same background/spacing as the panel defaults
 */
export const PRPanelContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
}));

export const PRPanelContainerSmall = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
}));

export const PRCardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(4),
    borderRadius: 0,
}));

export const PRSidePanelContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: 355,
}));

export const PRPageContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    padding: theme.spacing(4),
}));

/**
 * Line clamp our div to 2 lines, used throughout the client side when displaying titles in tables
 */
export const LineClampBox = styled(Box)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    maxHeight: "3em",
    wordBreak: " break-word",
});
