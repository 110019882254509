import { orderBy as _orderBy } from "lodash/collection";
import { MY_PROGRAM_PAGE_SIZE } from "./constants";

/**
 * parse data to create array of {label, value} for use with the selects on My Program
 * @param {array} items
 * @param {string} idLabel
 * @returns {*[]|*}
 */
export const getOptions = (items, idLabel) => {
    if (items) {
        return items
            .filter((item) => item.myProgramVisible)
            .map((visible) => ({
                label: visible.name,
                value: visible[idLabel],
            }))
            .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );
    }

    return [];
};

/**
 * shared by newsletters and alerts, determine the value for the selected item if there isn't one already
 * @param {{label: string, value: string|number}[]} options
 * @param {number|string} selectedId
 * @returns {string|*}
 */
export const calculateSelectedItem = (options, selectedId) => {
    if (!options.length) {
        // when no items are visible to client the select is empty. in this case the selected id
        // is already the default state of "". we also disallow turning off all items in "my program", so this
        // shouldn't happen via "manage", but just in case...
        return "";
    }

    // force the first option when an item isn't selected. ie, when page loads
    // or when the item is no longer visible (when toggled off in manage)
    if (!selectedId) {
        return options[0].value;
    }

    return selectedId;
};

/**
 * sort and paginate items
 * @param items
 * @param orderBy
 * @param order
 * @param page
 * @param pageSize
 * @returns {*[]}
 */
export const getDisplayedItems = ({
    items,
    orderBy,
    order,
    page,
    pageSize = MY_PROGRAM_PAGE_SIZE,
}) => {
    let itemsClone = [...items];

    if (orderBy) {
        itemsClone = _orderBy(
            items,
            function (item) {
                if (item[orderBy]) {
                    if (typeof item[orderBy] === "string") {
                        return item[orderBy].toLowerCase();
                    } else {
                        return item[orderBy];
                    }
                } else {
                    // per lodash - "by design when in asc order NaN, nullish, and symbol values are at the end so
                    // it fits that when desc they'd be at the beginning". we don't want them to be, so
                    // we will force empty string for comparison
                    return "";
                }
            },
            [order]
        );
    }

    const start = (page - 1) * pageSize;

    const end = page * pageSize;

    return itemsClone.slice(start, end);
};

/**
 * return only tag groups with tag definitions
 * @param {array} tagGroups
 * @returns {array}
 */
export const filterEmptyTagGroups = (tagGroups) =>
    tagGroups.filter((tg) => tg.tagDefinitions.length);

/**
 * sort tag definitions: by alias if it exists, otherwise by name
 * @param tagDefsArray
 * @returns {*}
 */
export const sortTagDefs = (tagDefsArray) =>
    [...tagDefsArray].sort((a, b) => {
        const aName = a.alias || a.name;
        const bName = b.alias || b.name;
        return aName.toLowerCase().localeCompare(bName.toLowerCase());
    });
