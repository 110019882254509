import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
    selectMyProgramClientLogoEnabled,
    selectMyProgramClientLogoUrl,
    selectMyProgramNavItems,
    setMyProgramSettingsData,
} from "../../redux/slices/myProgramSlice";
import { SETTINGS_CHECKBOX_LABELS_MAP } from "../../components/myProgram/constants";
import { updateMyProgramSettings } from "@publicrelay/service/dist/client/myProgram";
import { getClientId } from "../../util/cookies";
import { getDefaultErrorHandler } from "../../util/services";
import { PRSwitch } from "@publicrelay/component-library";
import PRDropzone from "../../components/shared/form/PRDropzone";
import useNotification from "../../hooks/useNotification";
import { LegendFormLabel } from "../../components/myProgram/styled";

/**
 * display the Settings page in "My Program". This page is only visible to analysts. It allows for
 * configuration of the "My Program" pages that are visible to the client, and the client logo
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Settings = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    /**
     * maintain state - the settings the user is editing before clicking Save
     */
    const [draftSettings, setDraftSettings] = useState();

    /**
     * the configured items currently visible in the "My Program" navigation
     * @type {{
     * tagsEnabled: boolean,
     * usersEnabled: boolean,
     * newslettersEnabled: boolean,
     * alertsEnabled: boolean,
     * outletListsEnabled: boolean,
     * reportsEnabled: boolean,
     * }|undefined}
     */
    const navItems = useSelector(selectMyProgramNavItems);

    const logoEnabled = useSelector(selectMyProgramClientLogoEnabled);

    const clientLogoUrl = useSelector(selectMyProgramClientLogoUrl);

    const dispatch = useDispatch();

    const { notify } = useNotification();

    /**
     * set the state for editing
     */
    useEffect(() => {
        if (navItems) {
            setDraftSettings({
                navItems: { ...navItems },
                logoEnabled,
            });
        }
    }, [navItems, logoEnabled]);

    /**
     * handle change of the checkboxes. checked items will be visible in the navigation after save
     * @param event
     */
    const handleChange = (event) => {
        setDraftSettings({
            ...draftSettings,
            navItems: {
                ...draftSettings.navItems,
                [event.target.name]: event.target.checked,
            },
        });
    };

    /**
     * handle the client logo switch on/off
     */
    const handleToggleClientLogoEnabled = () => {
        setDraftSettings((settings) => ({
            ...settings,
            logoEnabled: !settings.logoEnabled,
        }));
    };

    /**
     * passed to the PRDropzone component; update state with new files
     * @param files
     */
    const handleLogoChange = (files) => {
        setDraftSettings({
            ...draftSettings,
            uploadedLogo: [...files],
        });
    };

    /**
     * passed to the PRDropzone component (trash can click); remove the uploadedLogo
     */
    const handleLogoRemove = () => {
        setDraftSettings({
            ...draftSettings,
            uploadedLogo: undefined,
        });
    };

    /**
     * handle click of save button
     * call service to update the current settings
     */
    const handleSave = () => {
        if (
            draftSettings.logoEnabled &&
            !(clientLogoUrl || draftSettings.uploadedLogo)
        ) {
            notify("You must select an image file.", "error");
            return;
        }

        updateMyProgramSettings(getClientId(), {
            ...draftSettings.navItems,
            ...(draftSettings.uploadedLogo && {
                uploadedLogo: draftSettings.uploadedLogo[0],
            }),
            logoEnabled: draftSettings.logoEnabled,
        })
            .then((response) => {
                // update the redux store so that the "My Program"s navigation reflects changes
                dispatch(setMyProgramSettingsData(response));
            })
            .catch(getDefaultErrorHandler("updateMyProgramSettings"));
    };

    return (
        <MyProgramLayoutContainer title="Settings">
            <PRPageContentContainer>
                <Typography variant="h1" component="h1" mb={2}>
                    Settings
                </Typography>
                <Typography mb={2}>
                    Use these settings to configure the My Program page
                    according to your client's preferences.
                </Typography>

                <Box mb={1}>
                    <PRSwitch
                        formControlLabelConfig={{
                            label: "Client Logo",
                        }}
                        switchConfig={{
                            onChange: handleToggleClientLogoEnabled,
                        }}
                        applyAutoLeftMargin={true}
                        checked={!!draftSettings?.logoEnabled}
                    />
                </Box>

                {draftSettings?.logoEnabled && (
                    <>
                        <Typography mb={2}>
                            Upload a square PNG or JPG logo image. Suggested
                            dimensions are 400x400 pixels. The image will be
                            cropped into a circle. If unsure which image to use,
                            visit your client's social media account and use
                            their profile image. Please ensure the file name
                            does NOT contain spaces.
                        </Typography>
                        <Box width={250} mb={2}>
                            <PRDropzone
                                accept={{
                                    "image/*": [".png", ".jpeg", ".jpg"],
                                }}
                                multiple={false}
                                showPreviews={true}
                                onDrop={handleLogoChange}
                                onRemovePreview={handleLogoRemove}
                                previewFiles={draftSettings.uploadedLogo}
                            />
                        </Box>
                    </>
                )}

                <FormControl component="fieldset" variant="standard">
                    <LegendFormLabel component="legend">
                        Included Optional Tabs:
                    </LegendFormLabel>
                    <FormGroup>
                        {draftSettings?.navItems &&
                            Object.entries(draftSettings.navItems).map(
                                (entry) => {
                                    const [key, value] = entry;
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={handleChange}
                                                    name={key}
                                                />
                                            }
                                            label={
                                                SETTINGS_CHECKBOX_LABELS_MAP[
                                                    key
                                                ]
                                            }
                                        />
                                    );
                                }
                            )}
                    </FormGroup>
                </FormControl>
                <br />
                <br />
                <Box>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </PRPageContentContainer>
        </MyProgramLayoutContainer>
    );
};

export default CL01Settings;
