import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { getDefaultErrorHandler } from "../../util/services";
import { getEnabledClientNelis } from "@publicrelay/service/dist/client/languages";
import { STATUS_VALUE_MAP } from "../util";

// this will be stored at state.languages
export const name = "languages";

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get the enabled neli languages for the current client
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchEnabledClientNelis = createAsyncThunk(
    `${name}/fetchEnabledClientNelis`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getEnabledClientNelis();

            return response.items;
        } catch (error) {
            getDefaultErrorHandler("fetchEnabledClientNelis")(error);

            return rejectWithValue(error);
        }
    }
);

const initialState = {
    enabledClientNelis: null,
    status: STATUS_VALUE_MAP.IDLE,
};

const languagesSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        setEnabledClientNelis(state, { payload }) {
            state.enabledClientNelis = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchEnabledClientNelis.pending, (state) => {
                state.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchEnabledClientNelis.fulfilled, (state, action) => {
                state.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our neli languages to our state
                state.enabledClientNelis = action.payload;
            });
        // .addCase(
        //     getEnabledClientNelisFromService.rejected,
        //     (state, action) => {
        //         state.status = "failed";
        //         state.error = action.error.message;
        //     }
        // );
    },
});

/**
 * Select the current list of client enabled non-english languages
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectEnabledClientNelis = createSelector(
    (state) => state[name],
    (subState) => subState.enabledClientNelis
);

/**
 * select list of client enabled nelis sorted by language name
 */
export const selectSortedEnabledClientNelis = createSelector(
    selectEnabledClientNelis,
    (nelis) => {
        return nelis
            ? [...nelis].sort((a, b) => {
                  if (a.name < b.name) {
                      return -1;
                  }
                  if (a.name > b.name) {
                      return 1;
                  }
                  return 0;
              })
            : nelis;
    }
);

export const selectStatus = createSelector(
    (state) => state[name],
    (subState) => subState.status
);

export const { setEnabledClientNelis } = languagesSlice.actions;

export default languagesSlice.reducer;
