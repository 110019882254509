export const selectAllChildren = (node, parentSelected) => {
    if (node.subjects) {
        return node.subjects.map((subNode) => {
            return {
                ...subNode,
                selected: parentSelected,
                ...(subNode.subjects && {
                    subjects: selectAllChildren(subNode, parentSelected),
                }),
            };
        });
    } else {
        return node;
    }
};

export const replaceNodeInTree = (node, subjects) => {
    if (subjects.filter((branch) => branch.nodeId === node.nodeId).length) {
        return [
            ...subjects.filter((branch) => branch.nodeId !== node.nodeId),
            node,
        ].sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    } else {
        return subjects.map((child) => {
            if (child.subjects.length) {
                return {
                    ...child,
                    subjects: replaceNodeInTree(node, child.subjects),
                };
            } else {
                return child;
            }
        });
    }
};

export const isIndeterminate = (node) => {
    if (node.subjects) {
        if (node.subjects.filter((child) => child.selected).length > 0) {
            if (
                node.subjects.filter((child) => child.selected).length ===
                node.subjects.length
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            for (let i = 0; i < node.subjects.length; i += 1) {
                if (isIndeterminate(node.subjects[i])) {
                    return true;
                }
            }
        }
    } else {
        return false;
    }
};

/**
 * DO NOT TOUCH. MAGIC. Nicolai Gomez 7/6/2022
 *
 * Condition 1: Are we at a leaf node (no children): yes -> return the node as selected. i.e. do not change
 * Condition 2: Do we have children -> Yes. Go through each level of the node, down to the leaf to see if there are
 * any children that are not checked. If we happen to come across one, loop breaks and we know that the parent should not be checked.
 * Recursively check each level.
 *
 *
 * @param {object} node
 * @returns
 */

export const isChildChecked = (node) => {
    if (node.subjects.length) {
        for (let i = 0; i < node.subjects.length; i++) {
            if (!isChildChecked(node.subjects[i])) {
                return false;
            }
        }
        return true;
    } else {
        return node.selected;
    }
};

export const isParentChecked = (subjectArray) => {
    return subjectArray.map((child) => {
        if (child.subjects) {
            return {
                ...child,
                selected: isChildChecked(child),
                subjects: isParentChecked(child.subjects),
            };
        } else {
            return child;
        }
    });
};

export const removeIds = (array, subjectIds) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].selected) {
            subjectIds.push(array[i].subjectId);
        }
        if (array[i].subjects.length) {
            removeIds(array[i].subjects, subjectIds);
        }
    }

    return subjectIds;
};

/**_____________________Domain Object Transform___________________________ */

/**
 *
 * @param {*} subject
 * @param {*} index
 * @param {*} path
 * @returns
 */
export const discoverDO = (subject, index, path = []) => {
    return {
        ...subject,
        selected: false,
        indeterminate: false,
        index: index,
        path: path.length
            ? [...path, subject.nodeId.toString()]
            : [subject.nodeId.toString()],
        subjects: subject.subjects
            ? subject.subjects.map((subSubject, index) => {
                  return discoverDO(
                      subSubject,
                      index,
                      path
                          ? [...path, subject.nodeId.toString()]
                          : [subject.nodeId.toString()]
                  );
              })
            : [],
    };
};

export const discoverAuthorDTO = (payload, paginate) => {
    return {
        cacheKey: payload.cacheKey,
        nonce: new Date().getTime(),
        organizations: payload.organizations.map((org) => org.itemName),
        pageSize: 20,
        profileIds: payload.profileIds.map((author) =>
            author.itemId.toString()
        ),
        subjectIds: payload.subjectIds,
        ...(paginate && { pageNumber: payload.pageNumber }),
    };
};

export const discoverOutletsDTO = (payload, paginate) => {
    return {
        cacheKey: payload.cacheKey,
        nonce: new Date().getTime(),
        mediaTypes: payload.mediaTypes,
        pageSize: 20,
        profileIds: payload.profileIds.map((author) =>
            author.itemId.toString()
        ),
        outletNames: payload.outletNames,
        subjectIds: payload.subjectIds,
        ...(paginate && { pageNumber: payload.pageNumber }),
    };
};

export const getAuthorsExportUrl = (cacheKey) => {
    return `/com.publicrelay.www/prrestservices/discovery/discoverAuthors/exportCsv/${cacheKey}`;
};

export const getOutletsExportUrl = (cacheKey) => {
    return `/com.publicrelay.www/prrestservices/discovery/discoverOutlets/exportCsv/${cacheKey}`;
};

export const turnAllToFalse = (subjectTree) => {
    return subjectTree.map((branch) => {
        return {
            ...branch,
            selected: false,
            subjects: branch.subjects ? turnAllToFalse(branch.subjects) : [],
        };
    });
};

/**
 * Calculates twitter URL given a handle
 * @param {string} handle
 * @returns
 */
export const calculateTwitterUrl = (handle) => {
    return `https://twitter.com/${handle}`;
};
