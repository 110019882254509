import {
    bindHover,
    bindMenu,
    usePopupState,
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { HeaderMenuButton } from "./styled";
import PropTypes from "prop-types";
import useSelectedNav from "./useSelectedNav";

/**
 * creates the top nav menu item and places children in the popover menu
 * @param children
 * @param {string} navPopoverName
 * @param {string} displayName
 * @param rest - any additional props are supplied to the HoverMenu
 * @return {JSX.Element}
 * @constructor
 */
const DropdownMenuItem = ({
    children,
    navPopoverName,
    displayName,
    ...rest
}) => {
    const popupState = usePopupState({
        variant: "popover",
        popupId: navPopoverName,
    });

    const isNavSelected = useSelectedNav(navPopoverName);

    return (
        <>
            <HeaderMenuButton
                {...bindHover(popupState)}
                sx={{
                    ...(popupState.isOpen && {
                        color: "primary.main",
                    }),
                }}
                className={isNavSelected ? "active" : ""}
            >
                {displayName}
            </HeaderMenuButton>

            <HoverMenu
                MenuListProps={{
                    onClick: popupState.close,
                    sx: {
                        padding: 0,
                        margin: 0,
                        ...(popupState.anchorEl && {
                            minWidth: popupState.anchorEl.offsetWidth,
                        }),
                    },
                }}
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                    elevation: 0,
                    sx: { borderRadius: 0 },
                }}
                {...rest}
            >
                {/*<MenuItem onClick={popupState.close}>Cake</MenuItem>*/}
                {children}
            </HoverMenu>
        </>
    );
};

DropdownMenuItem.propTypes = {
    children: PropTypes.node,
    navPopoverName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
};

export default DropdownMenuItem;
