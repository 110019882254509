/**
 * Global App Constants
 */

/** Possible App Environments */
export const APP_ENVIRONMENTS = {
    DEV: "DEV",
    LAB: "LAB",
    QA: "QA",
    PROD: "PROD",
};

export const DEFAULT_ARTICLE_URL = "http://www.publicrelay.com/no-url-available/";
export const MALICIOUS_ARTICLE_URL = "http://www.publicrelay.com/malicious-article-url/";
