import BackdropLoader from "../components/shared/BackdropLoader";

/**
 * component to display as Suspense fallback element (see route config)
 * @return {JSX.Element}
 * @constructor
 */
const Loading = () => {
    return <BackdropLoader open={true} />;
};

export default Loading;
