import { createFilterOptions } from "@mui/material/Autocomplete";

/**
 * possible methods for selected tags
 * @type {{EXCLUDES: string, INCLUDES: string}}
 */
export const GROUP_METHODS = {
    INCLUDES: "INCLUDES",
    EXCLUDES: "EXCLUDES",
};

/**
 * default state for selected tag definitions
 * @type {{}}
 */
export const DEFAULT_SELECTED_TAG_DEFINITION_STATE = {
    [GROUP_METHODS.EXCLUDES]: [],
    [GROUP_METHODS.INCLUDES]: [],
};

/**
 * tone values
 * @type {number[]}
 */
export const DEFAULT_TONE_VALUES = [-2, -1, 0, 1, 2];

/**
 * signficance values
 * @type {number[]}
 */
export const DEFAULT_SIGNIFICANCE_VALUES = [0, 1, 2];

/**
 * determine the filtered options to be rendered on search
 * limits the number of tag options shown to the user at any given time for performance purposes
 * @type {(options: [], state: FilterOptionsState<>) => []}
 */
export const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 250,
});
