import {
    getClientCookie,
    getClientUsernameFromCookie,
    getClientUuidFromCookie,
    getLastViewedNewsFeedIdFromCookie,
    getLastViewedNewsletterIdFromCookie,
    getSelectedAuthorSubNavUrlFromCookie,
    getSelectedCoverageSubNavFromCookie,
    getSelectedDashboardSubNavFromCookie,
    getSelectedOutletSubNavUrlFromCookie,
    getSelectedTwitterHandlesSubNavUrlFromCookie,
    getTwitterCoverageSubNavUrlFromCookie,
    getLastSharedNewsletterFromCookie,
    getLastSharedNewsletterSectionFromCookie,
    getLastSharedNewsFeedFromCookie,
    getSelectedLiveStreamFromCookie,
    getCL08StartDateFromCookie,
    getCL08EndDateFromCookie,
    getExploreShowAsPercentFromCookie,
    getFirstNameCookieValue,
} from "./cookies";
import {
    getDateForwardedEndOfDay,
    getDateSubtractDays,
    getUTCDateLegacy,
} from "@publicrelay/component-library";

/**
 * Is the currently logged in user an internal user?
 * @return {boolean}
 */
export const isInternalClientUser = function () {
    const username = getClientUsernameFromCookie();
    return /-/.test(username);
};

/**
 * Returns whether the current user is logged in
 * @return {boolean}
 */
export const isLoggedIn = () => {
    return !!getClientCookie();
};

/**
 * get the clientUser username
 * @returns {string}
 */
export const getClientUsername = () => getClientUsernameFromCookie() || "";

/**
 * get the clientUser firstName or default
 * @returns {string}
 */
export const getClientFirstName = () => getFirstNameCookieValue() || "";

/**
 * get the clientUser uuid
 * @returns {string|undefined}
 */
export const getClientUuid = () => getClientUuidFromCookie();

/**
 * what was the last CL08 url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getSelectedCoverageSubNav = () =>
    getSelectedCoverageSubNavFromCookie() || false;

/**
 * what was the last dashboard (CL25) url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getSelectedDashboardSubNav = () =>
    getSelectedDashboardSubNavFromCookie() || false;

/**
 * what was the last author list view (CL12) url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getSelectedAuthorSubNavUrl = () =>
    getSelectedAuthorSubNavUrlFromCookie() || false;

/**
 * what was the last outlet list view (CL14) url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getSelectedOutletSubNavUrl = () =>
    getSelectedOutletSubNavUrlFromCookie() || false;

/**
 * what was the last twitter list view (CL28) url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getTwitterCoverageSubNavUrl = () =>
    getTwitterCoverageSubNavUrlFromCookie() || false;

/**
 * what was the last twitter handles list view (CL24) url visited, if any?
 * @returns {string|boolean} - returns false if there is not a value, otherwise url including domain
 */
export const getSelectedTwitterHandlesSubNavUrl = () =>
    getSelectedTwitterHandlesSubNavUrlFromCookie() || false;

/**
 * what is the id for the last CL07 (newsletter) view visited, if any?
 * @returns {number|boolean} - returns false if there is not a value, otherwise the id of the newsletter
 */
export const getLastViewedNewsletterId = () =>
    getLastViewedNewsletterIdFromCookie() || false;

/**
 * what is the id for the last CL06 (news feed) view visited, if any?
 * @returns {number|boolean} - returns false if there is not a value, otherwise the id of the news feed
 */
export const getLastViewedNewsFeedId = () =>
    getLastViewedNewsFeedIdFromCookie() || false;

/**
 * @returns {number|boolean} - returns the id of the newsletter, otherwise false
 */
export const getLastSharedNewsletter = () =>
    getLastSharedNewsletterFromCookie() || false;
/**
 * @returns {number|boolean} - returns the id of the newsletter section, otherwise false
 */
export const getLastSharedNewsletterSection = () =>
    getLastSharedNewsletterSectionFromCookie() || false;

/**
 * @returns {number|boolean} - returns the id of the news feed, otherwise false
 */
export const getLastSharedNewsFeed = () =>
    getLastSharedNewsFeedFromCookie() || false;

/**
 * @returns {number|boolean} - returns the id of the last viewed live stream bucket, otherwise false
 */
export const getLastViewedLiveStreamBucket = () =>
    getSelectedLiveStreamFromCookie() || false;

/**
 * @typedef {{
 * prFrom: {utcLong: number, utcString: string},
 * prTo: {utcLong: number, utcString: string},
 * from: Date,
 * to: Date,
 * }} StartEndDates
 */

/**
 * Returns start and end dates that can be used in gets for data and to set values in a date picker
 * Checks the cookie for CL08 dates. We used to maintain separate dates for each page, but now we have one value used
 * throughout the application (CL08, CL12, CL13, CL30, etc)
 * If nothing is in the cookie will default one week ago for start, and today for end
 * Note that the Date in the cookie, while "rewound" to midnight and "fast forwarded" to end of the day, is NOT in PR
 * time. We do that conversion here, so you can both set the date picker and also send the PR time to the services
 * @returns {StartEndDates}
 */
export const getCL08ODates = () => {
    // determine our dates from cookie or default
    const cookieStartDate = getCL08StartDateFromCookie();

    const cookieEndDate = getCL08EndDateFromCookie();

    const fromDate = cookieStartDate
        ? new Date(cookieStartDate)
        : getDateSubtractDays({ rewind: true, daysAgo: 7 });

    const toDate = cookieEndDate
        ? new Date(cookieEndDate)
        : getDateForwardedEndOfDay({ overrideDateValues: {} });

    const prFromDates = getUTCDateLegacy(fromDate, 0, 0, 0, false);

    const prToDates = getUTCDateLegacy(toDate, 23, 59, 59, false);

    return {
        prFrom: prFromDates,
        prTo: prToDates,
        from: fromDate,
        to: toDate,
    };
};

/**
 * @returns {boolean} - returns whether CL30 data should be shown as percent
 */
export const getExploreShowAsPercent = () =>
    !!getExploreShowAsPercentFromCookie();
