import { groupBy as _groupBy } from "lodash/collection";
import {
    DEFAULT_SIGNIFICANCE_VALUES,
    DEFAULT_TONE_VALUES,
    GROUP_METHODS,
} from "./constants";

/**
 * find the index of the given tagDefId, excluding compound tags
 * @param {array} tagList
 * @param {number} tagDefId
 * @returns {number}
 */
export const findOuterGroupSinglesByTagDef = (tagList, tagDefId) =>
    tagList.findIndex(
        (tl) => tl.length === 1 && tl.some((tag) => tag.tag === tagDefId)
    );

// export const findTagGroupByOuterGroup = (tagList, outerGroup) =>
//     tagList.find((tl) => tl.some((tag) => tag.outerGroup === outerGroup));

/**
 * there are all 3 possible cases for the tone / significance arrays:
 * (1) empty, (2) all values, (3) select values (from service these *come* as strings but the service will *accept* numbers)
 * cases 1 & 2  are the same as far as the BE logic; if none are applied it treats it as all applied.
 * instead of accounting for cases 1 & 2 in the component logic we'll populate empty tone / sig with all the values by default
 * instead of accounting for case 3 in the component logic we parse the strings to numbers for use with the ToggleButton components
 * finally, also format the incoming selected tags data as expected by the component
 * @param groups
 * @returns {{excluded: Array[], included: Array[]}}
 */
export const formatGroupsProp = (groups) => {
    const _groups = groups.map((group) => ({
        ...group,
        toneArray: fixToneValues(group.toneArray),
        signifArray: fixSignifValues(group.signifArray),
    }));

    const included = _groups.filter(
        (group) => group.method === GROUP_METHODS.INCLUDES
    );

    const excluded = _groups.filter(
        (group) => group.method === GROUP_METHODS.EXCLUDES
    );

    return {
        included: getValuesOfGrouped(included),
        excluded: getValuesOfGrouped(excluded),
    };
};

/**
 * when the tone array is empty fill it with the default values
 * or convert string values to numbers for use with the ToggleButton component
 * @param toneArray
 * @returns {number[]}
 */
const fixToneValues = (toneArray) =>
    !toneArray.length ? DEFAULT_TONE_VALUES : toneArray.map((v) => parseInt(v));

/**
 * when the significance array is empty, fill it with the default values
 * or convert string values to numbers for use with the ToggleButton component
 * @param signifArray
 * @returns {number[]}
 */
const fixSignifValues = (signifArray) =>
    !signifArray.length
        ? DEFAULT_SIGNIFICANCE_VALUES
        : signifArray.map((v) => parseInt(v));

/**
 * first group the given arrays by the outerGroup prop and return an array of these arrays
 * @param {array} array
 * @returns {array[]}
 */
const getValuesOfGrouped = (array) =>
    Object.values(_groupBy(array, "outerGroup"));

/**
 * generate data for a new selected tag definition (shape per service requirements)
 * @param {object} tagDef
 * @param {number} tagDef.id
 * @param {string} tagDef.name
 * @param {string} method
 * @param {number} outerGroup
 * @returns {{syndicate1: boolean, toneArray: number[], syndicate2: boolean, signifArray: number[], method: string, tag, tagName}}
 */
export const generateNewSelectedTagDefinitionData = (
    tagDef,
    method = GROUP_METHODS.INCLUDES,
    outerGroup
) => ({
    tag: tagDef.id,
    tagName: tagDef.name,
    toneArray: DEFAULT_TONE_VALUES,
    signifArray: DEFAULT_SIGNIFICANCE_VALUES,
    syndicate1: false, // TODO: What is this for?
    syndicate2: true, // TODO: What is this for?
    method,
    ...(outerGroup !== undefined && {
        outerGroup,
    }),
    ...(tagDef.isMultiDimensionalTag && {
        is2Dtag: true,
    }),
});

/**
 * generate a flattened list of tags with tag groups (optional) and their tag definitions (sorted)
 * @param {array} tagGroupsArray
 * @param {boolean} [includeTagGroups]
 * @returns {array}
 */
export const getTagGroupsAndTagDefinitionsFlattened = (
    tagGroupsArray,
    includeTagGroups = true
) =>
    tagGroupsArray
        .reduce((accumulator, currentValue) => {
            const { tagDefinitions, ...currentValueRest } = currentValue;

            return includeTagGroups
                ? [...accumulator, tagDefinitions, currentValueRest]
                : [...accumulator, tagDefinitions];
        }, [])
        .flat()
        .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

/**
 * format the params for generating 2D tags
 * @param {number[]} pendingTagGroupAssociations
 * @param {number} tagDefId
 * @returns {{tagDef1Array: number[], tagDef2Array: number[]}}
 */
export const formatAdd2DParams = (pendingTagGroupAssociations, tagDefId) => {
    let params = {
        tagDef1Array: pendingTagGroupAssociations,
        tagDef2Array: [],
    };

    for (let i = 0; i < pendingTagGroupAssociations.length; i++) {
        params.tagDef2Array.push(tagDefId);
    }

    return params;
};

/**
 * does the given filter list contain any filters that are considered advanced?
 * to be considered advanced the filters must contain at least one of: excluded tags, 2D tags, sig/tone other than defaults,
 * compound tags, more than 3 single tags applied
 * @param groups
 * @returns {boolean|*}
 */
export const isAdvancedTagFilterApplied = (groups) => {
    if (!groups) {
        return false;
    }

    if (groups.length > 3) {
        return true;
    }

    return groups.some((group) => {
        if (group.method === GROUP_METHODS.EXCLUDES) {
            return true;
        }

        if (
            group.signifArray.length !== 0 &&
            group.signifArray.length !== DEFAULT_SIGNIFICANCE_VALUES.length
        ) {
            return true;
        }

        if (
            group.toneArray.length !== 0 &&
            group.toneArray.length !== DEFAULT_TONE_VALUES.length
        ) {
            return true;
        }

        if (group.is2Dtag) {
            return true;
        }

        // are any groups compound? designated by repeated outer group values
        return (
            groups.findIndex(
                (_group) =>
                    _group.tag !== group.tag &&
                    _group.outerGroup === group.outerGroup
            ) > -1
        );
    });
};
