import format from "date-fns/format";

/**
 * default date formatting for use throughout the client side
 * expected to be used with date-fns
 * see https://date-fns.org/v2.28.0/docs/format
 * @type {string}
 */
const DEFAULT_DATE_FORMAT = "MM/dd/yy";

/**
 * use date-fns to format the given date
 * @param {number | Date} date
 * @param {string} [dateFormat]
 * @returns {string}
 */
export const getFormattedDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
    return format(date, dateFormat);
};

/**
 * convert the given 24 hr time string to 12 hr. does not use dates or take time zone into account
 * @param timeString - ex 05:15
 * @returns {string}
 */
export const convert24HourTo12 = (timeString) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
};
