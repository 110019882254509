import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * create a Snackbar showing an Alert of the given severity
 * @param  {Boolean} [options.autohide]
 * @param  {Boolean} options.open
 * @param  {domNode} options.children
 * @param  {String} [options.title]
 * @param  {Boolean} [options.allowClickAway]
 * @param  {String} [options.type]
 * @param  {Function}  [options.onClose]
 * @return {Component} Snackbar
 */
const SnackAlert = ({
    autohide = false,
    open,
    children,
    title,
    allowClickAway = true,
    type = "error",
    onClose,
}) => {
    const handleSnackClose = (event, reason) => {
        if (!allowClickAway && reason === "clickaway") {
            return;
        }

        if (onClose) {
            onClose(event);
        }
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={autohide ? 6000 : null}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            {...(onClose !== undefined && { onClose: handleSnackClose })}
        >
            <Alert
                severity={type}
                {...(onClose !== undefined && { onClose: handleSnackClose })}
            >
                {title && <AlertTitle>{title}</AlertTitle>}
                <Grid container spacing={3} style={{ minWidth: "25vw" }}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Alert>
        </Snackbar>
    );
};

SnackAlert.propTypes = {
    autohide: PropTypes.bool,
    open: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string,
    allowClickAway: PropTypes.bool,
    type: PropTypes.string,
    onClose: PropTypes.func,
};

export default SnackAlert;
