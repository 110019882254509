import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

/**
 * Component to standard edit icon with tooltip
 * TODO: move to component library
 * @param {} props
 */
function PREditIcon(props) {
    if (props.disabled) {
        return (
            <IconButton disabled size={props.iconButtonSize}>
                <EditIcon color="disabled" fontSize={props.fontSize} />
            </IconButton>
        );
    } else {
        return (
            <Tooltip title={props.title}>
                <IconButton
                    size={props.iconButtonSize}
                    aria-label={props.title}
                    onClick={props.handleClick}
                >
                    <EditIcon color={props.color} fontSize={props.fontSize} />
                </IconButton>
            </Tooltip>
        );
    }
}

PREditIcon.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    fontSize: PropTypes.oneOf(["inherit", "small", "medium", "large"]),
    iconButtonSize: PropTypes.oneOf(["small", "medium", "large"]),
    color: PropTypes.oneOf(["primary", "secondary", "disabled"]),
};

//Specify the default values for props
PREditIcon.defaultProps = {
    title: "Edit",
    fontSize: "medium",
    disabled: false,
    iconButtonSize: "medium",
    color: "primary",
};

export default PREditIcon;
