import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import ManageButton from "../../components/myProgram/ManageButton";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import PRPagination from "../../components/shared/PRPagination";
import {
    MY_PROGRAM_PAGE_SIZE,
    NO_OL_MESSAGE,
    NO_OUTLETS_IN_OL_MESSAGE,
    OL_HEAD_CELLS,
} from "../../components/myProgram/constants";
import useManagePagination from "../../hooks/useManagePagination";
import { useEffect, useState, useMemo } from "react";
import PRTableHead from "../../components/shared/tables/PRTableHead";
import TableBody from "@mui/material/TableBody";
import NoResults from "../../components/shared/NoResults";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import Table from "@mui/material/Table";
import { useTableSort } from "../../hooks/useTableSort";
import useAuth from "../../auth/useAuth";
import {
    getOutletListByIdSimple,
    updateMyProgramVisibleOutletLists,
} from "@publicrelay/service/dist/client/myProgram";
import { getOutletLists } from "@publicrelay/service/dist/clientAnalyst/influencers";
import { getDefaultErrorHandler } from "../../util/services";
import {
    calculateSelectedItem,
    getDisplayedItems,
    getOptions,
} from "../../components/myProgram/util";
import PRSelect from "../../components/shared/PRSelect";
import OutletPower from "../../components/shared/outetPower/OutletPower";
import OutletContactInformation from "../../components/myProgram/OutletContactInformation";
import { getCL15CL25Url } from "../../util/urls";
import ManageVisibleItems from "../../components/myProgram/ManageVisibleItems";
import ExportLink from "../../components/myProgram/ExportLink";

/**
 * show the "My Program" Outlet List page
 * also allows internal users to edit which OLs are visible in My Program
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01OutletLists = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { isInternalClientUser, clientId } = useAuth();

    const { currentPage, setCurrentPage, handlePagination } =
        useManagePagination();

    const { order, orderBy, handleRequestSort, resetSort } = useTableSort();

    const [isLoading, setIsLoading] = useState(true);

    const [outletLists, setOutletLists] = useState();

    const [manageOlOpen, setManageOlOpen] = useState(false);

    const [draftOlVisible, setDraftOlVisible] = useState();

    const [selectedOlId, setSelectedOlId] = useState("");

    const [olDetails, setOlDetails] = useState();

    /**
     * call service to get a list of outlet lists visible to the client
     * the first time this list is loaded we'll select the first OL for display in the table (see effect)
     */
    useEffect(() => {
        getOutletLists(clientId, { clientVisible: true })
            .then((response) => {
                setOutletLists(response.items);
            })
            .catch(getDefaultErrorHandler("getOutletLists"))
            .finally(() => {
                setIsLoading(false);
            });
    }, [clientId]);

    /**
     * when the selected OL changes (set on page load, user interaction, OL changed from visible in My Program)
     * call service to get new OL details for display in the table
     */
    useEffect(() => {
        if (selectedOlId) {
            setIsLoading(true);

            getOutletListByIdSimple(clientId, selectedOlId)
                .then((response) => {
                    setOlDetails(response.listOutletsMetadata);
                })
                .catch((e) => {
                    getDefaultErrorHandler("getOutletListByIdSimple")(e);
                    setOlDetails();
                })
                .finally(() => {
                    setIsLoading(false);
                    setCurrentPage(1);
                });
        }
    }, [selectedOlId, clientId, setCurrentPage]);

    /**
     * format the "My Program" visible outlet lists for use in the select
     * @type {{label: string, value: number|string}[]}
     */
    const olSelectOptions = useMemo(
        () => getOptions(outletLists, "outletListId"),
        [outletLists]
    );

    /**
     * when the select options change we might need to update which outlet list is currently being viewed
     * 2 cases: (1) page load and (2) visible OL removed via Manage
     */
    useEffect(() => {
        setSelectedOlId((selectedOl) => {
            return calculateSelectedItem(olSelectOptions, selectedOl);
        });
    }, [olSelectOptions]);

    /**
     * reset to the first page when the table sort changes
     */
    useEffect(() => {
        setCurrentPage(1);
    }, [orderBy, order, setCurrentPage]);

    /**
     * toggle the manage outlet lists dialog open
     */
    const handleManageOpen = () => {
        setManageOlOpen(true);

        setDraftOlVisible(
            outletLists
                .map((ol) => ({
                    outletListId: ol.outletListId,
                    name: ol.name,
                    myProgramVisible: ol.myProgramVisible,
                }))
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
        );
    };

    /**
     * close / reset Manage dialog
     */
    const handleDialogClose = () => {
        setManageOlOpen(false);

        setDraftOlVisible();
    };

    /**
     * passed to the Manage dialog; update My Program visible OLs
     */
    const handleDialogSubmit = () => {
        updateMyProgramVisibleOutletLists(clientId, draftOlVisible)
            .then((response) => {
                setOutletLists(response.items);

                // if we've toggled the currently selected OL not visible in "My Program",
                // reset the selected option to avoid MUI complaining the value doesn't match
                // an option when we update the OLs available for selection
                if (
                    response.items.some(
                        (ol) =>
                            ol.outletListId === selectedOlId &&
                            !ol.myProgramVisible
                    )
                ) {
                    setSelectedOlId("");
                }

                handleDialogClose();
            })
            .catch(getDefaultErrorHandler("updateMyProgramVisibleOutletLists"));
    };

    /**
     * handle the OL select change
     * @param evt
     */
    const handleOlChange = (evt) => {
        setSelectedOlId(evt.target.value);

        if (orderBy) {
            resetSort();
        }
    };

    /**
     * outlet lists currently displayed in the table based on page & sort
     * @type {array}
     */
    const displayedOlDetails = useMemo(() => {
        if (!olDetails?.length) {
            return [];
        }

        return getDisplayedItems({
            items: olDetails,
            orderBy,
            order,
            page: currentPage,
        });
    }, [olDetails, currentPage, order, orderBy]);

    return (
        <MyProgramLayoutContainer title="Outlet Lists">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <Typography variant="h1" component="h1">
                        Outlet Lists
                    </Typography>
                    <ExportLink
                        url={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/outletlist/${selectedOlId}/export`}
                        disabled={!selectedOlId}
                    />
                </Box>
                <ManageButton
                    isInternalClientUser={isInternalClientUser}
                    handleClick={handleManageOpen}
                    text="Manage Outlet Lists"
                    disabled={!outletLists?.length}
                />
            </PRPageContentContainer>

            <PRPageContentContainer mb={2}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <PRSelect
                            label="Select Outlet List"
                            options={olSelectOptions}
                            formControlProps={{
                                disabled: !olSelectOptions.length,
                                sx: { width: "300px", mb: 1 },
                            }}
                            selectProps={{
                                name: "outletList",
                                onChange: handleOlChange,
                                value: selectedOlId,
                            }}
                        />
                    </Box>

                    <PRPagination
                        pageSize={MY_PROGRAM_PAGE_SIZE}
                        resultCount={olDetails?.length || 0}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                    />
                </Box>
            </PRPageContentContainer>

            <Table
                sx={{
                    minWidth: "100%",
                }}
                stickyHeader
            >
                <PRTableHead
                    columns={OL_HEAD_CELLS}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                />
                {!isLoading && (
                    <TableBody>
                        {!outletLists.length ? (
                            <NoResults message={NO_OL_MESSAGE} />
                        ) : !olDetails?.length ? (
                            <NoResults message={NO_OUTLETS_IN_OL_MESSAGE} />
                        ) : (
                            displayedOlDetails.map((outlet) => (
                                <TableRow key={outlet.outletId}>
                                    <TableCell>
                                        <Link
                                            target="_blank"
                                            href={getCL15CL25Url(
                                                outlet.outletId
                                            )}
                                        >
                                            {outlet.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{outlet.url}</TableCell>
                                    <TableCell align="center">
                                        <OutletPower
                                            power={outlet.outletPower}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <OutletContactInformation
                                                outlet={outlet}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                )}
            </Table>
            {manageOlOpen && (
                <ManageVisibleItems
                    open={true}
                    items={draftOlVisible}
                    handleDialogSubmit={handleDialogSubmit}
                    setItems={setDraftOlVisible}
                    handleClose={handleDialogClose}
                    idLabel="outletListId"
                    type="Outlet Lists"
                >
                    Choose which outlet lists to show on this page. These
                    settings will not affect which outlet lists appear elsewhere
                    within PublicRelay systems. Visit AN-24 for additional
                    outlet list settings.
                </ManageVisibleItems>
            )}
        </MyProgramLayoutContainer>
    );
};

export default CL01OutletLists;
