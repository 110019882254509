import { styled } from "@mui/system";
import { MenuItem } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

/**
 * define shared display values used by the top nav top level links or buttons
 * @param theme
 * @returns {{
 * border: string,
 * backgroundColor: (string|number|*),
 * alignItems: string,
 * color: string,
 * textAlign: string,
 * paddingRight: number,
 * display: string,
 * "&.selected": {
 * color: string,
 * borderBottom: string
 * },
 * paddingLeft: number,
 * justifyContent: string,
 * fontWeight: string,
 * textTransform: string}}
 */
const getSharedHeaderNavItemStyleObj = (theme) => ({
    backgroundColor: theme.palette.background.appBar,
    paddingLeft: 32,
    paddingRight: 32,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "1em",
    "&.active": {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    },
});

/**
 * define the display of top nav dropdown items
 * @type {Component}
 */
export const HeaderMenuItem = styled(MenuItem)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.appBar,
    padding: 12,
    fontSize: theme.typography.fontSize,
    "&:hover": {
        backgroundColor: theme.palette.background.appBar,
    },
}));

/**
 * define the display of top nav dropdown links
 * @type {Component}
 */
export const HeaderMenuItemLink = styled(Link)(({ theme }) => ({
    display: "block",
    width: "100%",
    color: theme.palette.text.primary,
    "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
    },
    "&.active": {
        color: theme.palette.primary.main,
    },
}));

/**
 * define display of the top nav top level links
 * @type {Component}
 */
export const HeaderMenuLink = styled(Link)(({ theme }) => {
    return {
        ...getSharedHeaderNavItemStyleObj(theme),
        "&:hover": {
            textDecoration: "none",
            color: theme.palette.primary.main,
        },
    };
});

/**
 * define display of the top nav top level buttons (used for dropdowns)
 * @type {Component}
 */
export const HeaderMenuButton = styled(Button)(({ theme }) => {
    return {
        ...getSharedHeaderNavItemStyleObj(theme),
        borderRadius: 0,
        cursor: "default",
        "&:hover": {
            backgroundColor: theme.palette.background.appBar,
        },
    };
});
