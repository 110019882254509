import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

/**
 * @typedef {{
 * id: string|number,
 * label: string,
 * sortable?: bool,
 * align?: string,
 * sx?: object,
 * defaultDesc?: bool,
 * }} TableHeadColumn
 */

/**
 * component to output a table head with sortable columns (if configured).
 * Similar to analyst; consider moving to component lib for reuse
 * @param {TableHeadColumn[]} columns
 * @param {string} order
 * @param {string} orderBy
 * @param {function} onRequestSort
 * @param {boolean} showEditIcon
 * @param {boolean} showDeleteIcon
 */
const PRTableHead = ({
    columns,
    order,
    orderBy,
    onRequestSort,
    showEditIcon = false,
    showDeleteIcon = false,
}) => {
    /**
     * handle initial column sort click
     * @param  {string} property - corresponds to column
     * @param {boolean} defaultDesc - true if the default sort for the column is "desc"
     */
    const createSortHandler = (property, defaultDesc) => (event) => {
        // material ui uses space key for accessibility. once the sort label is focused by a click,
        // if the next action is hitting space then the sort runs again. avoid by blurring the node
        // see https://github.com/mui-org/material-ui/issues/21379
        // (event preventDefault / stopPropagation did not work)
        event.target.blur();

        onRequestSort(event, property, defaultDesc);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ? headCell.align : "center"}
                        {...(headCell.sortable && {
                            sortDirection:
                                orderBy === headCell.id ? order : false,
                        })}
                        sx={headCell.sx && { ...headCell.sx }}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : "asc"
                                }
                                onClick={createSortHandler(
                                    headCell.id,
                                    headCell.defaultDesc
                                )}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        ) : (
                            <>{headCell.label}</>
                        )}
                    </TableCell>
                ))}
                {showEditIcon && (
                    <TableCell align="center">
                        <EditIcon fontSize="small" />
                    </TableCell>
                )}
                {showDeleteIcon && (
                    <TableCell align="center">
                        <DeleteOutlineIcon fontSize="small" />
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

PRTableHead.propTypes = {
    columns: PropTypes.array.isRequired,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func,
};

export default PRTableHead;
