import EmailIcon from "@mui/icons-material/Email";
import { StyledLink } from "../../styled/StyledLink";
import PropTypes from "prop-types";

/**
 * display given email with mailto
 * @param {string} email
 * @returns {JSX.Element}
 * @constructor
 */
const EmailIconLink = ({ email }) => (
    <StyledLink href={`mailto: ${email}`}>
        <EmailIcon />
    </StyledLink>
);

EmailIconLink.propTypes = {
    email: PropTypes.string.isRequired,
};

export default EmailIconLink;
