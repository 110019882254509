export const DOJO_LOGIN = "/com.publicrelay.www/jsp/client/index.jsp";

export const DOJO_CL25 = "/com.publicrelay.www/jsp/client/dashboards.jsp";

export const DOJO_CL07 =
    "/com.publicrelay.www/jsp/client/groupingContent.jsp#type=publication&section=true";

export const DOJO_CL06 =
    "/com.publicrelay.www/jsp/client/news-feed.jsp#type=widget&section=false";

export const DOJO_CL08 = "/com.publicrelay.www/jsp/client/news.jsp";

export const DOJO_CL12 = "/com.publicrelay.www/jsp/client/authors.jsp";

export const DOJO_CL14 = "/com.publicrelay.www/jsp/client/outlets.jsp";

export const DOJO_CL15_CL25 = "/com.publicrelay.www/jsp/client/engage.jsp";

export const DOJO_CL18 = "/com.publicrelay.www/jsp/client/submit-content.jsp";

export const DOJO_CL24 = "/com.publicrelay.www/jsp/client/twitter-handles.jsp";

export const DOJO_CL27_AUTHORS =
    "/com.publicrelay.www/jsp/client/discover-authors.jsp";

export const DOJO_CL26 = "/com.publicrelay.www/jsp/client/predictive.jsp";

export const DOJO_CL28 = "/com.publicrelay.www/jsp/client/twitter.jsp";

export const DOJO_CL02 =
    "/com.publicrelay.www/jsp/client/reports-and-charts.jsp";

export const TERMS = "/com.publicrelay.www/jsp/client/terms.jsp";

export const DOJO_CL17 = "/com.publicrelay.www/jsp/client/help.jsp";

export const DOJO_CL23 = "/com.publicrelay.www/jsp/client/command-center.jsp";

export const DOJO_CL22 =
    "/com.publicrelay.www/jsp/client/live-stream-search.jsp";

export const DOJO_CL16 = "/com.publicrelay.www/jsp/client/search.jsp";

export const DOJO_GOLINK = "/com.publicrelay.www/jsp/client/intermediate.jsp";

export const SHARETHIS_IFRAME =
    "/com.publicrelay.www/jsp/client/shareThisIFrame.jsp";
