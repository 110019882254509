import EditIcon from "@mui/icons-material/Edit";
import { ProgramButton } from "./styled";
import PropTypes from "prop-types";

/**
 * display the edit icon for My Program pages (internal user only)
 * @param isInternalClientUser
 * @param handleClick
 * @param text
 * @param rest - any add'l params provided to the Button component
 * @returns {JSX.Element|null}
 * @constructor
 */
const ManageButton = ({ isInternalClientUser, handleClick, text, ...rest }) =>
    isInternalClientUser ? (
        <ProgramButton onClick={handleClick} {...rest}>
            <EditIcon sx={{ mr: 1 }} /> {text}
        </ProgramButton>
    ) : null;

ManageButton.propTypes = {
    isInternalClientUser: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default ManageButton;
