import PropTypes from "prop-types";

/**
 * component to redirect user to the given new location instead of a client-side navigation
 * most useful since we have the dojo app living next to the react app on the same domain; we need
 * to redirect from the react app to the other app via window.location instead of a
 * react router navigation (e.g., via Navigate)
 * @param {string} url
 * @constructor
 */
const RedirectDocument = ({ url }) => {
    window.location.href = url;
};

RedirectDocument.propTypes = {
    url: PropTypes.string.isRequired,
};

export default RedirectDocument;
