import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

/**
 * various style overrides for the AdvancedTagDefinitionDialog component
 */

export const AdvancedTagsTableHead = styled(TableCell)(({ theme }) => ({
    border: "1px solid red",
    backgroundColor: theme.palette.background.default,
    textTransform: "none",
}));

export const AvailableTagGroupHeading = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.background.accordionSummary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
}));

export const AvailableTagGroupContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

export const SelectedOuterGroupContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    margin: 0,
    display: "flex",
    alignItems: "center",
    width: "100%",
    "&.selected": {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.focusedOuterGroup,
    },
}));

export const AvailableTagDefinitionChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.components.input,
    width: "164px",
    justifyContent: "space-between",
    margin: 0,
    "&:hover": {
        backgroundColor: theme.palette.components.input,
    },
    "& .MuiChip-deleteIcon": {
        color: theme.palette.text.primary,
    },
    "&.selected": {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export const SelectedOuterGroupTagChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.components.input,
    justifyContent: "start",
    margin: 0,
}));

export const AvailableTagGroupButton = styled(Button)(({ theme }) => ({
    flexGrow: 1,
    justifyContent: "start",
    textTransform: "none",
    fontWeight: "bold",
    "&:disabled": {
        backgroundColor: theme.palette.background.container,
    },
}));
