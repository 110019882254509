import FacebookIcon from "@mui/icons-material/Facebook";
import { StyledLink } from "../../styled/StyledLink";
import PropTypes from "prop-types";

/**
 * display the facebook icon with link
 * @param {string} facebook
 * @returns {JSX.Element}
 * @constructor
 */
const FacebookIconLink = ({ facebook }) => (
    <StyledLink href={facebook} target="_blank" rel="noreferrer">
        <FacebookIcon />
    </StyledLink>
);

FacebookIconLink.propTypes = { facebook: PropTypes.string.isRequired };

export default FacebookIconLink;
