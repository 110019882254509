import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { StyledLink } from "../../styled/StyledLink";
import useNotification from "../../../hooks/useNotification";
import PropTypes from "prop-types";
import { copyToClipboard } from "./util";

/**
 * display phone icon with copy number to clipboard functionality
 * @param {string} phoneNumber
 * @returns {JSX.Element}
 * @constructor
 */
const LocalPhoneIconLink = ({ phoneNumber }) => {
    const { notify } = useNotification();

    return (
        <StyledLink
            component="button"
            onClick={copyToClipboard(phoneNumber, notify)}
        >
            <LocalPhoneIcon />
        </StyledLink>
    );
};

LocalPhoneIconLink.propTypes = { phoneNumber: PropTypes.string.isRequired };

export default LocalPhoneIconLink;
