import { Dialog } from "@publicrelay/component-library";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { LegendFormLabel } from "./styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/**
 * shows dialog to display the given items as checkboxes, and allows user to toggle them
 * @param items
 * @param open
 * @param handleDialogSubmit
 * @param setItems
 * @param handleClose
 * @param type
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ManageVisibleItems = ({
    items,
    open,
    handleDialogSubmit,
    setItems,
    handleClose,
    type,
    idLabel,
    children,
}) => {
    /**
     * handle toggling the checked value in state
     * @param {number} id
     * @returns {(function({target: object}): void)|*}
     */
    const handleChange =
        (id) =>
        ({ target }) => {
            setItems(
                items.map((item) =>
                    item[idLabel] === id
                        ? { ...item, myProgramVisible: target.checked }
                        : item
                )
            );
        };

    /**
     * split display into 2 columns once there are 10 items
     * @type {boolean}
     */
    const show2Columns = items.length > 10;

    /**
     * user must select at least one newsletter visible
     * @type {boolean}
     */
    const disableSave = items.every((item) => !item.myProgramVisible);

    return (
        <Dialog
            title={`Manage ${type}`}
            open={open}
            maxWidth="sm"
            onSubmit={handleDialogSubmit}
            onCancel={handleClose}
            disableSave={disableSave}
        >
            <Typography mb={2}>{children}</Typography>
            <FormControl
                component="fieldset"
                variant="standard"
                sx={{ width: "100%" }}
            >
                <LegendFormLabel component="legend">
                    Visible {type}:
                </LegendFormLabel>
                <Box overflow="auto" maxHeight={350}>
                    <FormGroup
                        {...(show2Columns && {
                            sx: {
                                display: "grid",
                                gridTemplateRows: `repeat(${Math.ceil(
                                    items.length / 2
                                )}, auto)`,
                                gridGap: "5px",
                                gridAutoFlow: "column",
                            },
                        })}
                    >
                        {items.length > 0 &&
                            items.map((item, index) => (
                                <FormControlLabel
                                    key={item[idLabel]}
                                    control={
                                        <Checkbox
                                            checked={item.myProgramVisible}
                                            onChange={handleChange(
                                                item[idLabel]
                                            )}
                                        />
                                    }
                                    label={item.name}
                                />
                            ))}
                    </FormGroup>
                </Box>
            </FormControl>
        </Dialog>
    );
};

export default ManageVisibleItems;
