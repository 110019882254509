import Link from "@mui/material/Link";
import { styled } from "@mui/system";

/**
 * Line clamp our link to 2 lines, used throughout the client side when displaying titles in tables
 * Consider moving to component if we need to override line clamp value, etc
 */
export const LineClampLink = styled(Link)(({ theme }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    maxHeight: "3em",
    wordBreak: " break-word",
}));

/**
 * This is a styled link that we are using for now to match other places where this is
 * being used on the existing client portal. Standard white, hover to blue
 */
export const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    "&:hover": {
        color: theme.palette.primary.main,
    },
}));
