import {
    getClientContactDatabaseEnabledCookieValue,
    getClientPredictiveEnabledCookieValue,
    getClientRawFeedEnabledCookieValue,
    getClientTwitterFeedCookieValue,
    getClientTypeFromCookie,
    getClientVisiblePublicationsCookieValue,
    getClientVisibleWidgetsCookieValue,
    getCommandCenterEnabledCookieValue,
    getLiveStreamAlertsCookieValue,
    getLiveStreamSearchCookieValue,
    getUploadAllowedCookieValue,
    getNeliEnabledCookieValue,
} from "./cookies";
import { getCL01Url, getCL25Url } from "./urls";

export const CLIENT_TYPE_DASHBOARD = "dashboard_client";

export const CLIENT_TYPE_EX = "excalibur_client";

/**
 * logic ported from methods.js
 * what is the URL for the client's landing page?
 * @returns {string}
 */
export const getClientLandingPageUrl = () => {
    const landingPageConfig = {
        CL25: getCL25Url(),
        CL01: getCL01Url(),
    };

    if (isClientTypeDashboard()) {
        // Dashboard type clients are always taken to their dashboard
        return landingPageConfig.CL25;
    } else {
        // all other users are directed to My Program
        return landingPageConfig.CL01;
    }
};

/**
 * logic ported from methods.js
 * Is the currently logged in user of client type dashboard
 * @returns {boolean} - is the client type "dashboard-client"
 */
export const isClientTypeDashboard = () => {
    const clientType = getClientTypeFromCookie();

    return clientType === CLIENT_TYPE_DASHBOARD;
};

/**
 * ported from methods.js
 * Is the client of type ex (based on the cookie value for "clientType")
 * @returns {boolean}
 */
export const isClientTypeEx = () => {
    const clientType = getClientTypeFromCookie();

    return clientType === CLIENT_TYPE_EX;
};

/**
 * is the user allowed to upload content via CL18?
 * @returns {boolean}
 */
export const isUploadAllowed = () => !!getUploadAllowedCookieValue();

/**
 * does the client have raw feed aka live stream enabled?
 * @returns {boolean}
 */
export const isClientRawFeedEnabled = () =>
    !!getClientRawFeedEnabledCookieValue();

/**
 * does the client have predictive enabled?
 * @returns {boolean}
 */
export const isClientPredictiveEnabled = () =>
    !!getClientPredictiveEnabledCookieValue();

/**
 * does the client have contact database (discover) enabled?
 * @returns {boolean}
 */
export const isClientContactDatabaseEnabled = () =>
    !!getClientContactDatabaseEnabledCookieValue();

/**
 * does the client have command center enabled (CL23)
 * @returns {boolean}
 */
export const isCommandCenterEnabled = () =>
    !!getCommandCenterEnabledCookieValue();

/**
 * does the client have access to twitter content?
 * @returns {boolean}
 */
export const isClientTwitterFeedEnabled = () =>
    !!getClientTwitterFeedCookieValue();

/**
 * does the have any publications visible on the client side?
 * @returns {boolean}
 */
export const hasVisiblePublications = () =>
    !!getClientVisiblePublicationsCookieValue();

/**
 * does the client have any news feeds (widgets) visible on the client side?
 * @returns {boolean}
 */
export const hasVisibleNewsfeeds = () => !!getClientVisibleWidgetsCookieValue();

/**
 * does the client have live stream search access?
 * @returns {boolean}
 */
export const isLiveStreamSearchEnabled = () =>
    !!getLiveStreamSearchCookieValue();

/**
 * does the client have live stream alerts enabled?
 * @returns {boolean}
 */
export const isLiveStreamAlertsEnabled = () =>
    !!getLiveStreamAlertsCookieValue();

/**
 * does the client have neli enabled?
 * @returns {boolean}
 */
export const isClientNeliEnabled = () => !!getNeliEnabledCookieValue();
