import { useContext } from "react";
import { AuthContext } from "./AuthContext";

/**
 * hook to get user info, including clientId. See AuthProvider for further details about the data available, or to add
 * data to the context
 * @returns {object} - current context value for AuthContext
 */
const useAuth = () => {
    return useContext(AuthContext);
};

export default useAuth;
