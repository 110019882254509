/**
 * Method to copy a phone number to clipboard
 * @param {string} phone
 * @returns
 */
export const copyToClipboard = (phone, showNotification) => () => {
    navigator.clipboard
        .writeText(phone)
        .then(() => {
            showNotification("Successfully copied to clipboard!", "success");
        })
        .catch(() => {
            showNotification(
                "Error trying to copy phone number to clipboard",
                "error"
            );
        });
};
