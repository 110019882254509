import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";
import nextId from "react-id-generator";

import { isEmail, isUrl } from "../../util/validation";
import {
    INVALID_EMAIL,
    INVALID_URL,
    REQUIRED_FIELD,
    INVALID_RANGE,
} from "../../util/strings";

/**
 * Convenience wrappers to create Material form inputs using standard publicrelay display
 * configuration
 */

/**
 * standard publicrelay text input implemenation
 * @param  {} props
 *
 * @param {Object} [props.formControlConfig]
 *        - props passed to FormControl. Can override defaults set here and add any props as needed.
 *        - eg. "fullWidth"
 *
 * @param {Object} [props.inputConfig]
 *        - props passed to OutlinedInput. Can override defaults set here and add any props as needed.
 *        - eg. "name", "value", "onChange"
 *
 * @param {Ojbect} [props.labelConfig]
 *        - props passed to InputLabel. Can override defaults set here and add any props as needed
 *
 * @param {Boolean} [required] - if true shows * on form label. defaults to false
 *
 * @param {String} [id] - id for the element
 *
 * @param {String} [type] - input type. defaut is "text"
 *
 * @param {String} [label] - label for the input, if any
 *
 * @param {React element} [startAdornmentNode] - added to the beginning of the element. eg. an Icon
 *
 * @param {String} [helperText] - used to give context about a field’s input (output below input)
 *
 * @param {Boolean} [shouldValidateOnBlur] - if true will validate a field's value based on input type
 *                                         - default false
 *
 * @param {Boolean} [shouldValidateOnChange]  - if true will validate a field's value based on input type
 *                                           - default false
 *
 * @param {Boolean} [forceValidation] - run vaidation/show errors now
 *
 * @param {Function} [register] - if passed, this is called on value change and passes field's validity
 *
 * @return {React Componenet}
 */
export const PRTextField = (props) => {
    const {
        formControlConfig = {},
        id = nextId("textfield_search_"), // Adding the word "search" to IDs to prevent lastpass autofill
        inputConfig = {},
        labelConfig = {},
        required = false,
        type = "text",
        label,
        startAdornmentNode,
        endAdornmentNode,
        helperText,
        helperProps = {},
        shouldValidateOnBlur = false,
        shouldValidateOnChange = false,
        forceValidation,
        register,
        selectOnFocus = false,
        customValidationMethod,
    } = props;

    /**
     * handle internal error state
     */
    const [isInternalError, setIsInternalError] = useState();

    /**
     * handle missing/invalid messaging
     */
    const [invalidMessage, setInvalidMessage] = useState();

    /**
     * when props.shouldValidateOnBlur is true, this method overrides any onBlur passed via
     * props.inputConfig. handles error display (missing/invalid), then calls props.inputConfig.onBlur
     * with the evt and isInvalid
     * @param  {Object} evt
     */
    const validateOnBlur = (evt) => {
        validate(evt.target.value, true);

        if (inputConfig.onBlur) {
            //pass through the onBlur event
            inputConfig.onBlur(evt);
        }
    };

    /**
     * check for various states, update error display/messaging
     * useCallback hook b/c we are using validate as a dependacy in useEffect hook
     * @param  {String} value - input value
     * @param {Boolean} showMessage - when true we'll display error; otherwise just return isInvalid
     * @return {Boolean} - true if errored, otherwise false
     */
    const validate = useCallback(
        (value, showMessage) => {
            /**
             * if we have min / max values for our input we'll validate our value against them
             * @param {string} value - our input value
             * @returns {boolean}
             */
            const isInRange = (value) => {
                if (!inputConfig.inputProps) {
                    return true;
                }

                const { min, max } = inputConfig.inputProps;

                if (min !== undefined && max !== undefined) {
                    if (value < parseInt(min) || value > parseInt(max)) {
                        return false;
                    }
                }

                return true;
            };

            let parsed;

            if (value !== undefined && value !== null) {
                // trim before validating if a string
                parsed = value.trim ? value.trim() : value;
            } else {
                parsed = "";
            }

            let isInvalid = false;

            let message;

            if (required && !parsed) {
                isInvalid = true;

                message = REQUIRED_FIELD;
            }

            if (value && type === "email" && !isEmail(parsed)) {
                isInvalid = true;

                message = INVALID_EMAIL;
            }

            if (value && type === "url" && !isUrl(parsed)) {
                isInvalid = true;

                message = INVALID_URL;
            }

            if (value && type === "number" && !isInRange(parsed)) {
                isInvalid = true;

                message = INVALID_RANGE;
            }

            if (customValidationMethod && !isInvalid) {
                let { customIsInvalid, customErrorText } =
                    customValidationMethod(parsed);

                isInvalid = customIsInvalid;

                message = customErrorText;
            }

            if (showMessage) {
                setInvalidMessage(message);

                setIsInternalError(isInvalid);
            }

            return isInvalid;
        },
        [required, type, customValidationMethod, inputConfig.inputProps]
    );

    /**
     * hook dependant on props.inputConfig.value, so runs everytime a new value for
     * props.inputConfig.value is passed to this component (ie, even on intial values)
     * but therefore, in effect (haha), this also corresponds to the onChange event
     * if props.shouldValidateOnChange is true, validate the field and show message if necessary
     */
    useEffect(() => {
        if (shouldValidateOnChange) {
            validate(inputConfig.value, true);
        }
    }, [inputConfig.value, validate, shouldValidateOnChange]);

    /**
     * hook dependant on props.inputConfig.value, so runs everytime a new value for
     * props.inputConfig.value is passed to this component (ie, even on intial values)
     * but therefore, in effect (haha), this also corresponds to the onChange event
     * if props.register is passed, we'll call the method to update current validity
     * you must pass a name to the input for this method to work.
     */
    useEffect(() => {
        if (register) {
            if (!inputConfig.name) {
                console.warn(
                    "PRTextField: You did not pass a name attribute but are trying to register a field."
                );
                return;
            }

            const isInvalid = validate(inputConfig.value);

            //pass through the current validity
            register({ [inputConfig.name]: isInvalid });
        }
    }, [inputConfig.value, inputConfig.name, validate, register]);

    /**
     * hook dependant on props.inputConfig.value, so runs everytime a new value for
     * props.inputConfig.value is passed to this component (ie, even on intial values)
     * but therefore, in effect (haha), this also corresponds to the onChange event
     * if props.forceValidation is true, we'll call the method to validate the input
     */
    useEffect(() => {
        if (forceValidation) {
            validate(inputConfig.value, true);
        }
    }, [inputConfig.value, forceValidation, validate]);

    useEffect(() => {
        if (formControlConfig.disabled) {
            setInvalidMessage(null);
            setIsInternalError(false);
        }
    }, [formControlConfig.disabled]);

    /**
     * select the value in the input
     * @param  {Object} evt - focus event
     */
    const handleSelectOnFocus = (evt) => {
        evt.target.select();
    };

    /**
     * if we want to "select on focus" we need to pass it as inputProps
     * without losing any passed in inputConfig
     * @return {Object}
     */
    const getInputPropsWithSelectOnFocus = () => {
        let inputProps = { onFocus: handleSelectOnFocus };

        if (inputConfig.inputProps) {
            inputProps = {
                ...inputProps,
                ...inputConfig.inputProps,
            };
        }

        return inputProps;
    };

    return (
        <FormControl
            variant="outlined"
            {...formControlConfig}
            {...(isInternalError && { error: isInternalError })}
        >
            {label && (
                <InputLabel htmlFor={id} required={required} {...labelConfig}>
                    {label}
                </InputLabel>
            )}
            <OutlinedInput
                autoComplete="chrome-off"
                type={type}
                id={id}
                startAdornment={
                    startAdornmentNode && (
                        <InputAdornment position="start">
                            {startAdornmentNode}
                        </InputAdornment>
                    )
                }
                endAdornment={
                    endAdornmentNode && (
                        <InputAdornment position="end">
                            {endAdornmentNode}
                        </InputAdornment>
                    )
                }
                label={label}
                {...inputConfig}
                {...(shouldValidateOnBlur && { onBlur: validateOnBlur })}
                {...(selectOnFocus && { ...getInputPropsWithSelectOnFocus() })}
            />
            {helperText && (
                <FormHelperText {...helperProps}>{helperText}</FormHelperText>
            )}
            {invalidMessage && (
                <FormHelperText>{invalidMessage}</FormHelperText>
            )}
        </FormControl>
    );
};

PRTextField.propTypes = {
    formControlConfig: PropTypes.object,
    id: PropTypes.string,
    inputConfig: PropTypes.object,
    labelConfig: PropTypes.object,
    required: PropTypes.bool,
    type: PropTypes.oneOf([
        "text",
        "search",
        "number",
        "email",
        "tel",
        "url",
        "password",
    ]),
    label: PropTypes.string,
    startAdornmentNode: PropTypes.element,
    helperText: PropTypes.string,
    helperProps: PropTypes.object,
    shouldValidateOnBlur: PropTypes.bool,
    shouldValidateOnChange: PropTypes.bool,
    forceValidation: PropTypes.bool,
    register: PropTypes.func,
};

/**
 * standard PublicRelay Search input implementation;
 * a wrapper that sets props on PRTextField to create a search input
 * @param  {} props - see PRTextField for details
 * @return {React Componenet} - a PRTextField with search input attributes
 */
export const PRSearchField = (props) => {
    return (
        <PRTextField
            type="search"
            startAdornmentNode={<SearchIcon />}
            {...props}
        />
    );
};

PRSearchField.propTypes = {
    formControlConfig: PropTypes.object,
    id: PropTypes.string,
    inputConfig: PropTypes.object,
    labelConfig: PropTypes.object,
    required: PropTypes.bool,
    label: PropTypes.string,
};
