import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import {useEffect, useState} from "react";
import Footer from "../components/navigation/Footer";
import Header from "../components/navigation/Header";
import Alert from "@mui/material/Alert";
import { browserName, browserVersion } from "react-device-detect";
import {
    getClientId,
    getUnsupportedBrowser,
    setUnsupportedBrowserCookie,
} from "../util/cookies";
import FABEnvironmentIndicator from "../components/FABEnvironmentIndicator";
import {getDefaultErrorHandler} from "../util/services";
import {recordPageVisit} from "../stagedServices";
import {isInternalClientUser} from "../util/users";

export const ChromeVersion = 55;

export const FirefoxVersion = 52;

export const unsupportedBrowserCookieValue = getUnsupportedBrowser();

const HeaderFooterLayout = () => {
    const [pageIdentifier, setPageIdentifier] = useState("");

    const calculateBrowser = () => {
        if (
            browserName === "Chrome" &&
            !unsupportedBrowserCookieValue &&
            browserVersion > ChromeVersion
        ) {
            return unsupportedBrowserCookieValue;
        }

        if (
            browserName === "Firefox" &&
            !unsupportedBrowserCookieValue &&
            browserVersion > FirefoxVersion
        ) {
            return unsupportedBrowserCookieValue;
        }

        setUnsupportedBrowserCookie(true);
        return !unsupportedBrowserCookieValue;
    };

    const [showAlert, setShowAlert] = useState(calculateBrowser());

    useEffect(() => {
        if (pageIdentifier) {
            //US6150 Add Tracking to Determine Page Views by User and Client
            const internalClientUser = isInternalClientUser();
            if (!internalClientUser) {
                console.debug('Recording page Visit to ', pageIdentifier)
                recordPageVisit(getClientId(), pageIdentifier)
                    .then(response => {
                        console.debug('Page visit record response: ', response)
                    }).catch(getDefaultErrorHandler('recordPageVisit'))
            } else {
                console.debug("Is not needed to record page visit for internal users")
            }
        }
    }, [pageIdentifier])

    return (
        <Box
            sx={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
                width: "100%",
                position: "relative",
            }}
        >
            <Header />
            <Paper
                sx={{
                    paddingTop: 0,
                    flex: "1",
                    overflow: "auto",
                    maxHeight: (theme) =>
                        `calc( 100vh - (${theme.header.maxHeight}px + ${theme.footer.height}px + 0.5px))`,
                }}
            >
                <Container fixed={true} maxWidth={false}>
                    {showAlert && (
                        <Alert
                            onClose={() => {
                                setShowAlert(false);
                            }}
                            variant="outlined"
                            severity="warning"
                            sx={{ color: "white", mt: 2 }}
                        >
                            This browser is not supported by PublicRelay so some
                            features may not function as expected. Please switch
                            to the latest version of Mozilla Firefox, Google
                            Chrome, or Microsoft Edge for the best user
                            experience.
                        </Alert>
                    )}
                    {/* An <Outlet> renders whatever child route is currently active,
                    so you can think about this <Outlet> as a placeholder for
                    the child routes we defined. */}
                    <Outlet
                        context={{
                            pageIdentifier: pageIdentifier,
                            setPageIdentifier: setPageIdentifier,
                        }}
                    />
                </Container>
            </Paper>
            <FABEnvironmentIndicator />
            <Footer pageTitle={pageIdentifier} />
        </Box>
    );
};

export default HeaderFooterLayout;
