import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";

/**
 * Pagination component with default layout for rendering in a page title area
 * @param {number} pageSize
 * @param {number} resultCount
 * @param {function(number)} handlePagination
 * @param {number} propCurrentPage
 * @param {string} [label]
 * @param rest - any additional props supplied to the Grid container
 * @returns {JSX.Element}
 * @constructor
 */
const PRPagination = ({
    pageSize,
    resultCount,
    handlePagination,
    currentPage: propCurrentPage,
    label,
    ...rest
}) => {
    // will be considered controlled when we have a current page prop
    const isControlled = propCurrentPage !== undefined;

    // if not controlled, we'll handle the current page in state
    // could add a default current page value later, to land directly on something other than 1
    const [internalCurrentPage, setInternalCurrentPage] = useState(1);

    // we need to output something for current page. depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state
    const currentPage = isControlled ? propCurrentPage : internalCurrentPage;

    const totalPages = useMemo(() => {
        return Math.ceil(resultCount / pageSize) || 1;
    }, [resultCount, pageSize]);

    /**
     * move prev / next page
     */
    const paginate = (count) => () => {
        const next = currentPage + count;

        if (!isControlled) {
            setInternalCurrentPage(next);
        }

        if (handlePagination) {
            handlePagination(next);
        }
    };

    return (
        <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={3}
            {...rest}
        >
            <Grid item sx={{ marginRight: 3 }}>
                <Typography variant="span">
                    {`${label} ${currentPage}/${totalPages}`}
                </Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={paginate(-1)} disabled={currentPage === 1}>
                    <ArrowBackIosIcon
                        color={currentPage === 1 ? "secondary" : "primary"}
                    />
                </IconButton>

                <IconButton
                    onClick={paginate(1)}
                    disabled={currentPage === totalPages}
                >
                    <ArrowForwardIosIcon
                        color={
                            currentPage === totalPages ? "secondary" : "primary"
                        }
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

PRPagination.defaultProps = {
    pageSize: PropTypes.number,
    resultCount: PropTypes.number,
    handlePagination: PropTypes.func,
    currentPage: PropTypes.number,
    label: PropTypes.string,
};

PRPagination.defaultProps = {
    pageSize: 20,
    resultCount: 0,
    label: "Page",
};

export default PRPagination;
