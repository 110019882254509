import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import { PRPageContentContainer } from "../../components/styled/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ManageButton from "../../components/myProgram/ManageButton";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../auth/useAuth";
import ExportLink from "../../components/myProgram/ExportLink";
import { getTagGroups } from "../../components/filters/tags/stagedServices";
import { getDefaultErrorHandler } from "../../util/services";
import { Virtuoso } from "react-virtuoso";
import {
    AvailableTagDefinitionChip,
    AvailableTagGroupContainer,
    AvailableTagGroupHeading,
} from "../../components/filters/tags/styled";
import { NO_TAGS_MESSAGE } from "../../components/myProgram/constants";
import BackdropLoader from "../../components/shared/BackdropLoader";
import ManageVisibleItems from "../../components/myProgram/ManageVisibleItems";
import { updateMyProgramVisibleTagGroups } from "@publicrelay/service/dist/client/myProgram";
import {
    filterEmptyTagGroups,
    sortTagDefs,
} from "../../components/myProgram/util";
// import Tags from "../../components/myProgram/Tags";

/**
 * display the "My Program" visible tags
 * allow internal users to update which tag groups are visible
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Tags = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { isInternalClientUser, clientId } = useAuth();

    const [tagList, setTagList] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [manageDialogOpen, setManageDialogOpen] = useState(false);

    const [draftTagGroups, setDraftTagGroups] = useState([]);

    /**
     * call service to get the tag groups for the client
     */
    useEffect(() => {
        getTagGroups()
            .then((response) => {
                setTagList(filterEmptyTagGroups(response.tagGroups));
            })
            .catch(getDefaultErrorHandler("getTagGroups"));
    }, []);

    /**
     * open & set data for manage dialog
     */
    const handleManageOpen = () => {
        setManageDialogOpen(true);

        setDraftTagGroups(
            tagList.map((tagGroup) => ({
                id: tagGroup.id,
                myProgramVisible: tagGroup.myProgramVisible,
                name: tagGroup.name,
            }))
        );
    };

    /**
     * close / reset the manage dialog
     */
    const handleDialogClose = () => {
        setManageDialogOpen(false);

        setDraftTagGroups();
    };

    /**
     * passed to the manage dialog to update the My Program visible tag groups
     */
    const handleDialogSubmit = () => {
        setIsLoading(true);

        setTagList();

        handleDialogClose();

        updateMyProgramVisibleTagGroups(clientId, draftTagGroups)
            .then((response) => {
                setTagList(filterEmptyTagGroups(response.tagGroups));
            })
            .catch(getDefaultErrorHandler("updateMyProgramVisibleTagGroups"));
    };

    /**
     * turn loading indicator off
     */
    useEffect(() => {
        if (tagList && isLoading) {
            setIsLoading(false);
        }
    }, [tagList, isLoading]);

    /**
     * only display tag groups that are My Program visible
     */
    const displayedTagGroups = useMemo(
        () => tagList?.filter((tg) => tg.myProgramVisible),
        [tagList]
    );

    return (
        <MyProgramLayoutContainer title="Tag Structure">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <Typography variant="h1" component="h1">
                        Tag Structure
                    </Typography>
                    <ExportLink
                        url={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/tag-structure/export`}
                        disabled={!tagList?.length}
                    />
                </Box>
                <ManageButton
                    isInternalClientUser={isInternalClientUser}
                    handleClick={handleManageOpen}
                    text="Manage Tag Groups"
                    disabled={!tagList?.length}
                />
            </PRPageContentContainer>

            {isLoading ? (
                <BackdropLoader open={true} />
            ) : !displayedTagGroups?.length ? (
                <PRPageContentContainer>
                    {NO_TAGS_MESSAGE}
                </PRPageContentContainer>
            ) : (
                // <Tags tagGroups={displayedTagGroups} />
                <Virtuoso
                    style={{
                        height: `calc(100vh - 240px)`,
                    }}
                    totalCount={displayedTagGroups.length}
                    increaseViewportBy={600}
                    itemContent={(index) => {
                        const tagGroup = displayedTagGroups[index];

                        const tagDefinitions = sortTagDefs(
                            tagGroup.tagDefinitions
                        );

                        return (
                            <Box paddingBottom={2}>
                                <AvailableTagGroupHeading
                                    component="div"
                                    px={2}
                                    py={1}
                                >
                                    {tagGroup.name}
                                </AvailableTagGroupHeading>
                                <AvailableTagGroupContainer
                                    elevation={2}
                                    sx={{ p: 1 }}
                                >
                                    {tagDefinitions.map((tagDef) => (
                                        <Box
                                            display="inline-block"
                                            component="span"
                                            p={1}
                                            key={tagDef.id}
                                        >
                                            <AvailableTagDefinitionChip
                                                label={
                                                    tagDef.alias || tagDef.name
                                                }
                                            />
                                        </Box>
                                    ))}
                                </AvailableTagGroupContainer>
                            </Box>
                        );
                    }}
                />
            )}

            {manageDialogOpen && (
                <ManageVisibleItems
                    open={true}
                    items={draftTagGroups}
                    handleDialogSubmit={handleDialogSubmit}
                    setItems={setDraftTagGroups}
                    handleClose={handleDialogClose}
                    idLabel="id"
                    type="Tag Groups"
                >
                    Choose which tag groups to show on this page. These settings
                    will not affect which tag groups appear elsewhere within
                    PublicRelay systems. Visit AN-04a for additional tag group
                    settings.
                </ManageVisibleItems>
            )}
        </MyProgramLayoutContainer>
    );
};

export default CL01Tags;
