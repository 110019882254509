import PropTypes from "prop-types";

const OUTLET_POWER_PATH = "/static/images/power/";

/**
 * Component to display corresponding image for given outlet power
 * TODO: move to component library? what if client uses different images?
 * @param {number} power - returns level 1 as default
 * @param rest - Any other props supplied will be provided to the img element
 * @returns {JSX.Element}
 * @constructor
 */
const OutletPower = ({ power, ...rest }) => {
    const { PUBLIC_URL } = process.env;

    let src;

    switch (power) {
        case 2:
            src = `${PUBLIC_URL}${OUTLET_POWER_PATH}outlet-power-2.png`;
            break;
        case 3:
            src = `${PUBLIC_URL}${OUTLET_POWER_PATH}outlet-power-3.png`;
            break;
        case 4:
            src = `${PUBLIC_URL}${OUTLET_POWER_PATH}outlet-power-4.png`;
            break;
        default:
            src = `${PUBLIC_URL}${OUTLET_POWER_PATH}outlet-power-1.png`;
    }

    return <img src={src} alt={`outlet power ${power}`} {...rest} />;
};

OutletPower.propTypes = {
    power: PropTypes.oneOf([1, 2, 3, 4]),
};

export default OutletPower;
