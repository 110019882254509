import { lazy, Suspense } from "react";
import HeaderFooterLayout from "../layouts/HeaderFooterLayout";
import RequireAuth from "../auth/RequireAuth";
import DefaultErrorSnack from "../components/snacks/DefaultErrorSnack";
import { Navigate } from "react-router-dom";
import {
    CL00_PATH,
    CL01_ALERTS_PATH,
    CL01_FEEDBACK_PATH,
    CL01_NEWSLETTERS_PATH,
    CL01_OUTLET_LISTS_PATH,
    CL01_OVERVIEW_PATH,
    CL01_REPORTS_PATH,
    CL01_SETTINGS_PATH,
    CL01_TAGS_PATH,
    CL01_USERS_PATH,
    CL10_PATH,
    CL11_PATH,
    CL27_PATH,
    CL30_PATH,
    CL31_PATH,
    getCL01Url,
    NOT_FOUND_PATH,
} from "../util/urls";
import Loading from "../pages/Loading";
import RedirectDocument from "../components/navigation/RedirectDocument";
import CL01Settings from "../pages/CL01/CL01Settings";
import CL01Reports from "../pages/CL01/CL01Reports";
import CL01Feedback from "../pages/CL01/CL01Feedback";
import CL01Alerts from "../pages/CL01/CL01Alerts";
import CL01Tags from "../pages/CL01/CL01Tags";
import CL01OutletLists from "../pages/CL01/CL01OutletLists";
import CL01Users from "../pages/CL01/CL01Users";
import CL01Newsletters from "../pages/CL01/CL01Newsletters";

/**
 * see https://reactrouter.com/docs/en/v6/examples/lazy-loading
 */

const CL27 = lazy(() => import("../pages/CL27"));
const CL10 = lazy(() => import("../pages/CL10"));
const CL11 = lazy(() => import("../pages/CL11"));
const CL30 = lazy(() => import("../pages/CL30"));
const CL31 = lazy(() => import("../pages/CL31"));
const CL00 = lazy(() => import("../pages/CL00"));
const CL01Overview = lazy(() => import("../pages/CL01/CL01Overview"));
const NotFound = lazy(() => import("../pages/PageNotFound"));

// https://reactrouter.com/docs/en/v6/getting-started/concepts#matching
const routes = [
    {
        path: `/client/${CL00_PATH}`,
        element: (
            <Suspense fallback={<Loading />}>
                <CL00 />
                <DefaultErrorSnack />
            </Suspense>
        ),
    },
    {
        path: "/client",
        element: (
            <RequireAuth>
                <HeaderFooterLayout />
                <DefaultErrorSnack />
            </RequireAuth>
        ),
        children: [
            {
                index: true,
                element: <RedirectDocument url={getCL01Url()} />,
            },

            {
                path: "cl-27",
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL27 pageIdentifier="CL-27" />
                    </Suspense>
                ),
            },
            {
                path: CL27_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL27 pageIdentifier="CL-27" />
                    </Suspense>
                ),
            },
            {
                path: CL10_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL10 pageIdentifier="CL-10" />
                    </Suspense>
                ),
            },
            {
                path: `${CL11_PATH}/:articleId`,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL11 pageIdentifier="CL-11" />
                    </Suspense>
                ),
            },
            {
                path: CL30_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL30 pageIdentifier="CL-30" />
                    </Suspense>
                ),
            },
            {
                path: `${CL31_PATH}/:tagId`,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL31 pageIdentifier="CL-31" />
                    </Suspense>
                ),
            },
            {
                path: "home",
                element: <Navigate to="overview" replace />,
            },
            {
                path: CL01_OVERVIEW_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Overview pageIdentifier="CL-01 Overview" />
                    </Suspense>
                ),
            },
            {
                path: CL01_SETTINGS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Settings pageIdentifier="CL-01 Settings" />
                    </Suspense>
                ),
            },
            {
                path: CL01_REPORTS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Reports pageIdentifier="CL-01 Reports" />
                    </Suspense>
                ),
            },
            {
                path: CL01_FEEDBACK_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Feedback pageIdentifier="CL-01 Feedback" />
                    </Suspense>
                ),
            },
            {
                path: CL01_ALERTS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Alerts pageIdentifier="CL-01 Alerts & Recipients" />
                    </Suspense>
                ),
            },
            {
                path: CL01_TAGS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Tags pageIdentifier="CL-01 Tag Structure" />
                    </Suspense>
                ),
            },
            {
                path: CL01_OUTLET_LISTS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01OutletLists pageIdentifier="CL-01 Outlet Lists" />
                    </Suspense>
                ),
            },
            {
                path: CL01_USERS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Users pageIdentifier="CL-01 Users" />
                    </Suspense>
                ),
            },
            {
                path: CL01_NEWSLETTERS_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <CL01Newsletters pageIdentifier="CL-01 Newsletters & Recipients" />
                    </Suspense>
                ),
            },
            {
                path: NOT_FOUND_PATH,
                element: (
                    <Suspense fallback={<Loading />}>
                        <NotFound />
                    </Suspense>
                ),
            },
            /**
             * Using path="*" means "match anything", so this route
             * acts like a catch-all for URLs that we don't have explicit
             * routes for. uses Navigate to take user to the not found page
             * so we don't have to worry about the top nav including a path
             * and showing something as selected (see useSelectedNav)
             */
            {
                path: "*",
                element: (
                    <Suspense fallback={<Loading />}>
                        <Navigate to={NOT_FOUND_PATH} />
                    </Suspense>
                ),
            },
        ],
    },
];

export default routes;
