import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import ManageButton from "../../components/myProgram/ManageButton";
import Box from "@mui/material/Box";
import PRPagination from "../../components/shared/PRPagination";
import {
    MY_PROGRAM_PAGE_SIZE,
    NO_USERS_MESSAGE,
    USERS_HEAD_CELLS,
} from "../../components/myProgram/constants";
import Table from "@mui/material/Table";
import PRTableHead from "../../components/shared/tables/PRTableHead";
import TableBody from "@mui/material/TableBody";
import NoResults from "../../components/shared/NoResults";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import useAuth from "../../auth/useAuth";
import { useTableSort } from "../../hooks/useTableSort";
import useManagePagination from "../../hooks/useManagePagination";
import { useEffect, useMemo, useState } from "react";
import CheckIconTableCell from "../../components/myProgram/CheckIconTableCell";
import {
    getMyProgramUsers,
    updatePaidSeats,
} from "@publicrelay/service/dist/client/myProgram";
import { getDefaultErrorHandler } from "../../util/services";
import {
    Dialog,
    getFormattedDate,
    getPRTime,
} from "@publicrelay/component-library";
import { getDisplayedItems } from "../../components/myProgram/util";
import { PRTextField } from "../../components/shared/PRInputs";
import ExportLink from "../../components/myProgram/ExportLink";

/**
 * display the Users page in My Program
 * allows internal users to edit the paid seat count displayed to client users
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Users = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { isInternalClientUser, clientId } = useAuth();

    const { order, orderBy, handleRequestSort } = useTableSort();

    const { currentPage, setCurrentPage, handlePagination } =
        useManagePagination();

    const [users, setUsers] = useState();

    const [paidSeats, setPaidSeats] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [manageSeatsOpen, setManageSeatsOpen] = useState(false);

    const [draftPaidSeats, setDraftPaidSeats] = useState();

    /**
     * call service to get My Program users data
     */
    useEffect(() => {
        getMyProgramUsers(clientId)
            .then((response) => {
                setUsers(response.activeUsers);

                setPaidSeats(response.paidSeats);
            })
            .catch(getDefaultErrorHandler("getMyProgramUsers"))
            .finally(() => {
                setIsLoading(false);
            });
        //eslint-disable-next-line
    }, []);

    /**
     * reset to the first page when the table sort changes
     */
    useEffect(() => {
        setCurrentPage(1);
    }, [orderBy, order, setCurrentPage]);

    /**
     * toggle the manage seats dialog open
     */
    const handleManageOpen = () => {
        setManageSeatsOpen(true);

        setDraftPaidSeats(paidSeats);
    };

    const closeManage = () => {
        setManageSeatsOpen(false);

        setDraftPaidSeats();
    };

    /**
     * passed to dialog; handle submitting the paid seat value
     */
    const handleDialogSubmit = () => {
        updatePaidSeats(clientId, { paidSeats: draftPaidSeats || null })
            .then((response) => {
                setPaidSeats(response.paidSeats);

                closeManage();
            })
            .catch(getDefaultErrorHandler("updatePaidSeats"));
    };

    /**
     * do not allow users to save unless value is at least 1 or emtpy
     * @returns {boolean}
     */
    const shouldDisableSave = () =>
        !!draftPaidSeats &&
        (draftPaidSeats < 1 || draftPaidSeats.toString().includes("."));

    /**
     * handle the seat count input change
     * @param {object} target
     */
    const handleChange = ({ target }) => {
        setDraftPaidSeats(target.value);
    };

    /**
     * apply sorting and pagination to the users list; this is what will be displayed in the table
     * @type {array}
     */
    const displayedUsers = useMemo(() => {
        if (!users?.length) {
            return [];
        }

        return getDisplayedItems({
            items: users,
            orderBy,
            order,
            page: currentPage,
        });
    }, [users, order, orderBy, currentPage]);

    /**
     * determine display for last activity column
     * @param dateLong
     * @returns {string}
     */
    const getDisplayedDate = (dateLong) =>
        dateLong
            ? getFormattedDate({ date: getPRTime(new Date(dateLong)) })
            : "N/A";

    return (
        <MyProgramLayoutContainer title="Users">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <Typography variant="h1" component="h1">
                        Users
                    </Typography>
                    <ExportLink
                        url={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/users/export`}
                        disabled={!users?.length}
                    />
                </Box>
                <ManageButton
                    isInternalClientUser={isInternalClientUser}
                    handleClick={handleManageOpen}
                    text="Manage Paid Seats"
                />
            </PRPageContentContainer>
            <PRPageContentContainer mb={2}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        {paidSeats > 0 && (
                            <>
                                Paid Seats Filled: {users?.length}/{paidSeats}
                            </>
                        )}
                    </Box>

                    <PRPagination
                        pageSize={MY_PROGRAM_PAGE_SIZE}
                        resultCount={users?.length || 0}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                    />
                </Box>
            </PRPageContentContainer>
            <Table
                sx={{
                    minWidth: "100%",
                }}
                stickyHeader
            >
                <PRTableHead
                    columns={USERS_HEAD_CELLS}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                />
                {!isLoading && (
                    <TableBody>
                        {!users?.length ? (
                            <NoResults message={NO_USERS_MESSAGE} />
                        ) : (
                            displayedUsers.map((user) => (
                                <TableRow key={user.username}>
                                    <TableCell>{user.fullName}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <CheckIconTableCell
                                        showCheckIcon={user.fullTextFlag}
                                    />
                                    <TableCell align="right">
                                        {getDisplayedDate(
                                            user.lastActivityDateLong
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                )}
            </Table>
            {manageSeatsOpen && (
                <Dialog
                    title="Manage Paid Seats"
                    open={manageSeatsOpen}
                    maxWidth="sm"
                    onSubmit={handleDialogSubmit}
                    onCancel={closeManage}
                    disableSave={shouldDisableSave()}
                >
                    <Typography component="legend" mb={1}>
                        Number of paid seats:
                    </Typography>
                    <PRTextField
                        type="number"
                        inputConfig={{
                            name: "paidSeats",
                            value: draftPaidSeats || "",
                            onChange: handleChange,
                            inputProps: { min: 1 },
                        }}
                    />
                </Dialog>
            )}
        </MyProgramLayoutContainer>
    );
};
export default CL01Users;
