import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { getDefaultErrorHandler } from "../../util/services";
import { STATUS_VALUE_MAP } from "../util";
import { getMediaTypes } from "@publicrelay/service/dist/analyst/influencers";
import { MEDIA_TYPE_OTHER } from "../../components/shared/influencers/constants";

// this will be stored at state.influencers
export const name = "influencers";

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get the media types from the db
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchMediaTypes = createAsyncThunk(
    `${name}/fetchMediaTypes`,
    async (_, { rejectWithValue }) => {
        try {
            return await getMediaTypes();
        } catch (error) {
            getDefaultErrorHandler("fetchMediaTypes")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * influences slice initial values
 */
const initialState = {
    mediaTypesArray: null,
    mediaTypesFetchStatus: STATUS_VALUE_MAP.IDLE,
};

const influencersSlice = createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchMediaTypes.pending, (state) => {
                state.mediaTypesFetchStatus = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchMediaTypes.fulfilled, (state, action) => {
                state.mediaTypesFetchStatus = STATUS_VALUE_MAP.SUCCEEDED;

                // finally add media types to redux store!
                state.mediaTypesArray = action.payload;
            });
    },
});

/**
 * Select the current array of media type objects from the store
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMediaTypes = createSelector(
    (state) => state[name],
    (subState) => subState.mediaTypesArray
);

export const selectSortedMediaTypes = createSelector(
    selectMediaTypes,
    (mediaTypes) => {
        if (mediaTypes) {
            return mediaTypes.map((mt) => {
                const sortedItems = [...mt.items];
                sortedItems.sort((a, b) => {
                    // for any category the media type "Other" sorts to the bottom
                    if (
                        a.name.toLowerCase() === MEDIA_TYPE_OTHER.toLowerCase()
                    ) {
                        return 1;
                    } else if (
                        b.name.toLowerCase() === MEDIA_TYPE_OTHER.toLowerCase()
                    ) {
                        return -1;
                    }
                    return a.name.localeCompare(b.name);
                });
                return { ...mt, items: sortedItems };
            });
        } else {
            return mediaTypes;
        }
    }
);

export const selectMediaTypeCount = createSelector(
    selectMediaTypes,
    (mediaTypes) => {
        if (mediaTypes) {
            return mediaTypes.flatMap((category) => category.items).length;
        } else {
            return 0;
        }
    }
);

/**
 * Select the current status for the media types fetch
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMediaTypesFetchStatus = createSelector(
    (state) => state[name],
    (subState) => subState.mediaTypesFetchStatus
);

export default influencersSlice.reducer;
