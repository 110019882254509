import LocalPhoneIconLink from "../shared/contacts/LocalPhoneIconLink";
import EmailIconLink from "../shared/contacts/EmailIconLink";
import Stack from "@mui/material/Stack";
import XcomIconLink from "../shared/contacts/XcomIconLink";
import FacebookIconLink from "../shared/contacts/FacebookIconLink";
import LinkedInIconLink from "../shared/contacts/LinkedInIconLink";
import PropTypes from "prop-types";

/**
 * @typedef {{
 * outletId: number,
 * url: string,
 * name: string,
 * email?: string,
 * linkedin?: string,
 * facebook?: string,
 * wikipedia?: string,
 * youtube?: string,
 * twitter?: string,
 * workPhone?: string,
 * potentialImpressions?: number,
 * outletPower?: number,
 * }} OutletDetail
 *
 * @param {OutletDetail} outlet
 * @returns {JSX.Element}
 * @constructor
 */
const OutletContactInformation = ({ outlet = {} }) => {
    const { workPhone, email, twitter, facebook, linkedin } = outlet;

    return (
        <Stack direction="row" spacing={1}>
            {workPhone && <LocalPhoneIconLink phoneNumber={workPhone} />}
            {email && <EmailIconLink email={email} />}
            {twitter && <XcomIconLink handle={twitter} />}
            {facebook && <FacebookIconLink facebook={facebook} />}
            {linkedin && <LinkedInIconLink linkedIn={linkedin} />}
        </Stack>
    );
};

OutletContactInformation.propTypes = { outlet: PropTypes.object.isRequired };

export default OutletContactInformation;
