import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { getDefaultErrorHandler } from "../../util/services";
import { STATUS_VALUE_MAP } from "../util";
import {
    getCountries,
    getCustomZones,
    getDmas,
    getStates,
} from "@publicrelay/service/dist/client/geography";

// this will be stored at state.geography
export const name = "geography";

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get a list of US states
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchStatesList = createAsyncThunk(
    `${name}/fetchStatesList`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getStates();

            return response.items;
        } catch (error) {
            getDefaultErrorHandler("fetchStatesList")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get a list of DMAs
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchDmasList = createAsyncThunk(
    `${name}/fetchDmasList`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getDmas();

            return response.items;
        } catch (error) {
            getDefaultErrorHandler("fetchDmasList")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get a list of countries
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchCountriesList = createAsyncThunk(
    `${name}/fetchCountriesList`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getCountries();

            return response.items;
        } catch (error) {
            getDefaultErrorHandler("fetchCountriesList")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get a list of custom zones for the given client
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchCustomZonesList = createAsyncThunk(
    `${name}/fetchCustomZonesList`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getCustomZones();

            return response.items;
        } catch (error) {
            getDefaultErrorHandler("fetchCustomZonesList")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * geography slice initial values
 * @type {{customZones: {customZonesArray: null, status: string}, dma: {dmaArray: null, status: string}, countries: {countriesArray: null, status: string}, states: {statesArray: null, status: string}}}
 */
const initialState = {
    states: {
        statesArray: null,
        status: STATUS_VALUE_MAP.IDLE,
    },
    dma: {
        dmaArray: null,
        status: STATUS_VALUE_MAP.IDLE,
    },
    countries: {
        countriesArray: null,
        status: STATUS_VALUE_MAP.IDLE,
    },
    customZones: {
        customZonesArray: null,
        status: STATUS_VALUE_MAP.IDLE,
    },
};

const geographySlice = createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchStatesList.pending, (state) => {
                state.states.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchStatesList.fulfilled, (state, action) => {
                state.states.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our states to redux
                state.states.statesArray = action.payload;
            })
            .addCase(fetchDmasList.pending, (state) => {
                state.dma.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchDmasList.fulfilled, (state, action) => {
                state.dma.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our DMAs to redux
                state.dma.dmaArray = action.payload;
            })
            .addCase(fetchCountriesList.pending, (state) => {
                state.countries.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchCountriesList.fulfilled, (state, action) => {
                state.countries.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our countries to redux
                state.countries.countriesArray = action.payload;
            })
            .addCase(fetchCustomZonesList.pending, (state) => {
                state.customZones.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchCustomZonesList.fulfilled, (state, action) => {
                state.customZones.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our custom zones to redux
                state.customZones.customZonesArray = action.payload;
            });
    },
});

/**
 * Select the current array of state objects from the store
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectStates = createSelector(
    (state) => state[name],
    (subState) => subState.states.statesArray
);

/**
 * select the status of the states fetch
 * @type {OutputSelector<[(function(*): *)], string, (...args: SelectorResultArray<[(function(*): *)]>) => (string & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectStatesStatus = createSelector(
    (state) => state[name],
    (subState) => subState.states.status
);

/**
 * select the current list of dma objects
 * @type {OutputSelector<[(function(*): *)], *, (...args: SelectorResultArray<[(function(*): *)]>) => any, GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectDmas = createSelector(
    (state) => state[name],
    (subState) => subState.dma.dmaArray
);

/**
 * select the status of the dma fetch
 * @type {OutputSelector<[(function(*): *)], string, (...args: SelectorResultArray<[(function(*): *)]>) => (string & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectDmaStatus = createSelector(
    (state) => state[name],
    (subState) => subState.dma.status
);

/**
 * select the current list of counties
 * @type {OutputSelector<[(function(*): *)], null, (...args: SelectorResultArray<[(function(*): *)]>) => (null & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectCountries = createSelector(
    (state) => state[name],
    (subState) => subState.countries.countriesArray
);

/**
 * select the status of the countries fetch
 * @type {OutputSelector<[(function(*): *)], string, (...args: SelectorResultArray<[(function(*): *)]>) => (string & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectCountriesStatus = createSelector(
    (state) => state[name],
    (subState) => subState.countries.status
);

/**
 * select the current list of custom zones
 * @type {OutputSelector<[(function(*): *)], null, (...args: SelectorResultArray<[(function(*): *)]>) => (null & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectCustomZones = createSelector(
    (state) => state[name],
    (subState) => subState.customZones.customZonesArray
);

/**
 * select the current status for fetching custom zones from the BE
 * @type {OutputSelector<[(function(*): *)], string, (...args: SelectorResultArray<[(function(*): *)]>) => (string & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectCustomZonesStatus = createSelector(
    (state) => state[name],
    (subState) => subState.customZones.status
);

export default geographySlice.reducer;
