import { useSnackbar } from "notistack";
import { showNotificationBuilder } from "../components/snacks/NotificationBuilder";

const useNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const notify = (message, severity = "warning") => {
        const showNotification = showNotificationBuilder(enqueueSnackbar, {});

        showNotification(message, severity);
    };

    return { notify };
};

export default useNotification;
