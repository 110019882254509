import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { STATUS_VALUE_MAP } from "../util";
import { getFeatureTogglz } from "../../stagedServices";

// this will be stored at state.system
export const name = "system";

/**
 * call service to get a list of feature togglz
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchFeatureTogglz = createAsyncThunk(
    `${name}/fetchFeatureTogglz`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await getFeatureTogglz();

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const initialState = {
    featureTogglz: {},
    featureTogglzStatus: STATUS_VALUE_MAP.IDLE,
};

const systemSlice = createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFeatureTogglz.pending, (state) => {
                state.featureTogglzStatus = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchFeatureTogglz.fulfilled, (state, action) => {
                state.featureTogglzStatus = STATUS_VALUE_MAP.SUCCEEDED;

                // Add our feature togglz to redux
                state.featureTogglz = action.payload;
            });
    },
});

/**
 * select the current feature togglz held in state
 * @type {OutputSelector<[(function(*): *)], {}|any, (...args: SelectorResultArray<[(function(*): *)]>) => any, GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectFeatureTogglz = createSelector(
    (state) => state[name],
    (subState) => subState.featureTogglz
);

/**
 * select the current status of the feature toggles fetch
 * @type {OutputSelector<[(function(*): *)], string|*, (...args: SelectorResultArray<[(function(*): *)]>) => any, GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const selectFeatureTogglzStatus = createSelector(
    (state) => state[name],
    (subState) => subState.featureTogglzStatus
);

export default systemSlice.reducer;
