import Grid from "@mui/material/Grid";
import ProgramSidePanel from "./ProgramSidePanel";
import { ProgramContainerBox } from "./styled";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

/**
 * "My Program" layout, including the side panel / navigation
 * Set page title here
 * @param {string} title
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
const MyProgramLayoutContainer = ({ title = "", children }) => {
    return (
        <ProgramContainerBox>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Grid container spacing={3}>
                {/*start left side*/}
                <Grid item xs={3}>
                    <ProgramSidePanel />
                </Grid>
                {/*end left side*/}

                {/*start right side*/}
                <Grid item xs={9}>
                    {children}
                </Grid>
                {/*end right side*/}
            </Grid>
        </ProgramContainerBox>
    );
};

MyProgramLayoutContainer.propTypes = {
    title: PropTypes.string.isRequired,
    childred: PropTypes.elementType,
};

export default MyProgramLayoutContainer;
