/**
 * methods to standardize validation
 */

/**
 * from methods.js (publicrelay.regexp.url)
 * @type {String}
 */
const REGEXP_URL = new RegExp(
    "((https?|ftps?)\\://)(((?:(?:[\\da-zA-Z](?:[-\\da-zA-Z]{0,61}[\\da-zA-Z])?)\\.)*(?:[a-zA-Z](?:[-\\da-zA-Z]{0,6}[\\da-zA-Z])?)\\.?)|(((\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])|(0[xX]0*[\\da-fA-F]?[\\da-fA-F]\\.){3}0[xX]0*[\\da-fA-F]?[\\da-fA-F]|(0+[0-3][0-7][0-7]\\.){3}0+[0-3][0-7][0-7]|(0|[1-9]\\d{0,8}|[1-3]\\d{9}|4[01]\\d{8}|42[0-8]\\d{7}|429[0-3]\\d{6}|4294[0-8]\\d{5}|42949[0-5]\\d{4}|429496[0-6]\\d{3}|4294967[01]\\d{2}|42949672[0-8]\\d|429496729[0-5])|0[xX]0*[\\da-fA-F]{1,8}|([\\da-fA-F]{1,4}\\:){7}[\\da-fA-F]{1,4}|([\\da-fA-F]{1,4}\\:){6}((\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])))(\\:\\d+)?(/(?:[^\"'\\s/]+/)*(?:[^\"'\\s/]+(?:\\?[^\\s]*)?)?)?"
);

/**
 * due to complexity of possible email addresses, use simplest/most permissive validation
 * @type {RegExp}
 */
const REGEXP_EMAIL = new RegExp(/\S+@\S+\.\S+/);

/**
 * check if given string matches email syntax
 *
 * @param  {String} email - validate this
 * @return {Boolean} - true if valid
 */
export const isEmail = (email) => {
    return REGEXP_EMAIL.test(email);
};

/**
 * check if given string matches url syntax
 *
 * @param  {String} url - validate this
 * @return {Boolean} - true if valid
 */
export const isUrl = (url) => {
    return REGEXP_URL.test(url);
};

/**
 * does the string have at least one digit
 * @param {string} s
 * @returns {boolean}
 */
export const hasOneNumber = (s) => /(?=.*\d)/.test(s);

/**
 * does the string have at least one uppercase letter
 * @param {string} s
 * @returns {boolean}
 */
export const hasOneUpperCase = (s) => /(?=.*[A-Z])/.test(s);

/**
 * does the string have at least one lowercase letter
 * @param {string} s
 * @returns {boolean}
 */
export const hasOneLowerCase = (s) => /(?=.*[a-z])/.test(s);

/**
 * does the string have at least one special char, whitespace incl
 * @param {string} s
 * @returns {boolean}
 */
export const hasOneSpecialChar = (s) => /([^A-Za-z0-9])/.test(s);

/**
 * is the string  at least n characters long
 * @param {string} s
 * @param {number} n
 * @returns {boolean}
 */
export const hasAtLeastNChar = (s = "", n = 8) =>
    new RegExp(`(?=.{${n},})`).test(s.trim());
