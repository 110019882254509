import { getClientUsername } from "./users";

/**
 * ported from methods.js
 * Are we on an an ex environment (gobarometer or exanalystdev exanalystqa exclientqa etc)
 * @returns {boolean}
 */
export const isEXEnvironment = () => {
    // Regex that encompasses all EX domains
    const exRegex = /(ex[^]+\.publicrelay)|(gobarometer).com/;

    return exRegex.test(window.location.hostname);
};

/**
 * Method to get the google analytics tracking Id
 * @returns {trackingId}
 */
export const getGoogleTrackingId = () => {
    const keyMap = {
        "dev-external": "UA-99569016-1",
        "dev-internal": "UA-99569016-2",
        "qa-external": "UA-99589696-1",
        "qa-internal": "UA-99589696-2",
        "prod-external": "UA-23805916-1",
        "prod-internal": "UA-23805916-2",
        error: "UA-23805916-3", // Send all errors to client-prod error property
    };

    const isInternal = /-/.test(getClientUsername()) ? "internal" : "external";
    const key = process.env.REACT_APP_ENV_TYPE.toLowerCase() + "-" + isInternal;

    let trackingId = keyMap[key];

    if (!trackingId) {
        console.log("Something went wrong with getting the tracking ID");
        trackingId = keyMap["error"];
    }

    return trackingId;
};

/**
 * from dojo methods
 * Returns the base URL for moving from the client side to the analyst side (e.g. qa client -> qa analyst)
 * @return {string} The base host URL on the analyst side
 */
export const getAnalystRedirectBase = () => {
    const hostmap = getHostMap(true);

    return `${hostmap[window.location.origin]}/`;
};

// ported from dojo methods
const AN_HOST_PROD = "https://analyst.publicrelay.com:8443";
const CL_HOST_PROD = "https://client.publicrelay.com";
const AN_HOST_QA = "https://analystqa.publicrelay.com:8443";
const CL_HOST_QA = "https://clientqa.publicrelay.com";
const AN_HOST_LAB = "https://analystlab.publicrelay.com:8443";
const CL_HOST_LAB = "https://clientlab.publicrelay.com";
const AN_HOST_LOCALPR = "https://local.publicrelay.com:8443";
const CL_HOST_LOCALPR = "https://local.publicrelay.com";
const AN_HOST_LOCAL = "https://localanalyst.publicrelay.com:8443";
const CL_HOST_LOCAL = "https://localclient.publicrelay.com";

/**
 * from dojo methods
 * Provides a mapping for jumping between analyst and client environments
 * default to jump client > analyst
 * @param {boolean} [toAnalyst] is this a Client -> Analyst transition or an Analyst -> Client (default)
 * @returns {Object} mapping of the URLs
 */
const getHostMap = function (toAnalyst = false) {
    const analystToClientMapping = {
        [AN_HOST_PROD]: CL_HOST_PROD,
        [AN_HOST_QA]: CL_HOST_QA,
        [AN_HOST_LAB]: CL_HOST_LAB,
        [AN_HOST_LOCALPR]: CL_HOST_LOCALPR,
        [AN_HOST_LOCAL]: CL_HOST_LOCAL,
    };

    let result = {};

    if (!toAnalyst) {
        result = analystToClientMapping;
    } else {
        Object.keys(analystToClientMapping).forEach(function (key) {
            const value = analystToClientMapping[key];
            result[value] = key;
        });
    }

    return result;
};
