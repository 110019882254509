import List from "@mui/material/List";
import { ProgramNavItem } from "./styled";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink } from "react-router-dom";
import {
    getCL01AlertsUrl,
    getCL01FeedbackUrl,
    getCL01NewslettersUrl,
    getCL01OutletListsUrl,
    getCL01OverviewUrl,
    getCL01ReportsUrl,
    getCL01SettingsUrl,
    getCL01TagsUrl,
    getCL01UsersUrl,
} from "../../util/urls";
import ListItemIcon from "@mui/material/ListItemIcon";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ListItemText from "@mui/material/ListItemText";
import BarChartIcon from "@mui/icons-material/BarChart";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import SendIcon from "@mui/icons-material/Send";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FeedIcon from "@mui/icons-material/Feed";
import GroupsIcon from "@mui/icons-material/Groups";
import useAuth from "../../auth/useAuth";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSelector } from "react-redux";
import { selectMyProgramNavItems } from "../../redux/slices/myProgramSlice";

// WIP
const Nav = () => {
    const { isInternalClientUser } = useAuth();

    const navItems = useSelector(selectMyProgramNavItems);

    return (
        <nav aria-label="dashboard pages">
            {/*wait for settings service to return before populating any of the nav*/}
            {navItems && (
                <List>
                    <ProgramNavItem disablePadding>
                        <ListItemButton
                            component={NavLink}
                            to={getCL01OverviewUrl()}
                        >
                            <ListItemIcon>
                                <TravelExploreIcon />
                            </ListItemIcon>
                            <ListItemText primary="Overview" />
                        </ListItemButton>
                    </ProgramNavItem>
                    {navItems.reportsEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01ReportsUrl()}
                            >
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    {navItems.newslettersEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01NewslettersUrl()}
                            >
                                <ListItemIcon>
                                    <LocalPostOfficeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Newsletters & Recipients" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    {navItems.alertsEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01AlertsUrl()}
                            >
                                <ListItemIcon>
                                    <NotificationsNoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Alerts & Recipients" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    {navItems.tagsEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01TagsUrl()}
                            >
                                <ListItemIcon>
                                    <LocalOfferIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tag Structure" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    {navItems.outletListsEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01OutletListsUrl()}
                            >
                                <ListItemIcon>
                                    <FeedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Outlet Lists" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    {navItems.usersEnabled && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01UsersUrl()}
                            >
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                    <ProgramNavItem disablePadding>
                        <ListItemButton
                            component={NavLink}
                            to={getCL01FeedbackUrl()}
                        >
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Feedback & Questions " />
                        </ListItemButton>
                    </ProgramNavItem>
                    {isInternalClientUser && (
                        <ProgramNavItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={getCL01SettingsUrl()}
                            >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItemButton>
                        </ProgramNavItem>
                    )}
                </List>
            )}
        </nav>
    );
};

export default Nav;
