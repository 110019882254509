import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { getClientFullName } from "../../util/cookies";
import { getClientWikiLink, getTermsLink } from "../../util/urls";
import {
    PR_TWITTER,
    CONTACT_EMAIL,
    PUBLIC_SITE,
    PR_FB,
    PR_LI,
} from "../../util/prUrls";
import Copyright from "../shared/Copyright";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Box from "@mui/material/Box";
import useAuth from "../../auth/useAuth";
import { StyledLink } from "../styled/StyledLink";
import PropTypes from "prop-types";

/**
 * Display the standard client side footer
 * @param {string} pageTitle
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({ pageTitle }) => {
    const authUser = useAuth();

    const clientFullName = getClientFullName();

    return (
        <AppBar position="sticky">
            <Toolbar variant="dense">
                <Container fixed={true} maxWidth={false}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ borderColor: "primary.main" }}
                            />
                        }
                        spacing={2}
                    >
                        {authUser.isInternalClientUser && (
                            <div>{clientFullName}</div>
                        )}
                        {authUser.isInternalClientUser && pageTitle && (
                            <div>
                                <StyledLink href={getClientWikiLink(pageTitle)}>
                                    {pageTitle}
                                </StyledLink>
                            </div>
                        )}
                        <div>
                            <StyledLink href={getTermsLink()}>Terms</StyledLink>
                        </div>
                        <div>
                            <StyledLink href={PUBLIC_SITE}>About</StyledLink>
                        </div>
                        <div>
                            <StyledLink href={`mailto:${CONTACT_EMAIL}`}>
                                Contact
                            </StyledLink>
                        </div>

                        <div>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <div>Follow Us:</div>
                                <StyledLink href={PR_TWITTER}>
                                    <XIcon sx={{ ml: 1, fontSize: "1.5em" }} />
                                </StyledLink>
                                <StyledLink href={PR_FB}>
                                    <FacebookIcon />
                                </StyledLink>
                                <StyledLink href={PR_LI}>
                                    <LinkedInIcon />
                                </StyledLink>
                            </Box>
                        </div>
                        <div>
                            <Copyright />
                        </div>
                    </Stack>
                </Container>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;

Footer.propTypes = {
    pageTitle: PropTypes.string,
};
