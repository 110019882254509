import { forwardRef, useCallback } from "react";
import Alert from "@mui/material/Alert";
import { useSnackbar, SnackbarContent } from "notistack";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * NICOLAI TODO: MAY DEPRECATE IF WE SUCCESSFULLY PUBLISH COMPONENT LIBRARY
 */
const NotistackContent = forwardRef((props, ref) => {
    const { closeSnackbar } = useSnackbar();

    /**
     * closes the snackbar
     */
    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref}>
            <Alert severity={props.severity} onClose={handleDismiss}>
                <Grid container spacing={3} style={{ minWidth: "25vw" }}>
                    <Grid item xs={12}>
                        {props.children ? props.children : props.message}
                    </Grid>
                </Grid>
            </Alert>
        </SnackbarContent>
    );
});

NotistackContent.propTypes = {
    children: PropTypes.node,
    id: PropTypes.number,
    message: PropTypes.string,
    severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
};

export default NotistackContent;
