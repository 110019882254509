import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { getClientId } from "../util/cookies";
import { getClientUuid } from "../util/users";

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.set({
            dimension1: getClientId(),
            dimension2: getClientUuid(),
            userId: getClientUuid(),
            siteSpeedSampleRate: 100,
            customMap: { dimension1: "ClientId", dimension2: "UserId" },
        });
        ReactGA.pageview(location.pathname);
    }, [location]);
};

export default usePageTracking;
