import { AuthContext } from "./AuthContext";
import { getClientId } from "../util/cookies";
import {
    getClientFirstName,
    getClientUsername,
    getClientUuid,
    isInternalClientUser,
    isLoggedIn,
} from "../util/users";

/**
 * Provides application user info from the cookie for the logged in user
 * Eventually might provide login/out methods
 * see https://reactrouter.com/docs/en/v6/examples/auth
 * @param {React.ReactNode} children
 * @returns {React.Context}
 * @constructor
 */
const AuthProvider = ({ children }) => {
    let value = {
        isAuth: isLoggedIn(),
        clientId: getClientId(),
        isInternalClientUser: isInternalClientUser(),
        uuid: getClientUuid(),
        username: getClientUsername(),
        firstName: getClientFirstName(),
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
