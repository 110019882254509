import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { getDefaultErrorHandler } from "../../util/services";
import { STATUS_VALUE_MAP } from "../util";
import { getMyProgramSettings } from "@publicrelay/service/dist/client/myProgram";
import { getClientId } from "../../util/cookies";
import { DEFAULT_PR_CONTACT } from "../../components/myProgram/constants";

// this will be stored at state.myProgram
export const name = "myProgram";

/**
 * Redux Toolkit's createAsyncThunk API generates thunks that automatically dispatch "start/success/failure" actions
 * (see extraReducers case)
 * this thunk calls the service to get the settings and support data for My Program
 * see https://redux.js.org/tutorials/essentials/part-5-async-logic for further documentation
 */
export const fetchMyProgramSettings = createAsyncThunk(
    `${name}/fetchMyProgramSettings`,
    async (_, { rejectWithValue }) => {
        try {
            return await getMyProgramSettings(getClientId());
        } catch (error) {
            getDefaultErrorHandler("fetchMyProgramSettings")(error);

            return rejectWithValue(error);
        }
    }
);

/**
 * @typedef {{
 * clientId: number,
 * username: string,
 * clientLogoUrl?: string,
 * reportsEnabled: boolean,
 * newslettersEnabled: boolean,
 * alertsEnabled: boolean,
 * tagsEnabled: boolean,
 * outletListsEnabled: boolean,
 * usersEnabled: boolean,
 * logoEnabled: boolean,
 * myProgramContacts:{
 * fullName: string,
 * titleDescription: string,
 * email: string,
 * }[]
 * }} MyProgramSettings
 */

/**
 * myProgram slice initial values
 * @type {{myProgramSettings: {settingsData: MyProgramSettings|null, status: string}}}
 */
const initialState = {
    myProgramSettings: {
        settingsData: null,
        status: STATUS_VALUE_MAP.IDLE,
    },
};

const myProgramSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        setMyProgramSettingsData(state, { payload }) {
            state.myProgramSettings.settingsData = payload;
        },
        setMyProgramContacts(state, { payload }) {
            state.myProgramSettings.settingsData.myProgramContacts = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMyProgramSettings.pending, (state) => {
                state.myProgramSettings.status = STATUS_VALUE_MAP.LOADING;
            })
            .addCase(fetchMyProgramSettings.fulfilled, (state, action) => {
                state.myProgramSettings.status = STATUS_VALUE_MAP.SUCCEEDED;

                // Add settings to redux store
                state.myProgramSettings.settingsData = action.payload;
            });
    },
});

/**
 * Select the current settings data for My Program
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMyProgramSettingsData = createSelector(
    (state) => state[name],
    (subState) => subState.myProgramSettings.settingsData
);

/**
 * Select the current settings service status for My Program
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMyProgramSettingsStatus = createSelector(
    (state) => state[name],
    (subState) => subState.myProgramSettings.status
);

/**
 * Select only the nav items from the current settings data. inserted in order matching navigation
 */
export const selectMyProgramNavItems = createSelector(
    (state) => state[name],
    (subState) => {
        if (subState.myProgramSettings.settingsData) {
            const settingsData = subState.myProgramSettings.settingsData;

            return {
                reportsEnabled: settingsData.reportsEnabled,
                newslettersEnabled: settingsData.newslettersEnabled,
                alertsEnabled: settingsData.alertsEnabled,
                tagsEnabled: settingsData.tagsEnabled,
                outletListsEnabled: settingsData.outletListsEnabled,
                usersEnabled: settingsData.usersEnabled,
            };
        } else {
            return undefined;
        }
    }
);

/**
 * Select the current client logo to display on the My Program pages
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMyProgramClientLogoUrl = createSelector(
    (state) => state[name],
    (subState) => subState.myProgramSettings.settingsData?.clientLogoUrl
);

/**
 * Select the configuration value for client logo enabled
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMyProgramClientLogoEnabled = createSelector(
    (state) => state[name],
    (subState) => !!subState.myProgramSettings.settingsData?.logoEnabled
);

/**
 * Select the current support contacts for My Program
 * returns the list of contacts from the service; if none provided then returns the default
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectMyProgramSupportContacts = createSelector(
    (state) => state[name],
    (subState) => {
        const myProgramContacts =
            subState.myProgramSettings.settingsData?.myProgramContacts;

        if (!myProgramContacts || !myProgramContacts.length) {
            //always return at least the default contact
            return [DEFAULT_PR_CONTACT];
        } else {
            return subState.myProgramSettings.settingsData.myProgramContacts;
        }
    }
);

export const { setMyProgramSettingsData, setMyProgramContacts } =
    myProgramSlice.actions;

export default myProgramSlice.reducer;
