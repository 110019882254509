import { calculateTwitterUrl } from "../../discover/util";
import { StyledLink } from "../../styled/StyledLink";
import XIcon from "@mui/icons-material/X";
import PropTypes from "prop-types";

/**
 * display the x.com icon with link
 * @param handle
 * @returns {JSX.Element}
 * @constructor
 */
const XcomIconLink = ({ handle }) => (
    <StyledLink
        target="_blank"
        rel="noreferrer"
        href={calculateTwitterUrl(handle)}
    >
        <XIcon />
    </StyledLink>
);

XcomIconLink.propTypes = { handle: PropTypes.string.isRequired };

export default XcomIconLink;
