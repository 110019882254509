import { PRTextField } from "../shared/PRInputs";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getCL16Url } from "../../util/urls";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

/**
 * create the site search that appears in the top nav
 * @param {object} sx
 * @returns {JSX.Element}
 * @constructor
 */
const Search = ({ sx }) => {
    const [value, setValue] = useState("");

    const handleSearchChange = (evt) => {
        setValue(evt.target.value);
    };

    /**
     * submit handler; open global search in this window
     * @param evt
     */
    const handleSearch = (evt) => {
        evt.preventDefault();

        if (!value.trim().length) {
            return;
        }

        window.location.href = getCL16Url(value);
    };

    return (
        <Box {...(sx && { sx })}>
            <form autoComplete="off" onSubmit={handleSearch}>
                <PRTextField
                    id="siteSearch"
                    formControlConfig={{
                        fullWidth: true,
                    }}
                    startAdornmentNode={
                        <IconButton
                            onClick={handleSearch}
                            size="small"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    }
                    inputConfig={{
                        autoComplete: "off",
                        onChange: handleSearchChange,
                        value: value,
                        size: "small",
                        inputProps: {
                            placeholder: "Search...",
                            maxLength: 500,
                            onFocus: (evt) => evt.target.select(),
                        },
                        sx: { width: "190px" },
                    }}
                />
            </form>
        </Box>
    );
};

Search.propTypes = {
    sx: PropTypes.object,
};

export default Search;
