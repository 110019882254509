import { useLocation } from "react-router-dom";
import { MENUS_URLS_MAPPING } from "./constants";
import { useMemo } from "react";

/**
 * does one of the subnav paths for the given top level menu name match the location we are currently on?
 * @param {string} navName
 * @returns {boolean}
 */
const useSelectedNav = (navName) => {
    const location = useLocation();

    return useMemo(() => {
        let isNavSelected = false;

        if (MENUS_URLS_MAPPING[navName]) {
            isNavSelected = MENUS_URLS_MAPPING[navName].some((path) =>
                location.pathname.includes(path)
            );
        }

        return isNavSelected;
    }, [location.pathname, navName]);
};

export default useSelectedNav;
