import React from "react";
import store from "../redux/index";
import { setServiceError } from "../redux/slices/serviceSlice";
import Pre from "../components/styled/Pre";

/**
 * Given a key for a service, return the default error handler
 * Fires a redux action that stores the error under the key
 * @param serviceKey
 * @return {function}
 */
export const getDefaultErrorHandler =
    (serviceKey) =>
    (error, ...rest) => {
        store.dispatch(setServiceError({ serviceKey, error }));
    };

/**
 * Given an error object from the backend, derive a simple string
 * @param error
 * @return {string|JSX.Element}
 */
export const getSimpleErrorMessage = (error) => {
    const helpMessage =
        "Please try again or contact your analyst with the error code above.";

    if (error) {
        if (error.errors && error.errors.map) {
            return error.errors.map((error, index) => (
                <Pre key={index}>{error.message || error.toString()}</Pre>
            ));
        } else if (error.errorType) {
            let output = error.errorType;

            if (output === "Unauthorized") {
                output = `${output} service request`;
            }

            return (
                <div>
                    {output} ({error.responseStatus})<p>{helpMessage}</p>
                </div>
            );
        } else {
            return (
                <div>
                    {error.message || error}
                    <p>{helpMessage}</p>
                </div>
            );
        }
    } else {
        return (
            <div>
                Unspecified error
                <p>{helpMessage}</p>
            </div>
        );
    }
};
