import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import useAuth from "../../auth/useAuth";
import ManageButton from "../../components/myProgram/ManageButton";
import { useEffect, useMemo, useState } from "react";
import {
    getDeadlinesByGrouping,
    getNewsletterDistributionListInfo,
    updateMyProgramVisibleNewsletters,
} from "@publicrelay/service/dist/client/myProgram";
import {
    getDistributionListRecipients,
    getNewsletterList,
} from "@publicrelay/service/dist/clientAnalyst/newsletters";
import { getDefaultErrorHandler } from "../../util/services";
import PRSelect from "../../components/shared/PRSelect";
import Box from "@mui/material/Box";
import NewsletterDeadlineAndSender from "../../components/myProgram/NewsletterDeadlineAndSender";
import {
    MY_PROGRAM_PAGE_SIZE,
    NO_NEWSLETTERS_MESSAGE,
    NO_RECIPIENTS_MESSAGE,
    OTHER_NEWSLETTERS_HEAD_CELLS,
    RESPONSIVE_NEWSLETTERS_HEAD_CELLS,
} from "../../components/myProgram/constants";
import PRTableHead from "../../components/shared/tables/PRTableHead";
import { useTableSort } from "../../hooks/useTableSort";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import NoResults from "../../components/shared/NoResults";
import { TableCell } from "@mui/material";
import CheckIconTableCell from "../../components/myProgram/CheckIconTableCell";
import PRPagination from "../../components/shared/PRPagination";
import ManageVisibleItems from "../../components/myProgram/ManageVisibleItems";
import {
    calculateSelectedItem,
    getDisplayedItems,
    getOptions,
} from "../../components/myProgram/util";
import useManagePagination from "../../hooks/useManagePagination";
import ExportLink from "../../components/myProgram/ExportLink";

/**
 * allow users to view recipients & distro data for client visible, "My Program" visible newsletters
 * allow internal users to edit which client visible newsletters are shown in "My Program"
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Newsletters = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { isInternalClientUser, clientId } = useAuth();

    const [newsletterList, setNewsletterList] = useState();

    const [selectedNewsletterGroupingId, setSelectedNewsletterGroupingId] =
        useState("");

    const [distributionListSender, setDistributionListSender] = useState();

    const [deadlineList, setDeadlineList] = useState();

    const [recipients, setRecipients] = useState();

    const [manageDialogOpen, setManageDiaogOpen] = useState(false);

    const [draftNewslettersList, setDraftNewslettersList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const { order, orderBy, handleRequestSort, resetSort } = useTableSort();

    const { currentPage, setCurrentPage, handlePagination } =
        useManagePagination();

    //call service to the newsletters used as options in the select
    useEffect(() => {
        getNewsletterList(clientId, { clientVisibled: true })
            .then((response) => {
                setNewsletterList(response);
            })
            .catch(getDefaultErrorHandler("getNewsletterList"))
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    /**
     * whenever we have a newly selected newsletter, call services to get the
     * distribution list info, deadline info, and recipients
     */
    useEffect(() => {
        if (selectedNewsletterGroupingId) {
            setIsLoading(true);

            Promise.all([
                getNewsletterDistributionListInfo(
                    clientId,
                    selectedNewsletterGroupingId
                ),
                getDeadlinesByGrouping(clientId, selectedNewsletterGroupingId),
                getDistributionListRecipients(
                    clientId,
                    selectedNewsletterGroupingId
                ),
            ])
                .then((response) => {
                    setDistributionListSender(response[0].sendAsEmail);

                    setDeadlineList(response[1].items);

                    setRecipients(response[2].items);
                })
                .catch((error) => {
                    getDefaultErrorHandler("getNewsletterPromiseAll")(error);
                    setRecipients();
                    setDeadlineList();
                    setDistributionListSender();
                })
                .finally(() => {
                    setIsLoading(false);
                    setCurrentPage(1);
                });
        }
    }, [selectedNewsletterGroupingId, clientId, setCurrentPage]);

    /**
     * reset to the first page when the table sort changes
     */
    useEffect(() => {
        setCurrentPage(1);
    }, [orderBy, order, setCurrentPage]);

    /**
     * format the "My Program" visible newsletters for use in the select
     * @type {{label: string, value: number}[]}
     */
    const newsletterSelectOptions = useMemo(
        () => getOptions(newsletterList, "groupingId"),
        [newsletterList]
    );

    /**
     * when the select options change we might need to update which newsletter is currently being viewed
     * 2 cases: (1) page load and (2) visible newsletter removed via Manage
     */
    useEffect(() => {
        setSelectedNewsletterGroupingId((selectedNewsletter) => {
            return calculateSelectedItem(
                newsletterSelectOptions,
                selectedNewsletter
            );
        });
    }, [newsletterSelectOptions]);

    /**
     * open the manage newsletter dialog (visible to internal users only)
     */
    const handleManageClick = () => {
        setManageDiaogOpen(true);

        setDraftNewslettersList(
            newsletterList.map((newsletter) => ({
                groupingId: newsletter.groupingId,
                name: newsletter.name,
                myProgramVisible: newsletter.myProgramVisible,
            }))
        );
    };

    /**
     * passed to the manage dialog; update the newsletters visible in "My Program"
     */
    const handleDialogSubmit = () => {
        updateMyProgramVisibleNewsletters(clientId, draftNewslettersList)
            .then((response) => {
                handleDialogClose();

                // if we've toggled the currently selected newsletter not visible in "My Program",
                // reset the selected option to avoid MUI complaining the value doesn't match
                // an option when we update the newsletters available for selection
                if (
                    response.some(
                        (nl) =>
                            nl.groupingId === selectedNewsletterGroupingId &&
                            !nl.myProgramVisible
                    )
                ) {
                    setSelectedNewsletterGroupingId("");
                }

                setNewsletterList(response);
            })
            .catch(getDefaultErrorHandler("updateMyProgramVisibleNewsletters"));
    };

    /**
     * close / reset the manage newsletters dialog
     */
    const handleDialogClose = () => {
        setManageDiaogOpen(false);

        setDraftNewslettersList([]);
    };

    /**
     * handle the select change
     * update the selected newsletter in state. triggers service calls (see effect)
     * @param target
     */
    const handleNewsletterChange = ({ target }) => {
        setSelectedNewsletterGroupingId(target.value);

        if (orderBy) {
            resetSort();
        }
    };

    /**
     * when the selected newsletter changes determine if it's type responsive
     * this dictates what columns are displayed in the table
     * @type {boolean|undefined}
     */
    const isResponsive = useMemo(() => {
        if (selectedNewsletterGroupingId) {
            return newsletterList?.find(
                (nl) => nl.groupingId === selectedNewsletterGroupingId
            ).isResponsive;
        }
    }, [selectedNewsletterGroupingId, newsletterList]);

    /**
     * different columns display based on newsletter type
     * @type {TableHeadColumn[]}
     */
    const tableHeadCells = isResponsive
        ? RESPONSIVE_NEWSLETTERS_HEAD_CELLS
        : OTHER_NEWSLETTERS_HEAD_CELLS;

    /**
     * apply sorting and pagination to the recipients list
     * @type {array}
     */
    const displayedRecipients = useMemo(() => {
        if (!recipients?.length) {
            return [];
        }

        return getDisplayedItems({
            items: recipients,
            orderBy,
            order,
            page: currentPage,
        });
    }, [recipients, currentPage, order, orderBy]);

    return (
        <MyProgramLayoutContainer title="Newsletters & Recipients">
            <PRPageContentContainer
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <Typography variant="h1" component="h1">
                        Newsletters & Recipients
                    </Typography>
                    <ExportLink
                        url={`/com.publicrelay.www/prrestservices/client/${clientId}/myprogram/newsletter/${selectedNewsletterGroupingId}/export`}
                        disabled={!selectedNewsletterGroupingId}
                    />
                </Box>
                <ManageButton
                    isInternalClientUser={isInternalClientUser}
                    handleClick={handleManageClick}
                    text="Manage Newsletters"
                    disabled={!newsletterList?.length}
                />
            </PRPageContentContainer>

            <PRPageContentContainer mb={2}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <PRSelect
                            label="Select Newsletter"
                            options={newsletterSelectOptions}
                            formControlProps={{
                                disabled: !newsletterSelectOptions.length,
                                sx: { width: "300px", mb: 1 },
                            }}
                            selectProps={{
                                name: "newsletter",
                                onChange: handleNewsletterChange,
                                value: selectedNewsletterGroupingId,
                            }}
                        />
                    </Box>

                    <PRPagination
                        pageSize={MY_PROGRAM_PAGE_SIZE}
                        resultCount={recipients?.length || 0}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                    />
                </Box>
                <NewsletterDeadlineAndSender
                    deadlineList={deadlineList}
                    distributionListSender={distributionListSender}
                />
            </PRPageContentContainer>

            <Table
                sx={{
                    minWidth: "100%",
                }}
                stickyHeader
            >
                <PRTableHead
                    columns={tableHeadCells}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                />
                {!isLoading && (
                    <TableBody>
                        {!newsletterSelectOptions.length ? (
                            <NoResults message={NO_NEWSLETTERS_MESSAGE} />
                        ) : !recipients?.length ? (
                            <NoResults message={NO_RECIPIENTS_MESSAGE} />
                        ) : isResponsive ? (
                            displayedRecipients.map((recipient) => (
                                <TableRow key={recipient.email}>
                                    <TableCell>{recipient.email}</TableCell>
                                    <CheckIconTableCell
                                        showCheckIcon={recipient.fullTextFlag}
                                    />
                                    <CheckIconTableCell
                                        showCheckIcon={recipient.excerptFlag}
                                    />
                                    <CheckIconTableCell
                                        showCheckIcon={
                                            recipient.preapprovalFlag
                                        }
                                    />
                                    <CheckIconTableCell
                                        showCheckIcon={
                                            recipient.showMetricsFlag
                                        }
                                    />
                                </TableRow>
                            ))
                        ) : (
                            displayedRecipients.map((recipient) => (
                                <TableRow key={recipient.contactId}>
                                    <TableCell>{recipient.email}</TableCell>
                                    <CheckIconTableCell
                                        showCheckIcon={
                                            recipient.preapprovalFlag
                                        }
                                    />
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                )}
            </Table>

            {manageDialogOpen && (
                <ManageVisibleItems
                    open={true}
                    items={draftNewslettersList}
                    handleDialogSubmit={handleDialogSubmit}
                    setItems={setDraftNewslettersList}
                    handleClose={handleDialogClose}
                    idLabel="groupingId"
                    type="Newsletters"
                >
                    Choose which newsletters to show on this page. These
                    settings will not affect which newsletters appear elsewhere
                    within PublicRelay systems. Visit AN-06a for additional
                    newsletter settings.
                </ManageVisibleItems>
            )}
        </MyProgramLayoutContainer>
    );
};

export default CL01Newsletters;
