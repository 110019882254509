import usePageIdentifier from "../../components/navigation/usePageIdentifier";
import MyProgramLayoutContainer from "../../components/myProgram/MyProgramLayoutContainer";
import { PRPageContentContainer } from "../../components/styled/Container";
import Typography from "@mui/material/Typography";
import PRSelect from "../../components/shared/PRSelect";
import { FEEDBACK_CATEGORY_OPTIONS } from "../../components/myProgram/constants";
import { useState } from "react";
import { PRTextField } from "../../components/shared/PRInputs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { sendFeedbackToAnalyst } from "@publicrelay/service/dist/client/myProgram";
import { getDefaultErrorHandler } from "../../util/services";
import useNotification from "../../hooks/useNotification";

/**
 * allow client user to send feedback to the email address configured on AD02
 * appears as a nav item in My Program
 * subject, comment, and category are required
 * @param {string} pageIdentifier
 * @returns {JSX.Element}
 * @constructor
 */
const CL01Feedback = ({ pageIdentifier }) => {
    usePageIdentifier(pageIdentifier);

    const { notify } = useNotification();

    const [category, setCategory] = useState("");

    const [subject, setSubject] = useState("");

    const [comment, setComment] = useState("");

    /**
     * update category state
     * @param target
     */
    const handleCategoryChange = ({ target }) => {
        setCategory(target.value);
    };

    /**
     * update subject state
     * @param target
     */
    const handleSubjectChange = ({ target }) => {
        setSubject(target.value);
    };

    /**
     * update comment state
     * @param target
     */
    const handleCommentChange = ({ target }) => {
        setComment(target.value);
    };

    /**
     * validate fields and call service to send email via BE
     */
    const handleSubmit = () => {
        if (!subject.trim() || !comment.trim() || !category) {
            notify(
                "You must select a category and write both a subject and a message.",
                "error"
            );
            return;
        }

        sendFeedbackToAnalyst({ category, subject, comment })
            .then(() => {
                notify(
                    "Message sent successfully. Please allow up to 24 hours for a response.",
                    "success"
                );

                setComment("");
                setSubject("");
                setCategory("");
            })
            .catch(getDefaultErrorHandler("sendFeedbackToAnalyst"));
    };

    return (
        <MyProgramLayoutContainer title="Feedback & Questions">
            <PRPageContentContainer>
                <Typography variant="h1" component="h1" mb={2}>
                    Feedback & Questions
                </Typography>
                <Typography mb={3}>
                    Use the contact form below to ask your analyst questions,
                    request program changes, or provide general feedback.
                </Typography>

                <Box mb={3} width="50%">
                    <PRSelect
                        label="Select Category"
                        options={FEEDBACK_CATEGORY_OPTIONS}
                        formControlProps={{
                            sx: { width: "205px" },
                            required: true,
                        }}
                        selectProps={{
                            name: "category",
                            onChange: handleCategoryChange,
                            value: category,
                        }}
                    />
                </Box>

                <Box mb={3} width="50%">
                    <PRTextField
                        formControlConfig={{ fullWidth: true }}
                        label="Subject"
                        type="text"
                        required={true}
                        inputConfig={{
                            name: "subject",
                            value: subject,
                            onChange: handleSubjectChange,
                            inputProps: {
                                maxLength: 100,
                                placeholder: "Give your message a subject...",
                            },
                        }}
                    />
                </Box>

                <Box mb={3} width="50%">
                    <PRTextField
                        formControlConfig={{ fullWidth: true }}
                        type="text"
                        required={true}
                        label="Message"
                        inputConfig={{
                            multiline: true,
                            rows: 10,
                            name: "comment",
                            value: comment,
                            onChange: handleCommentChange,
                            inputProps: {
                                maxLength: 1000,
                                placeholder: "Write your message...",
                            },
                        }}
                    />
                </Box>
                <Button variant="contained" onClick={handleSubmit}>
                    Submit
                </Button>
            </PRPageContentContainer>
        </MyProgramLayoutContainer>
    );
};

export default CL01Feedback;
