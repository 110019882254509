import { createTheme } from "@mui/material/styles";

const lightTextColor = "#ecf0f1";

const darkBackgroundColor = "#393e46";

let theme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#54b7fa",
            contrastText: lightTextColor,
        },
        secondary: {
            main: "#7c828b",
            contrastText: lightTextColor,
        },
        components: {
            input: "#778292",
            text: "#ffffff",
        },
        background: {
            default: "#434953",
            container: darkBackgroundColor,
            paper: "#434953",
            appBar: darkBackgroundColor,
            tableHead: "#2f3239",
            activeTab: darkBackgroundColor,
            accordionSummary: "#2f3239",
            focusedOuterGroup: "#384452",
            activeNavItem: "#2f3239",
        },
        text: {
            primary: lightTextColor,
            disabled: "#999",
        },
        error: {
            contrastText: "#fff",
            dark: "#e73055", //CF6679
            light: "#ee6f88",
            main: "#e73055",
        },
        success: {
            contrastText: "#fff",
            dark: "#1b5e20", //CF6679
            light: "#00C189",
            main: "#2e7d32",
        },
        divider: "#545454",
        lightDivider: "#d0d0d0",
    },
    typography: {
        fontSize: 14,
        fontFamily: ["Lato", "Arial", "sans-serif"].join(","),
    },
    shape: {
        borderRadius: 4,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1382,
            xl: 1382,
        },
    },
    header: {
        maxHeight: 81,
    },
    footer: {
        height: 48, // from MUI toolbar "dense"
    },
    pageContainer: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    fusioncharts: {
        red: "#ff5850",
        green: "#00b682",
        grey: "#d0d0d0",
        blue: "#367CAB",
        fontColor: lightTextColor,
        fontFamily: "Lato, Arial, sans-serif",
        fontSize: 13,
        messageFontSize: 14,
        bgColor: darkBackgroundColor,
        axisColor: "#778292",
    },
});

// allows for usage of values defined in theme above
// https://mui.com/material-ui/customization/theming/#api
theme = createTheme(theme, {
    typography: {
        h1: {
            fontSize: theme.typography.fontSize * 2,
            fontWeight: 400,
        },
        h2: {
            fontSize: theme.typography.fontSize * 1.6,
            fontWeight: 400,
        },
        h3: {
            fontSize: theme.typography.fontSize * 1.4,
            fontWeight: 400,
        },
        body1: { fontSize: theme.typography.fontSize },
    },
    components: {
        // Name of the component
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // defaults from dojo client
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    lineHeight: "1.42857",
                    letterSpacing: "normal",
                },
            },
        },
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No ripple, on the whole application
            },
        },
        // Name of the component
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        opacity: 0.5,
                        color: "#ffffff",
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
        },
        // Name of the component
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textTransform: "uppercase",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                noOptions: {
                    color: theme.palette.text.primary,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.components.input,
                    color: theme.palette.components.text,
                },
                adornedEnd: {
                    button: {
                        svg: {
                            color: theme.palette.components.text,
                        },
                    },
                },
                input: {
                    "::placeholder": {
                        color: "#ffffff",
                        opacity: 0.7,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.components.text,
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    fontWeight: "bolder",
                    fontStyle: "italic",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    svg: {
                        color: "white",
                    },
                },
            },
        },
        // Name of the component
        MuiTable: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    backgroundColor: theme.palette.background.appBar,
                },
            },
        },
        // Name of the component
        MuiTableRow: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    "&:hover": {
                        // Set hover color
                        backgroundColor: `${theme.palette.background.default} !important`,
                    },
                },
            },
        },
        // Name of the component
        MuiTableCell: {
            // ported to match dojo
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    padding: "1rem",
                    borderBottom: `2px solid ${theme.palette.background.default}`,
                },
                // Name of the slot
                head: {
                    // Some CSS
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    backgroundColor: theme.palette.background.tableHead,
                    textTransform: "uppercase",
                    fontWeight: 400,
                    "&[aria-sort]": {
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: theme.zIndex.drawer + 1,
                    color: "#fff",
                },
            },
        },
        // Name of the component
        MuiTableSortLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    "&:hover": {
                        color: theme.palette.primary.main,
                    },
                    "&:focus": { color: theme.palette.primary.main },
                    "&.Mui-active": {
                        color: theme.palette.primary.main,
                        "&:hover": { color: theme.palette.primary.main },
                    },
                },
                icon: {
                    color: `${theme.palette.primary.main}!important`,
                },
            },
        },
        // Name of the component
        MuiAppBar: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    backgroundColor: theme.palette.background.appBar,
                },
            },
        },
        // Name of the component
        MuiLink: {
            styleOverrides: {
                // Name of the slot
                root: {
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                        color: theme.palette.text.primary,
                    },
                    "&[disabled]": {
                        color: theme.palette.text.disabled,
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none",
                        },
                    },
                },
                button: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#778292",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#ffffff",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#778292",
                },
            },
        },
        // Name of the component
        MuiContainer: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // match the dojo side exactly. mui defaults to 24px
                    paddingLeft: `${theme.pageContainer.paddingLeft}px !important`,
                    paddingRight: `${theme.pageContainer.paddingRight}px !important`,
                },
            },
        },
        // Name of the component
        MuiFormHelperText: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: theme.palette.text.primary,
                    "&.Mui-error": {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
        // Name of the component
        MuiAccordion: {
            styleOverrides: {
                //Name of the slot
                root: {
                    "&:before": {
                        backgroundColor: "transparent",
                    },
                },
                // Name of the slot
                region: {
                    backgroundColor: theme.palette.background.container,
                },
            },
        },
        // Name of the component
        MuiAccordionSummary: {
            styleOverrides: {
                // Name of the slot
                root: {
                    backgroundColor: theme.palette.background.accordionSummary,
                },
                expandIconWrapper: {
                    color: theme.palette.text.primary,
                },
            },
        },
        // Name of the component
        MuiStepLabel: {
            styleOverrides: {
                // Name of the slot
                labelContainer: {
                    color: theme.palette.text.primary,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                // Name of the slot
                label: {
                    fontSize: theme.typography.fontSize,
                },
            },
        },
        // Name of the component
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                // Name of the slot
                button: {
                    color: theme.palette.primary.main,
                },
            },
        },
        // Name of the component
        MuiCalendarPicker: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiTypography-root": {
                        color: theme.palette.secondary.main,
                    },
                },
            },
        },
        // Name of the component
        MuiDayCalendar: {
            styleOverrides: {
                // Name of the slot
                weekDayLabel: { color: theme.palette.text.primary },
            },
        },

        // Name of the component
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.fontSize,
                },
                // Name of the slot
                li: {
                    color: theme.palette.text.primary,
                },
                separator: {
                    color: theme.palette.text.primary,
                },
            },
        },

        // Name of the component
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    "&[disabled]": {
                        color: theme.palette.text.disabled,
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none",
                        },
                    },
                },
            },
        },

        // Name of the component
        MuiSwitch: {
            styleOverrides: {
                // Name of the slot
                switchBase: {
                    color: theme.palette.text.primary,
                },
            },
        },
    },
});

export default theme;
