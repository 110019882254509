import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

export const name = "services"; // this will be stored at state.services

const servicesSlice = createSlice({
    name: name,
    initialState: {
        serviceErrors: {},
    },
    reducers: {
        setServiceError(state, { payload }) {
            const { serviceKey, error } = payload;

            // );
            state.serviceErrors[serviceKey] = error;
        },
        clearServiceError(state, { payload }) {
            const { serviceKey } = payload;
            delete state.serviceErrors[serviceKey];
        },
        clearServiceErrors(state) {
            state.serviceErrors = {};
        },
    },
});

/**
 * Select the service errors available
 */
export const selectServiceErrors = createSelector(
    (state) => state[name],
    (services) => services.serviceErrors
);

/**
 * Select the service error for a specific service
 */
export const selectServiceError = (key) => {
    return createSelector(selectServiceErrors, (errors) => errors[key]);
};

export const { setServiceError, clearServiceError, clearServiceErrors } =
    servicesSlice.actions;

export default servicesSlice.reducer;
