import { combineReducers } from "redux";
import services, { name as servicesPath } from "./slices/serviceSlice";
import liveStream, { name as liveStreamPath } from "./slices/liveStreamSlice";
import languages, { name as languagesPath } from "./slices/languageSlice";
import geography, { name as geographyPath } from "./slices/geographySlice";
import system, { name as systemPath } from "./slices/systemSlice";
import tags, { name as tagsPath } from "./slices/tagsSlice";
import myProgram, { name as myProgramPath } from "./slices/myProgramSlice";
import influencers, {
    name as influencersPath,
} from "./slices/influencersSlice";

export default combineReducers({
    [geographyPath]: geography,
    [influencersPath]: influencers,
    [languagesPath]: languages,
    [liveStreamPath]: liveStream,
    [servicesPath]: services,
    [systemPath]: system,
    [tagsPath]: tags,
    [myProgramPath]: myProgram,
});
