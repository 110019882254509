import NotistackContent from "./NotistackContent";

/**
 * get a method to show PR notistack content with given params.NICOLAI TODO:
 *  MAY BE DEPRECATED IF WE CAN SUCCESSFULLY
 * PUBLISH COMPONENT LIBRARY
 * @param enqueueSnackbar
 * @param options
 * @returns {(function(string, string=, node): void)|*}
 */
export const showNotificationBuilder =
    (enqueueSnackbar, options) =>
    (message, severity = "success", children) => {
        enqueueSnackbar(message, {
            content: (key, message) => (
                <NotistackContent
                    id={key}
                    message={message}
                    severity={severity}
                    children={children}
                />
            ),
            ...options,
        });
    };
