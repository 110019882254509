/**
 * format configuration for the Stacks in the Cards on dashboard Overview
 * @type {{alignItems: string, sx: {height: number}, justifyContent: string, direction: string}}
 */
export const CARD_STACK_CONTROLS = {
    direction: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    sx: { height: 220 },
};

/**
 * we can only have this many contacts
 * @type {number}
 */
export const MAX_CONTACTS = 4;

/**
 * empty contact for system help
 * @type {MyProgramContact}
 */
export const BLANK_CONTACT = {
    fullName: "",
    titleDescription: "",
    email: "",
};

/**
 * default PR contact. this is shown when no other contacts have been saved
 * @type {MyProgramContact}
 */
export const DEFAULT_PR_CONTACT = {
    fullName: "PublicRelay Team",
    titleDescription: "General Questions",
    email: "help@publicrelay.com",
};

/**
 * map labels to keys for Settings page display
 * @type {{
 * tagsEnabled: string,
 * usersEnabled: string,
 * newslettersEnabled: string,
 * alertsEnabled: string,
 * reportsEnabled: string,
 * outletListsEnabled: string
 * }}
 */
export const SETTINGS_CHECKBOX_LABELS_MAP = {
    reportsEnabled: "Reports",
    newslettersEnabled: "Newsletters & Recipients",
    alertsEnabled: "Alerts & Recipients",
    tagsEnabled: "Tag Structure",
    outletListsEnabled: "Outlet Lists",
    usersEnabled: "Users",
};

/**
 * configure table header columns for the CL01Reports page
 * @type {TableHeadColumn[]}
 */
export const REPORTS_HEAD_CELLS = [
    {
        id: "dateRange",
        label: "Date",
        sortable: true,
        align: "left",
    },
    {
        id: "displayName",
        label: "Name",
        sortable: true,
        align: "left",
        sx: { minWidth: "300px" },
    },
    {
        id: "reportType",
        label: "Type",
        sortable: true,
        align: "left",
        sx: { width: "180px" },
    },
    {
        id: "cadence",
        label: "Cadence",
        sortable: true,
        align: "left",
        sx: { width: "200px" },
    },
];

/**
 * no results message
 * @type {string}
 */
export const NO_REPORTS_MESSAGE =
    "Sorry, there are no reports available. Please contact your analyst if you have questions.";

/**
 * category options available on My Program feedback page
 * @type {{label: string, value: string}[]}
 */
export const FEEDBACK_CATEGORY_OPTIONS = [
    { label: "Reports", value: "Reports" },
    { label: "Newsletters & Recipients", value: "Newsletters & Recipients" },
    { label: "Alerts & Recipients", value: "Alerts & Recipients" },
    { label: "Tag Structure", value: "Tag Structure" },
    { label: "Outlet Lists", value: "Outlet Lists" },
    { label: "Users", value: "Users" },
    { label: "Other", value: "Other" },
];

/**
 * @typedef {{
 * reportType: string,
 * reportId?: number,
 * dateRange: string,
 * displayName: string,
 * tableauUrl?: string,
 * cadence: string,
 * uploadedFile?: File
 * }} MyProgramReport
 *
 * @type MyProgramReport
 */
export const ADD_EDIT_DIALOG_DEFAULT_STATE = {
    reportType: "",
    displayName: "",
    tableauUrl: "",
    dateRange: "",
    reportId: undefined,
    uploadedFile: undefined,
    cadence: "",
    filename: "",
};

/**
 * options for report types
 * @type {{label: string, value: string}[]}
 */
export const REPORT_TYPE_OPTIONS = [
    { label: "Cloud Report", value: "Cloud Report" },
    { label: "Local File", value: "Local File" },
];

/**
 * options for report cadence
 * @type {{label: string, value: string}[]}
 */
export const CADENCE_OPTIONS = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-Weekly", value: "Bi-Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Bi-Monthly", value: "Bi-Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Semi-Annual", value: "Semi-Annual" },
    { label: "Annual", value: "Annual" },
    { label: "Custom", value: "Custom" },
    { label: "Ad-Hoc", value: "Ad-Hoc" },
];

/**
 * configure table header columns for the CL01Newsletters page - Responsive case
 * @type {TableHeadColumn[]}
 */
export const RESPONSIVE_NEWSLETTERS_HEAD_CELLS = [
    {
        id: "email",
        label: "Email",
        sortable: true,
        align: "left",
        sx: { minWidth: "300px" },
    },
    {
        id: "fullTextFlag",
        label: "Full Text",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
    {
        id: "excerptFlag",
        label: "Excerpt-Only",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
    {
        id: "preapprovalFlag",
        label: "Pre-Approval",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
    {
        id: "showMetricsFlag",
        label: "Metrics",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
];

/**
 * configure table header columns for the CL01Newsletters page - non Responsive case
 * @type {TableHeadColumn[]}
 */
export const OTHER_NEWSLETTERS_HEAD_CELLS = [
    {
        id: "email",
        label: "Email",
        sortable: true,
        align: "left",
        sx: { width: "50%" },
    },

    {
        id: "preapprovalFlag",
        label: "Pre-Approval",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
];

/**
 * @type {string}
 */
export const NO_NEWSLETTERS_MESSAGE =
    "Sorry, there are no newsletters available. Please contact your analyst if you have questions.";

/**
 * @type {string}
 */
export const NO_RECIPIENTS_MESSAGE =
    "Sorry, there are no recipients for this newsletter. Please contact your analyst if you have questions.";

/**
 * displayed row count for My Program pages
 * @type {number}
 */
export const MY_PROGRAM_PAGE_SIZE = 20;

/**
 * configure table header columns for the CL01Alerts page
 * @type {TableHeadColumn[]}
 */
export const ALERTS_HEAD_CELLS = [
    {
        id: "email",
        label: "Email",
        sortable: true,
        align: "left",
    },
];

/**
 * @type {string}
 */
export const NO_ALERTS_MESSAGE =
    "Sorry, there are no alerts available. Please contact your analyst if you have questions.";

/**
 * @type {string}
 */
export const NO_ALERT_RECIPIENTS_MESSAGE =
    "Sorry, there are no recipients for this alert. Please contact your analyst if you have questions.";

/**
 * configure table header columns for the CL01Users page
 * @type {TableHeadColumn[]}
 */
export const USERS_HEAD_CELLS = [
    {
        id: "fullName",
        label: "Name",
        sortable: true,
        align: "left",
    },
    {
        id: "email",
        label: "Email",
        sortable: true,
        align: "left",
    },
    {
        id: "username",
        label: "Username",
        sortable: true,
        align: "left",
    },
    {
        id: "fullTextFlag",
        label: "Full Text",
        sortable: true,
        align: "center",
        defaultDesc: true,
    },
    {
        id: "lastActivityDateLong",
        label: "Last Activity",
        sortable: true,
        align: "right",
        defaultDesc: true,
    },
];

/**
 * @type {string}
 */
export const NO_USERS_MESSAGE = "Sorry, there are no users available.";

/**
 * configure table header columns for the CL01OutletLists page
 * @type {TableHeadColumn[]}
 */
export const OL_HEAD_CELLS = [
    {
        id: "name",
        label: "Outlet",
        sortable: true,
        align: "left",
    },
    {
        id: "url",
        label: "Url",
        sortable: true,
        align: "left",
    },
    {
        id: "outletPower",
        label: "Outlet Power",
        sortable: true,
        defaultDesc: true,
    },
    {
        id: "contact",
        label: "Contact Information",
        sortable: false,
    },
];

/**
 * @type {string}
 */
export const NO_OL_MESSAGE =
    "Sorry, there are no outlet lists available. Please contact your analyst if you have questions.";

/**
 * @type {string}
 */
export const NO_OUTLETS_IN_OL_MESSAGE =
    "Sorry, there are no outlets in this outlet list. Please contact your analyst if you have questions.";

/**
 * @type {string}
 */
export const NO_TAGS_MESSAGE =
    "Sorry, there are no tag groups available. Please contact your analyst if you have questions.";
