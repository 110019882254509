export const INVALID_EMAIL = "Enter a valid email address.";

export const INVALID_URL = "Enter a valid URL.";

export const INVALID_RANGE = "Enter a valid range.";

export const REQUIRED_FIELD = "This field is required.";

export const ORDER_DIRECTION = Object.freeze({
    ASC: "asc",
    DESC: "desc",
});

/**
 * split a string at commas, semicolons, newlines, or returns
 * @param {string} text
 * @returns {string[]}
 */
export const splitText = (text) => {
    if (text) {
        const lines = text.split(/[\n\r\s , ;]+/);

        return lines.map((line) => {
            return line.trim();
        });
    }

    return [];
};
