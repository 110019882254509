import { useState } from "react";
import { ORDER_DIRECTION } from "../util/strings";

/**
 * hook to manage state for MUI table head sorts
 */
export const useTableSort = () => {
    /**
     * maintain state - which way is sorted column ordered? asc / desc
     */
    const [order, setOrder] = useState(ORDER_DIRECTION.ASC);

    /**
     * maintain state - which column are we sorting on?
     */
    const [orderBy, setOrderBy] = useState(null);

    /**
     * via table head click to sort
     * @param {object} event
     * @param {string} property - column we are sorting on
     * @param {boolean} [defaultDesc]
     */
    const handleRequestSort = (event, property, defaultDesc) => {
        let isAsc;

        if (orderBy !== property) {
            isAsc = defaultDesc === true;
        } else {
            isAsc = order === ORDER_DIRECTION.ASC;
        }

        setOrder(isAsc ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC);

        setOrderBy(property);
    };

    const resetSort = () => {
        setOrder(ORDER_DIRECTION.ASC);

        setOrderBy(null);
    };

    return { order, orderBy, handleRequestSort, resetSort };
};
