import {
    CL31_PATH,
    createAbsoluteClientAppUrl,
    getCL10Url,
    getCL27Url,
    getCL30Url,
} from "../../util/urls";

export const CONTACTS_MENU_NAME = "contactsMenu";

export const CONTENT_MENU_NAME = "contentMenu";

export const NEWSLETTER_MENU_NAME = "newslettersMenu";

export const ACCOUNT_MENU_NAME = "accountMenu";

export const DATA_MENU_NAME = "dataMenu";

/**
 * mapping menus (by navPopoverName) to a list of the urls that fall under them
 * @type {object}
 */
export const MENUS_URLS_MAPPING = {
    [CONTACTS_MENU_NAME]: [getCL27Url()],
    [CONTENT_MENU_NAME]: [getCL10Url()],
    [DATA_MENU_NAME]: [getCL30Url(), createAbsoluteClientAppUrl(CL31_PATH)],
};
