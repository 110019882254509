import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormLabel from "@mui/material/FormLabel";

/**
 * styled components used throughout the dashboard pages
 */

export const ProgramContainerBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export const ProgramSidePanelContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.container,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: "sticky",
    top: 0,
}));

export const ProgramNavItem = styled(ListItem)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.background.activeNavItem,
    },
    "& .active": {
        pointerEvents: "none",
        backgroundColor: theme.palette.background.activeNavItem,
    },
    "& .MuiListItemIcon-root": {
        color: theme.palette.primary.contrastText,
    },
}));

export const ProgramChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    "& .MuiChip-avatar": {
        color: theme.palette.primary.contrastText,
    },
}));

export const ProgramButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    "&.Mui-disabled": {
        backgroundColor: "transparent",
    },
}));

export const SupportCard = styled(Card)(({ theme }) => ({
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    width: "25%",
    borderColor: theme.palette.primary.main,
    textAlign: "center",
}));

export const LegendFormLabel = styled(FormLabel)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    "&.Mui-focused": {
        color: theme.palette.primary.contrastText,
    },
}));
