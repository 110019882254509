import { useState } from "react";

/**
 * hook to manage current page for controlled PRPagination
 * @returns {{handlePagination: function, setCurrentPage: function, currentPage: number}}
 */
const useManagePagination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    /**
     * called when user clicks prev/next in pagination component
     * @param {number} page - we go here
     */
    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    return { currentPage, setCurrentPage, handlePagination };
};
export default useManagePagination;
