import { createRef } from "react";
import { SnackbarProvider } from "notistack";
import Grow from "@mui/material/Grow";

/**
 * NICOLAI TODO:
 * MAY BE DEPRECATED IF WE CAN SUCCESSFULLY
 * PUBLISH COMPONENT LIBRARY
 */
const AlertProvider = ({ children }) => {
    const notistackRef = createRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: "center",
                horizontal: "top",
            }}
            ref={notistackRef}
            action={(key) => <div onClick={onClickDismiss(key)}>Close</div>}
            onClose={(event, reason, key) => {
                if (reason === "clickaway") {
                    notistackRef.current.closeSnackbar(key);
                }
            }}
            TransitionComponent={Grow}
        >
            {children}
        </SnackbarProvider>
    );
};

export default AlertProvider;
