import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { NavLink, useNavigate } from "react-router-dom";
import {
    isClientRawFeedEnabled,
    isClientTypeDashboard,
    isUploadAllowed,
    isClientTwitterFeedEnabled,
    isClientPredictiveEnabled,
    isClientContactDatabaseEnabled,
    isCommandCenterEnabled,
    hasVisiblePublications,
    hasVisibleNewsfeeds,
} from "../../util/client";
import HeaderLogo from "./HeaderLogo";
import {
    getCL08Url,
    getCL28Url,
    getCL10Url,
    getCL12Url,
    getCL14Url,
    getCL24Url,
    getCL02Url,
    getCL26Url,
    getCL07Url,
    getCL06Url,
    getCL23Url,
    getCL18Url,
    getCL17Url,
    getCL27Url,
    getCL30Url,
    getCL25Url,
    CL01_BASE,
} from "../../util/urls";
import {
    getLastViewedLiveStreamBucket,
    getLastViewedNewsFeedId,
    getLastViewedNewsletterId,
    getSelectedAuthorSubNavUrl,
    getSelectedCoverageSubNav,
    getSelectedDashboardSubNav,
    getSelectedOutletSubNavUrl,
    getSelectedTwitterHandlesSubNavUrl,
    getTwitterCoverageSubNavUrl,
} from "../../util/users";
import DropdownMenuItem from "./DropdownMenuItem";
import { HeaderMenuItem, HeaderMenuItemLink, HeaderMenuLink } from "./styled";
import LogoutLink from "./LogoutLink";
import {
    ACCOUNT_MENU_NAME,
    CONTACTS_MENU_NAME,
    CONTENT_MENU_NAME,
    DATA_MENU_NAME,
    NEWSLETTER_MENU_NAME,
} from "./constants";
import Search from "./Search";
import { isEXEnvironment } from "../../util/environment";

/**
 * standard header for PR client side
 * logic to display navigation items depending on client configuration
 * IMPORTANT! in order to highlight the selected dropdown item (corresponding to the page user is on) you must use
 * NavLink component, allowing ReactRouter to do the work of determining which item is "active"
 * @returns {JSX.Element}
 * @constructor
 */
const Header = () => {
    const navigate = useNavigate();

    /**
     * EX doesn't get the Explore link
     * @type {boolean}
     */
    const isEx = isEXEnvironment();

    /**
     * clients might be dashboard only; note no upload for dashboard clients
     * @type {boolean}
     */
    const isTypeDashboard = isClientTypeDashboard();

    /**
     * is the user allowed to upload content via CL18?
     * @type {Boolean}
     */
    const uploadAllowed = isUploadAllowed();

    /**
     * does the client have live stream enabled?
     * @type {boolean}
     */
    const rawFeedEnabled = isClientRawFeedEnabled();

    /**
     * does the client have twitter content?
     * @type {boolean}
     */
    const twitterEnabled = isClientTwitterFeedEnabled();

    /**
     * is predictive enabled for the client?
     * @type {boolean}
     */
    const predictiveEnabled = isClientPredictiveEnabled();

    /**
     * does the client have media db enabled?
     * @type {boolean}
     */
    const contactDbEnabled = isClientContactDatabaseEnabled();

    /**
     * does the client have command center enabled?
     * @type {boolean}
     */
    const commandCenterEnabled = isCommandCenterEnabled();

    /**
     * are there any newsletters configured to show on the client side?
     * @type {boolean}
     */
    const visiblePublications = hasVisiblePublications();

    /**
     * are there any newsfeeds configured to show on the client side?
     * @type {boolean}
     */
    const visibleNewsFeeds = hasVisibleNewsfeeds();

    /**
     * we want to return the user to the previously visited CL08 saved view, if any
     * TODO: move to onClick handler instead like dojo side? needs further investigation
     * @type {string}
     */
    const newsLink = getSelectedCoverageSubNav() || getCL08Url();

    /**
     * handle click for live stream; send user to most recently viewed LS page (if any)
     * @param {object} evt
     */
    const handleLiveStreamClick = (evt) => {
        evt.preventDefault();

        // take user to most recently stored view
        navigate(getCL10Url(getLastViewedLiveStreamBucket()));
    };

    return (
        <AppBar position="sticky" elevation={0}>
            <Toolbar variant="dense">
                <Container fixed={true} maxWidth={false}>
                    <Box
                        component="nav"
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingLeft: 2,
                                paddingRight: 2,
                            }}
                        >
                            <HeaderLogo />
                        </Box>
                        {/*using to prop this way ensures the Home tab will show active for all pages under /dashboard (via NavLink)*/}
                        {/*see route config for how the user is directed to overview when the link is clicked*/}
                        <HeaderMenuLink
                            component={NavLink}
                            to={`/client/${CL01_BASE}`}
                        >
                            Home
                        </HeaderMenuLink>

                        <DropdownMenuItem
                            navPopoverName={CONTENT_MENU_NAME}
                            displayName="Content"
                        >
                            <HeaderMenuItem>
                                <HeaderMenuItemLink href={newsLink}>
                                    News
                                </HeaderMenuItemLink>
                            </HeaderMenuItem>
                            {twitterEnabled && (
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        href={
                                            getTwitterCoverageSubNavUrl() ||
                                            getCL28Url()
                                        }
                                    >
                                        X.com
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            )}
                            {rawFeedEnabled && (
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        to={getCL10Url()}
                                        onClick={handleLiveStreamClick}
                                        component={NavLink}
                                    >
                                        Live Stream
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            )}
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            navPopoverName={CONTACTS_MENU_NAME}
                            displayName="Contacts"
                        >
                            <HeaderMenuItem>
                                <HeaderMenuItemLink
                                    href={
                                        getSelectedAuthorSubNavUrl() ||
                                        getCL12Url()
                                    }
                                >
                                    Authors
                                </HeaderMenuItemLink>
                            </HeaderMenuItem>
                            <HeaderMenuItem>
                                <HeaderMenuItemLink
                                    href={
                                        getSelectedOutletSubNavUrl() ||
                                        getCL14Url()
                                    }
                                >
                                    Outlets
                                </HeaderMenuItemLink>
                            </HeaderMenuItem>
                            {twitterEnabled && (
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        href={
                                            getSelectedTwitterHandlesSubNavUrl() ||
                                            getCL24Url()
                                        }
                                    >
                                        Twitter Handles
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            )}
                            {contactDbEnabled && (
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        to={getCL27Url()}
                                        component={NavLink}
                                    >
                                        Discover
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            )}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            navPopoverName={DATA_MENU_NAME}
                            displayName="Data"
                        >
                            <HeaderMenuItem>
                                <HeaderMenuItemLink
                                    href={
                                        getSelectedDashboardSubNav() ||
                                        getCL25Url()
                                    }
                                >
                                    Dashboards
                                </HeaderMenuItemLink>
                            </HeaderMenuItem>
                            <HeaderMenuItem>
                                <HeaderMenuItemLink href={getCL02Url()}>
                                    Charts
                                </HeaderMenuItemLink>
                            </HeaderMenuItem>
                            {!isEx && (
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        to={getCL30Url()}
                                        component={NavLink}
                                    >
                                        Explore
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            )}
                        </DropdownMenuItem>

                        {predictiveEnabled && (
                            <HeaderMenuLink href={getCL26Url()}>
                                Predictive
                            </HeaderMenuLink>
                        )}
                        {visiblePublications && visibleNewsFeeds && (
                            <DropdownMenuItem
                                navPopoverName={NEWSLETTER_MENU_NAME}
                                displayName="Newsletters"
                            >
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        href={getCL07Url(
                                            getLastViewedNewsletterId()
                                        )}
                                    >
                                        Newsletters
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink
                                        href={getCL06Url(
                                            getLastViewedNewsFeedId()
                                        )}
                                    >
                                        News Feed
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                            </DropdownMenuItem>
                        )}
                        {visiblePublications && !visibleNewsFeeds && (
                            <HeaderMenuLink
                                href={getCL07Url(getLastViewedNewsletterId())}
                            >
                                Newsletters
                            </HeaderMenuLink>
                        )}
                        {visibleNewsFeeds && !visiblePublications && (
                            <HeaderMenuLink
                                href={getCL06Url(getLastViewedNewsFeedId())}
                            >
                                Newsletters
                            </HeaderMenuLink>
                        )}

                        <Search
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingLeft: 2,
                                paddingRight: 2,
                                ...(!visiblePublications &&
                                    !visibleNewsFeeds &&
                                    !predictiveEnabled && {
                                        marginLeft: "auto",
                                    }),
                            }}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                marginLeft: "auto",
                            }}
                        >
                            <DropdownMenuItem
                                navPopoverName={ACCOUNT_MENU_NAME}
                                displayName="My Account"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <HeaderMenuItem>
                                    <HeaderMenuItemLink href={getCL17Url()}>
                                        Help
                                    </HeaderMenuItemLink>
                                </HeaderMenuItem>
                                {commandCenterEnabled && (
                                    <HeaderMenuItem>
                                        <HeaderMenuItemLink href={getCL23Url()}>
                                            Command Center
                                        </HeaderMenuItemLink>
                                    </HeaderMenuItem>
                                )}
                                {uploadAllowed && !isTypeDashboard && (
                                    <HeaderMenuItem>
                                        <HeaderMenuItemLink href={getCL18Url()}>
                                            Upload Articles
                                        </HeaderMenuItemLink>
                                    </HeaderMenuItem>
                                )}
                                <HeaderMenuItem>
                                    <LogoutLink />
                                </HeaderMenuItem>
                            </DropdownMenuItem>
                        </Box>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
