import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import ListSubheader from "@mui/material/ListSubheader";
import nextId from "react-id-generator";

export const REQUIRED_FIELD = "This field is required.";

/**
 * Convenience wrappers to create Material Select using standard publicrelay display
 * configuration
 */

/**
 * standard publicrelay Select implemenation
 * @param  {} props
 *
 * @param {Object} [props.formControlProps]
 *        - props passed to FormControl. Can override defaults set here and add any props as needed.
 *        - eg "fullWidth", "required"
 *
 * @param {Object} [props.inputLabelProps]
 *        - props passed to InputLabel. Can override defaults set here and add any props as needed
 *
 * @param {Ojbect} [props.selectProps]
 *        - props passed to Select. Can override defaults set here and add any props as needed.
 *        - eg "value" or "onChange"
 *
 * @param {Array} [props.options] - list of options as objects. each option format {label, value}. output as MenuItems. You can parse in a disabled flag on each option
 *                                  That will register if you are making selections as disabling selections as they are chosen from the dropdown. Defaults to false.
 *
 * @param {Array} [props.componentOptions] - in case you want to parse your options first, you can provide a list of components
 *                                         - to be output as options. can be used in place of props.options,
 *                                         - or with props.options, but this list is output first.
 *                                         - this can be used when the options are more complex
 *                                         - eg, includes ListSubheader, etc. see MediaTypeSelect as an example
 *
 * @param {String} [props.id] - id associated with this Select
 *
 * @param {String} [props.label] - the label for the Select
 *
 * @return {React Componenet}
 */
const PRSelect = (props) => {
    const {
        formControlProps = {},
        inputLabelProps = {},
        selectProps = {},
        shouldValidateOnBlur = false,
        shouldValidateOnChange = false,
        id = nextId("select_"),
        options,
        componentOptions,
        label,
        forceValidation,
        register,
    } = props;

    /**
     * handle internal error state
     */
    const [isInternalError, setIsInternalError] = useState();

    /**
     * handle missing/invalid messaging
     */
    const [invalidMessage, setInvalidMessage] = useState();

    /**
     * check for various states, update error display/messaging
     * useCallback hook b/c we are using validate as a dependacy in useEffect hook
     * @param  {String} value - input value
     * @param {Boolean} showMessage - when true we'll display error; otherwise just return isInvalid
     * @return {Boolean} - true if errored, otherwise false
     */
    const validate = useCallback(
        (value, showMessage) => {
            let isInvalid = false;

            let message;

            if (formControlProps.required && !value) {
                isInvalid = true;

                message = REQUIRED_FIELD;
            }

            if (showMessage) {
                setInvalidMessage(message);

                setIsInternalError(isInvalid);
            }

            return isInvalid;
        },
        [formControlProps.required]
    );

    /**
     * when props.shouldValidateOnBlur is true, this method overrides any onBlur passed via
     * props.selectProps. handles error display (missing/invalid), then calls props.selectProps.onBlur
     * with the evt
     * @param  {Object} evt
     */
    const validateOnBlur = (evt) => {
        const value = evt.target.value;

        validate(value, true);

        if (selectProps.onBlur) {
            //pass through the onBlur event
            selectProps.onBlur(evt);
        }
    };

    /**
     * hook dependant on props.selectProps.value, so runs everytime a new value for
     * props.selectProps.value is passed to this component (ie, even on intial values)
     * but therefore, in effect (haha), this also corresponds to the onChange event
     * if props.shouldValidateOnChange is true, validate the field and show message if necessary
     */
    useEffect(() => {
        if (shouldValidateOnChange) {
            const value = selectProps.value || "";

            validate(value, true);
        }
    }, [selectProps.value, validate, shouldValidateOnChange]);

    /**
     * hook dependant on props.selectProps.value, so runs everytime a new value for
     * props.selectProps.value is passed to this component (ie, even on intial values)
     * but therefore, in effect (haha), this also corresponds to the onChange event
     * if props.register is passed, we'll call the method to update current validity
     * you must pass a name to the input for this method to work.
     */
    useEffect(() => {
        if (register) {
            if (!selectProps.name) {
                console.warn(
                    "PRSelect: You did not pass a name attribute but are trying to register a field."
                );
                return;
            }

            const value = selectProps.value || "";

            const isValid = validate(value);

            //pass through the current validity
            register({ [selectProps.name]: isValid });
        }
    }, [selectProps.value, selectProps.name, validate, register]);

    /**
     * should we validate this component now?
     */
    useEffect(() => {
        if (forceValidation) {
            const value = selectProps.value || "";

            validate(value, true);
        }
    }, [selectProps.value, forceValidation, validate]);

    return (
        <FormControl
            variant="outlined"
            {...formControlProps}
            {...(isInternalError && { error: isInternalError })}
        >
            {label && (
                <InputLabel id={`${id}_label`} {...inputLabelProps}>
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={`${id}_label`}
                id={id}
                label={label}
                {...selectProps}
                {...(shouldValidateOnBlur && { onBlur: validateOnBlur })}
            >
                {componentOptions}
                {options &&
                    options.map((option, index) =>
                        option.isOptGroup ? (
                            <ListSubheader key={index}>
                                {option.label}
                            </ListSubheader>
                        ) : (
                            <MenuItem
                                key={index}
                                disabled={option.disabled || false}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        )
                    )}
            </Select>
            {props.helperText && (
                <FormHelperText>{props.helperText}</FormHelperText>
            )}
            {invalidMessage && (
                <FormHelperText>{invalidMessage}</FormHelperText>
            )}
        </FormControl>
    );
};

PRSelect.propTypes = {
    formControlProps: PropTypes.object,
    inputLabelProps: PropTypes.object,
    selectProps: PropTypes.object,
    options: PropTypes.array,
    componentOptions: PropTypes.array,
    id: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    shouldValidateOnBlur: PropTypes.bool,
    shouldValidateOnChange: PropTypes.bool,
    forceValidation: PropTypes.bool,
    register: PropTypes.func,
};

export default PRSelect;
