import TableCell from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";

/**
 * TableCell with check icon or empty depending on prop value
 * @param {boolean} showCheckIcon
 * @returns {JSX.Element}
 * @constructor
 */
const CheckIconTableCell = ({ showCheckIcon = false }) => (
    <TableCell align="center">{showCheckIcon && <CheckIcon />}</TableCell>
);

CheckIconTableCell.propTypes = {
    showCheckIcon: PropTypes.bool,
};

export default CheckIconTableCell;
