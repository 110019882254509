import Link from "@mui/material/Link";
import PropTypes from "prop-types";

/**
 * show link for export download
 * @param {string} url - target this location
 * @param {boolean} disabled - should link show as disabled?
 * @returns {JSX.Element}
 * @constructor
 */
const ExportLink = ({ url, disabled = false }) => (
    <Link
        href={url}
        download={true}
        {...(disabled && {
            sx: {
                pointerEvents: "none",
                color: (theme) => theme.palette.text.disabled,
            },
        })}
    >
        Export
    </Link>
);

ExportLink.propTypes = {
    url: PropTypes.string.isRequired,
    disable: PropTypes.bool,
};

export default ExportLink;
