import Typography from "@mui/material/Typography";
import Nav from "./Nav";
import { ProgramSidePanelContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchMyProgramSettings,
    selectMyProgramClientLogoEnabled,
    selectMyProgramClientLogoUrl,
    selectMyProgramSettingsStatus,
} from "../../redux/slices/myProgramSlice";
import { useEffect } from "react";
import { STATUS_VALUE_MAP } from "../../redux/util";
import Logo from "./Logo";

/**
 * display the logo and left nav for "My Program"
 * this component calls the service to get the "My Program" settings data and puts it in Redux for use in the
 * Settings and Support components as well
 * @returns {JSX.Element}
 * @constructor
 */
const ProgramSidePanel = () => {
    const dispatch = useDispatch();

    const status = useSelector(selectMyProgramSettingsStatus);

    const clientLogoUrl = useSelector(selectMyProgramClientLogoUrl);

    const logoEnabled = useSelector(selectMyProgramClientLogoEnabled);

    /**
     * call service to get program settings, support, and image data
     * put in global store so this can be referenced by any of the my program pages
     */
    useEffect(() => {
        if (status === STATUS_VALUE_MAP.IDLE) {
            dispatch(fetchMyProgramSettings());
        }
    }, [status, dispatch]);

    return (
        <ProgramSidePanelContainer>
            {logoEnabled && <Logo clientLogoUrl={clientLogoUrl} />}
            <Typography variant="h2" component="h2" mb={3} textAlign="center">
                My Program
            </Typography>
            <Nav />
        </ProgramSidePanelContainer>
    );
};

export default ProgramSidePanel;
