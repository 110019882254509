import React from "react";
import PropTypes from "prop-types";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dropzone from "react-dropzone";
import { PRDeleteIcon } from "@publicrelay/component-library";
import Input from "@mui/material/Input";

/**
 * Styling the container as we are using a vanilla React dropzone. Material UI, as of 3/23/22 does
 * not support a native dropzone. Can replace when one is developed.
 */
const DropzoneContainer = styled(Box)(({ theme }) => ({
    textAlign: "center",
    padding: 10,
    cursor: "pointer",
    border: 2,
    borderColor: theme.palette.text.primary,
    borderStyle: "dashed",
    borderRadius: 5,
}));

const DropzoneSpan = styled(Box)(() => ({
    fontSize: 1,
    fontWeight: 400,
}));

/**
 * TODO: move to component library
 * React Dropzone for file image uploads. We accept 6 parameters and can add more as more use cases
 * become available. Accept is the type of file permitted, multiple is whether multiple files are
 * accepted or not, showPreviews is a boolean if you want to see the file
 * name or not. onDrop should be a function for when a file is dropped in. onRemovePreview is a function that
 * is able to deselect a dropped file. previewFiles is the current photo that has been DROPPED, not the file that may
 * exist in the database.
 *
 * Note, that there are two prop getters, getInputProps and getRootProps. This is how we bind the properties onto their respective
 * DOM elements. The input and the div that these are bound to handle the logistics of the drag n drop.
 *
 * @param accept
 * @param showPreviews
 * @param onDrop
 * @param onRemovePreview
 * @param multiple
 * @returns {JSX.Element}
 * @constructor
 */
const PRDropzone = ({
    accept,
    showPreviews,
    onDrop,
    onRemovePreview,
    previewFiles = [],
    multiple,
}) => {
    return (
        <Box component="div">
            <Dropzone onDrop={onDrop} accept={accept} multiple={multiple}>
                {({ getRootProps, getInputProps }) => {
                    return (
                        <DropzoneContainer {...getRootProps()}>
                            <Input {...getInputProps()} />
                            <Box component="p">
                                Drag and drop a file here or click
                            </Box>
                            <DropzoneSpan component="span">
                                <CloudUploadIcon fontSize="large" />
                            </DropzoneSpan>
                        </DropzoneContainer>
                    );
                }}
            </Dropzone>

            {showPreviews && (
                <List>
                    {previewFiles.map((file) => (
                        <ListItem sx={{ paddingLeft: 0 }} key={file.name}>
                            <PRDeleteIcon handleClick={onRemovePreview} />
                            <ListItemText primary={file.name} />
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

PRDropzone.propTypes = {
    multiple: PropTypes.bool.isRequired,
    accept: PropTypes.object.isRequired,
    showPreviews: PropTypes.bool.isRequired,
    onDrop: PropTypes.func.isRequired,
    onRemovePreview: PropTypes.func.isRequired,
    previewFiles: PropTypes.array,
};

export default PRDropzone;
