import useAuth from "./useAuth";
import { getLoginUrl } from "../util/urls";

/**
 * Redirect to login page if user is not authenticated
 * see https://reactrouter.com/docs/en/v6/examples/auth
 * @param {React.ReactNode} children
 * @returns {*}
 * @constructor
 */
const RequireAuth = ({ children }) => {
    const user = useAuth();

    if (!user.isAuth) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        //return <Navigate to="/login" state={{ from: location }} replace />;
        window.location = getLoginUrl();
    }

    return children;
};

export default RequireAuth;
