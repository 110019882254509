import * as DOJO_URLS from "./dojoUrls";
import { getAnalystRedirectBase } from "./environment";

/**
 * accept for routing config, please do not import paths directly; to generate urls/links create and
 * then use a method to retrieve the url
 */

/**
 * client app paths
 * unlike analyst, the client app does not have a basename defined at the router level
 * app paths are relative to work with our nested route definitions in react router (initially all under "/client")
 * these will also work with react router Links as the "to" prop (see https://reactrouter.com/docs/en/v6/components/link)
 * Also see createAbsoluteClientAppUrl, useResolvedPath (https://reactrouter.com/docs/en/v6/hooks/use-resolved-path), and
 * resolvePath (https://reactrouter.com/docs/en/v6/utils/resolve-path) provided by react router
 */

const basename = "/client";

/**
 * CL27 relative path
 * @type {string}
 */
export const CL27_PATH = "discover";

/**
 * CL10 relative path
 * @type {string}
 */
export const CL10_PATH = "livestream";

/**
 * CL11 relative path
 * @type {string}
 */
export const CL11_PATH = "livestream/article";

/**
 * CL30 relative path
 * @type {string}
 */
export const CL30_PATH = "explore";

/**
 * CL31 relative path
 * @type {string}
 */
export const CL31_PATH = "tags";

/**
 * CL00 - create / reset password relateive path
 * @type {string}
 */
export const CL00_PATH = "password";

/**
 * 404 relative path
 * @type {string}
 */
export const NOT_FOUND_PATH = "page-not-found";

export const CL01_BASE = "home";

/**
 * CL01 Overview relative path
 * @type {string}
 */
export const CL01_OVERVIEW_PATH = `${CL01_BASE}/overview`;

/**
 * CL01 reports relative path
 * @type {string}
 */
export const CL01_REPORTS_PATH = `${CL01_BASE}/reports`;

/**
 * CL01 newsletters & recipients relative path
 * @type {string}
 */
export const CL01_NEWSLETTERS_PATH = `${CL01_BASE}/newsletters`;

/**
 * CL01 settings relative path
 * @type {string}
 */
export const CL01_SETTINGS_PATH = `${CL01_BASE}/settings`;

/**
 * CL01 feedback relative path
 * @type {string}
 */
export const CL01_FEEDBACK_PATH = `${CL01_BASE}/feedback`;

/**
 * CL01 alerts relative path
 * @type {string}
 */
export const CL01_ALERTS_PATH = `${CL01_BASE}/alerts`;

/**
 * CL01 tags relative path
 * @type {string}
 */
export const CL01_TAGS_PATH = `${CL01_BASE}/tag-structure`;

/**
 * CL01 outlet lists relative path
 * @type {string}
 */
export const CL01_OUTLET_LISTS_PATH = `${CL01_BASE}/outlet-lists`;

/**
 * CL01 users relative path
 * @type {string}
 */
export const CL01_USERS_PATH = `${CL01_BASE}/users`;

/**
 * create an absolute path to the client app at /client/
 * @param path
 * @returns {string}
 */
export const createAbsoluteClientAppUrl = (path) => `${basename}/${path}`;

/**
 * Get the wiki link for the current page or the page provided
 * @param {string} pageName
 * @returns {string} url
 */
export const getClientWikiLink = (pageName) => {
    const wikiLinkBase = "https://wiki.publicrelay.com/mediawiki/index.php/";

    return `${wikiLinkBase}${pageName}`;
};

/**
 * create a url to the intermediate jsp that generates an analysis on the client side
 * (if it doesnt already exist). once generated, the user is directed to CL-09
 * @param {string|number} articleId
 * @return {string}
 */
export const getGoLink = (articleId) =>
    `${DOJO_URLS.DOJO_GOLINK}#articleId=${articleId}`;

/**
 * discover authors / outlets view
 * @returns {string}
 */
export const getCL27Url = () => createAbsoluteClientAppUrl(CL27_PATH);

/**
 * login page
 * @returns {string}
 */
export const getLoginUrl = () => DOJO_URLS.DOJO_LOGIN;

/**
 * dashboard (My Program)
 * @returns {string}
 */
export const getCL01Url = () => getCL01OverviewUrl();

/**
 * charts list
 * @returns {string}
 */
export const getCL02Url = () => DOJO_URLS.DOJO_CL02;

/**
 * generate a link for the news feed page (CL06)
 * @returns {string}
 */
export const getCL06Url = (newsFeedId) => {
    return newsFeedId
        ? `${DOJO_URLS.DOJO_CL06}&id=${newsFeedId}`
        : DOJO_URLS.DOJO_CL06;
};

/**
 * generate a link for the newsletters page (CL07)
 * @returns {string}
 */
export const getCL07Url = (newsletterId) => {
    return newsletterId
        ? `${DOJO_URLS.DOJO_CL07}&id=${newsletterId}`
        : DOJO_URLS.DOJO_CL07;
};

/**
 * news list page
 * @returns {string}
 */
export const getCL08Url = () => DOJO_URLS.DOJO_CL08;

/**
 * article detail page
 * @param {number} analysidId
 * @param {number} articleId
 * @returns {string}
 */
export const getCL09Url = (analysidId, articleId) =>
    `${getCL08Url()}#view=item&id=${analysidId}&secondId=${articleId}`;

/**
 * twitter list page
 * @returns {string}
 */
export const getCL28Url = () => DOJO_URLS.DOJO_CL28;

/**
 * live stream list page
 * @param {number|string} [bucketId]
 * @returns {string}
 */
export const getCL10Url = (bucketId) => {
    let relativePath;

    if (bucketId) {
        relativePath = `${CL10_PATH}?bucketId=${bucketId}`;
    } else {
        relativePath = CL10_PATH;
    }

    return createAbsoluteClientAppUrl(relativePath);
};

/**
 * the live stream detail page
 * @returns {string}
 */
export const getCL11Url = (id) =>
    createAbsoluteClientAppUrl(`${CL11_PATH}/${id}`);

/**
 * explore tags list page
 * @returns {string}
 */
export const getCL30Url = () => createAbsoluteClientAppUrl(CL30_PATH);

/**
 * explore tags detail page
 * @param {number} id - a tag ID
 * @returns {string}
 */
export const getCL31Url = (id) =>
    createAbsoluteClientAppUrl(`${CL31_PATH}/${id}`);

/**
 * dashboard overview page
 * @returns {string}
 */
export const getCL01OverviewUrl = () =>
    createAbsoluteClientAppUrl(CL01_OVERVIEW_PATH);

/**
 * dashboard reports page
 * @returns {string}
 */
export const getCL01ReportsUrl = () =>
    createAbsoluteClientAppUrl(CL01_REPORTS_PATH);

/**
 * dashboard newsletters & recipients page
 * @returns {string}
 */
export const getCL01NewslettersUrl = () =>
    createAbsoluteClientAppUrl(CL01_NEWSLETTERS_PATH);

/**
 * dashboard settings (visible only to internal users) page
 * @returns {string}
 */
export const getCL01SettingsUrl = () =>
    createAbsoluteClientAppUrl(CL01_SETTINGS_PATH);

/**
 * dashboard feedback page
 * @returns {string}
 */
export const getCL01FeedbackUrl = () =>
    createAbsoluteClientAppUrl(CL01_FEEDBACK_PATH);

/**
 * dashboard alerts page
 * @returns {string}
 */
export const getCL01AlertsUrl = () =>
    createAbsoluteClientAppUrl(CL01_ALERTS_PATH);

/**
 * dashboard tags page
 * @returns {string}
 */
export const getCL01TagsUrl = () => createAbsoluteClientAppUrl(CL01_TAGS_PATH);

/**
 * dashboard outlet lists page
 * @returns {string}
 */
export const getCL01OutletListsUrl = () =>
    createAbsoluteClientAppUrl(CL01_OUTLET_LISTS_PATH);

/**
 * dashboard users page
 * @returns {string}
 */
export const getCL01UsersUrl = () =>
    createAbsoluteClientAppUrl(CL01_USERS_PATH);

/**
 * authors list page
 * @returns {string}
 */
export const getCL12Url = () => DOJO_URLS.DOJO_CL12;

/**
 * outlets list page
 * @returns {string}
 */
export const getCL14Url = () => DOJO_URLS.DOJO_CL14;

/**
 * outlet detail page
 * @param {number} outletId
 * @returns {string}
 */
export const getCL15CL25Url = (outletId) =>
    `${DOJO_URLS.DOJO_CL15_CL25}#id=${outletId}-OUTLET&view=item`;

/**
 * help
 * @returns {string}
 */
export const getCL17Url = () => DOJO_URLS.DOJO_CL17;

/**
 * twitter handles list page
 * @returns {string}
 */
export const getCL24Url = () => DOJO_URLS.DOJO_CL24;

/**
 * predictive
 * @returns {string}
 */
export const getCL26Url = () => DOJO_URLS.DOJO_CL26;

/**
 * command center
 * @returns {string}
 */
export const getCL23Url = () => DOJO_URLS.DOJO_CL23;

/**
 * upload articles
 * @returns {string}
 */
export const getCL18Url = () => DOJO_URLS.DOJO_CL18;

/**
 * get the link for the terms page
 * @returns {string}
 */
export const getTermsLink = () => DOJO_URLS.TERMS;

/**
 * live stream seardch
 * @returns {string}
 */
export const getCL22Link = () => DOJO_URLS.DOJO_CL22;

/**
 * global search
 * @param {string} term
 * @returns {string}
 */
export const getCL16Url = (term) =>
    `${DOJO_URLS.DOJO_CL16}#search=${encodeURIComponent(term)}`;

/**
 * Tableau dashboard
 * @returns {string}
 */
export const getCL25Url = () => DOJO_URLS.DOJO_CL25;

/**
 * construct a url for the AddThis (previously ShareThis) iframe src that's on the dojo side
 * @param {string} title
 * @param {string} url
 * @returns {`/com.publicrelay.www/jsp/client/shareThisIFrame.jsp?title=${string}&description=${string}&url=${string}`}
 */
export const getShareThisIframeUrl = (title, url) => {
    const encodedTitle = encodeURIComponent(title);

    return `${
        DOJO_URLS.SHARETHIS_IFRAME
    }?title=${encodedTitle}&description=${encodedTitle}&url=${encodeURIComponent(
        url
    )}`;
};

/**
 * get link to AN-06a: Newsletters. considers current environment (eg, local, lab, etc)
 * @returns {string}
 */
export const getAN06aUrl = () =>
    `${getAnalystRedirectBase()}analyst/outputs/newsletters`;
