import React from "react";
import PropTypes from "prop-types";
import { getSimpleErrorMessage } from "../../util/services";
import SnackAlert from "./SnackAlert";
import { useDispatch, useSelector } from "react-redux";
import {
    selectServiceErrors,
    clearServiceErrors,
} from "../../redux/slices/serviceSlice";

/**
 * opens a snack alert if there are errors in the redux store
 * accommodates "boundaryError" param to handle error boundary cases; see AuthLayout.js
 * @param {object} [boundaryError]
 * @param {string} [boundaryError.message]
 * @param {function} [onErrorClose] - should clear boundary error if it exists
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultErrorSnack = ({ boundaryError, onErrorClose }) => {
    const errors = useSelector(selectServiceErrors);

    const dispatch = useDispatch();

    /**
     * handle closing the error snackbar by clearing redux error state, boundary error state
     */
    const handleErrorClose = () => {
        if (onErrorClose) {
            onErrorClose();
        }

        dispatch(clearServiceErrors());
    };

    return (
        <SnackAlert
            title="System Error"
            type="error"
            open={Object.keys(errors).length > 0 || boundaryError}
            onClose={handleErrorClose}
        >
            {Object.keys(errors).map((errorKey, index) => (
                <div key={index}>{getSimpleErrorMessage(errors[errorKey])}</div>
            ))}

            {boundaryError && boundaryError.message}
        </SnackAlert>
    );
};

DefaultErrorSnack.propTypes = {
    boundaryError: PropTypes.object,
    onErrorClose: PropTypes.func,
};

export default DefaultErrorSnack;
