import Box from "@mui/material/Box";
import PropTypes from "prop-types";

/**
 * display the client logo (if any) formatted for the "My Program" left panel
 * the logo is configured on the "My Program" Settings page
 * same display as used on Predictive (dojo)
 * @param {string} clientLogoUrl
 * @returns {JSX.Element|null}
 * @constructor
 */
const Logo = ({ clientLogoUrl }) =>
    clientLogoUrl ? (
        <Box display="flex" justifyContent="center" mb={2}>
            <Box
                sx={{
                    borderRadius: "50%",
                    overflow: "hidden",
                    // backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={clientLogoUrl}
                    alt="logo"
                    style={{
                        width: "85px",
                        height: "85px",
                        objectFit: "contain",
                    }}
                />
            </Box>
        </Box>
    ) : null;

Logo.propTypes = {
    clientLogoUrl: PropTypes.string,
};

export default Logo;
